import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { ProjectProfile } from '@ceres/domain';
import { FilterService } from '@ceres/filter';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { ListItem } from '@ceres/frontend-helper';
import { ProjectSelectionService } from '../../services/project-selection.service';
import { FilterFactoryService } from '../../services/filter-factory.service';

@Component({
    selector: 'ceres-project-selection',
    templateUrl: './project-selection.component.html',
    styleUrls: ['./project-selection.component.scss']
})
export class ProjectSelectionComponent implements OnInit, OnDestroy {
    public availableColumns: ListItem[] = [
        { title: 'selected', selected: true },
        { title: 'mpNumber', selected: true },
        { title: 'mpTitle', selected: true },
        { title: 'projektGruppierung', selected: true },
        { title: 'projectLead.name', selected: true },
        { title: 'businessPartner.fullName', selected: true }
    ];

    public displayedColumns: string[] = [];

    public filterService: FilterService<ProjectProfile>;

    public filters = {};

    public searchControl = new FormControl('');

    public gridWidth = 0;

    public selectedProjects = new Map<number, ProjectProfile>();
    public multiple = false;
    public allChecked = false;
    public fewSelected = false;
    public filterMarkedForDeletion = false;

    private filterNames = ['mpNumber', 'projektGruppierung', 'projectLead.name', 'businessPartner.fullName'];

    private columnWidth = 140;

    private destroy$: Subject<boolean> = new Subject<boolean>();

    @ViewChild(MatSort)
    sort: MatSort;

    constructor(
        public dialogRef: MatDialogRef<ProjectSelectionComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public projectSelectionService: ProjectSelectionService,
        private filterFactory: FilterFactoryService
    ) {
        if (this.data.multiple) {
            this.multiple = this.data.multiple;
        }
        if (this.data.filterMarkedForDeletion) {
            this.filterMarkedForDeletion = this.data.filterMarkedForDeletion;
        }
        if (this.data.projects && this.data.projects.length) {
            this.selectedProjects = this.data.projects.filter((e) => e.id);
        }
    }

    ngOnInit() {
        this.filters = this.filterFactory.create(this.filterNames);
        this.projectSelectionService.getFilterValues(this.filterMarkedForDeletion);
        this.filterService = new FilterService<ProjectProfile>();
        this.changeDisplayedColumns(this.availableColumns);
        // TODO andere Lösung finden, da DOM-Manipulationen in Angular verboten
        document.querySelector('body').classList.add('new-wrapper');

        this.subscribeToFilterChanges();
        this.subscribeToAppliedFilters();
        this.subscribeToSearchChanges();
        this.subscribeToProjectProfileChanges();
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
        // TODO andere Lösung finden, da DOM-Manipulationen in Angular verboten
        document.querySelector('body').classList.remove('new-wrapper');
    }

    public pageChanged(event: PageEvent) {
        const { pageIndex, pageSize } = { ...event };
        this.projectSelectionService.setPage({ pageIndex, pageSize });
    }

    public sortChanged(event: Sort) {
        if (event && event.active && event.direction) {
            this.projectSelectionService.setSort({
                key: event.active,
                direction: event.direction
            });
        } else {
            this.projectSelectionService.setSort(null);
        }
    }

    public changeSelected(element: ProjectProfile, event) {
        this.selectedProjects = new Map(this.selectedProjects);
        if (!this.multiple) {
            if (event.checked) {
                this.selectedProjects.clear();
                this.selectedProjects.set(element.mpNumber, element);
            } else {
                this.selectedProjects.clear();
            }
        } else {
            if (event.checked) {
                this.selectedProjects.set(element.mpNumber, element);
            } else {
                this.selectedProjects.delete(element.mpNumber);
            }
        }
        this.checkIfAllAreSelected();
    }

    public selectAll() {
        this.selectedProjects = new Map(this.selectedProjects);
        if (this.allChecked || this.fewSelected) {
            const projectProfiles = this.projectSelectionService.getAllProjectProfiles();
            for (const profile of projectProfiles) {
                this.selectedProjects.set(profile.mpNumber, profile);
            }
            this.fewSelected = false;
            this.allChecked = true;
        } else if (!this.allChecked) {
            this.selectedProjects.clear();
        }
    }

    public remove(element: ProjectProfile) {
        this.changeSelected(element, { checked: false });
    }

    public closeDialog() {
        if (!this.selectedProjects || this.selectedProjects.size === 0) {
            this.dialogRef.close();
        } else {
            const selected = [...this.selectedProjects.values()];
            if (!this.multiple) {
                if (selected.length > 0) {
                    this.dialogRef.close({ ...selected[0] });
                } else {
                    this.dialogRef.close();
                }
            } else {
                this.dialogRef.close(selected);
            }
        }
    }

    public changeDisplayedColumns(columns: ListItem[]) {
        this.displayedColumns = columns.filter((column) => column.selected).map((column) => column.title);
        this.gridWidth = this.displayedColumns.length * this.columnWidth;
    }

    private checkIfAllAreSelected() {
        const projectProfiles = this.projectSelectionService.getAllProjectProfiles();
        const allSelected = projectProfiles.reduce(
            (agg, profile) => agg && this.selectedProjects.has(profile.mpNumber),
            true
        );

        if (allSelected) {
            this.allChecked = true;
            this.fewSelected = false;
        } else {
            this.fewSelected = this.selectedProjects.size > 0;
            this.allChecked = false;
        }
    }

    private subscribeToFilterChanges() {
        this.projectSelectionService.filterValues$
            .pipe(takeUntil(this.destroy$))
            .subscribe((filters) => (this.filterService.fixedFilterValues = filters));
    }

    private subscribeToAppliedFilters() {
        this.filterService.appliedFilters$
            .pipe(takeUntil(this.destroy$))
            .subscribe((filters) => this.projectSelectionService.applyFilters(filters, this.filterMarkedForDeletion));
    }

    private subscribeToSearchChanges() {
        this.searchControl.valueChanges
            .pipe(takeUntil(this.destroy$), distinctUntilChanged(), debounceTime(900))
            .subscribe((search) => this.projectSelectionService.applySearch(search, this.filterMarkedForDeletion));
    }

    private subscribeToProjectProfileChanges() {
        this.projectSelectionService.projectProfiles$
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => this.checkIfAllAreSelected());
    }
}
