import { AppUserService } from '@ceres/shared/services';
import { MessageService } from '@ceres/shared/services';
import { Component, OnInit, OnDestroy, NgZone, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ItemReference, BusinessArea, Portfolio } from '@ceres/domain';
import { BusinessAreasService } from '@ceres/shared/services';
import { take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { TranslocoService } from '@ngneat/transloco';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { News } from '../../models';
import { PortfolioService } from '../../services/portfolio.service';
import { NewsService } from '../../services/news.service';

@Component({
    selector: 'ceres-add-news',
    templateUrl: './add-news.component.html',
    styleUrls: ['./add-news.component.scss']
})
export class AddNewsComponent implements OnInit, OnDestroy {
    public draftItem: News;
    @ViewChild('autosize') autosize: CdkTextareaAutosize;

    public portfolios: Portfolio[];
    public businessAreas: BusinessArea[];

    private target: string;
    private targetGroup: Portfolio;
    private targetTeam: BusinessArea;
    public subscriptions: Subject<boolean> = new Subject();

    constructor(
        public dialogRef: MatDialogRef<AddNewsComponent>,
        public appUserService: AppUserService,
        private newsService: NewsService,
        private messageService: MessageService,
        private portfolioService: PortfolioService,
        private translateService: TranslocoService,
        private businessAreaService: BusinessAreasService,
        private _ngZone: NgZone
    ) {}

    ngOnInit() {
        this.appUserService.mainUser$.pipe(takeUntil(this.subscriptions)).subscribe((employee) => {
            // TODO CUSTOMER ACCESS
            if (employee && employee.id) {
                switch (employee.role.id) {
                    case 1:
                        this.target = 'All';
                        break;
                    case 3:
                        this.target = 'Group';
                        this.targetGroup = employee.details.portfolio;
                        break;
                    case 6:
                        this.target = 'Team';
                        this.targetTeam = employee.details.businessArea;
                        break;
                    case 11:
                        this.target = 'Team';
                        this.targetTeam = employee.details.businessArea;
                        break;
                    case 12:
                        this.target = 'Group';
                        this.targetGroup = employee.details.portfolio;
                        break;
                }
                this.draftItem = new News({
                    id: -1,
                    title: '',
                    content: '',
                    collapsed: true,
                    priority: 0,
                    validTo: new Date(),
                    critical: false,
                    target: this.target,
                    targetGroup: this.targetGroup,
                    targetTeam: this.targetTeam
                });

                void Promise.all([this.portfolioService.getAll(), this.businessAreaService.getAll()]).then(
                    ([portfolios, businessAreas]) => {
                        this.portfolios = portfolios;
                        this.businessAreas = this.businessAreaService.transformBusinessAreasByType(
                            businessAreas,
                            'BusinessArea'
                        );
                    }
                );
            }
        });
    }

    ngOnDestroy() {
        this.subscriptions.next(true);
        this.subscriptions.unsubscribe();
    }

    compareLookups(item1: ItemReference, item2: ItemReference): boolean {
        return item1 && item2 && item1.id === item2.id;
    }

    triggerResize() {
        this._ngZone.onStable.pipe(take(1)).subscribe(() => this.autosize.resizeToFitContent(true));
    }

    save() {
        void this.newsService.create(this.draftItem).then(() => {
            this.showMessage('control-center.others.news-added', 1);
            this.draftItem = new News({
                id: -1,
                title: '',
                content: '',
                priority: null,
                validTo: null,
                critical: false,
                target: this.target,
                targetGroup: this.targetGroup,
                targetTeam: this.targetTeam
            });
        });
    }

    checkDisability() {
        return (
            !this.draftItem.title ||
            (this.draftItem.target !== 'All' && !this.draftItem.targetGroup && !this.draftItem.targetTeam) ||
            this.draftItem.priority === null ||
            !this.draftItem.validTo
        );
    }

    showMessage(msg: string, type: number) {
        this.messageService.pushMessage({
            message: msg,
            title: this.translateService.translate('menu.news'),
            type
        });
    }
}
