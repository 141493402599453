<ng-template>
    <ng-content></ng-content>
</ng-template>
<mat-stepper class="flex-center flex-column content" [linear]="true" #stepper>
    @for (wizardForm of wizardForms; track wizardForm; let i = $index) {
        <mat-step [stepControl]="wizardForm.formGroup">
            <ng-template matStepLabel>{{ wizardForm.title }}</ng-template>
            <ng-container *ngTemplateOutlet="wizardForm.templateRef"></ng-container>
            <div class="button--container">
                <ceres-button [style]="'primary'" icon="close" (click)="cancel.emit()">{{
                    'shared.buttons.cancel' | transloco
                }}</ceres-button>
                @if (i !== wizardForms.length - 1) {
                    <ceres-button
                        [disabled]="wizardForm.formGroup.invalid"
                        icon="arrow-right-small"
                        (click)="stepper.next()"
                        >{{ 'shared.buttons.next' | transloco }}</ceres-button
                    >
                }
                @if (i === wizardForms.length - 1) {
                    <ceres-button [disabled]="wizardForm.formGroup.invalid" icon="check-mark" (click)="save.emit()">{{
                        'shared.buttons.save' | transloco
                    }}</ceres-button>
                }
            </div>
        </mat-step>
    }
</mat-stepper>
