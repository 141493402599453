<div class="d-flex">
    <h2 data-testid="feature-header-title" id="feature-header-title">
        {{ title }}
    </h2>
</div>

<div class="fh-content-container">
    <div class="fh-content-left" data-testId="fh-content-left">
        <ng-content select="[left]"></ng-content>
    </div>
    <div class="fh-content-right" data-testId="fh-content-right">
        <ng-content select="[right]"></ng-content>
    </div>
</div>
