import { Employee, Impersonation, IUser } from '@ceres/domain';
import { MessageService } from '@ceres/shared/services';
import { MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { AppUserService } from '@ceres/shared/services';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { format } from 'date-fns';
import { ImpersonationService } from '../../services/impersonation.service';

@Component({
    selector: 'ceres-impersonation',
    templateUrl: './impersonation.component.html',
    styleUrls: ['./impersonation.component.scss']
})
export class ImpersonationComponent implements OnInit, OnDestroy {
    public gridWidth: number;

    private currentEmployee: IUser;
    public subscriptions: Subject<boolean> = new Subject();

    public displayedColumns = ['user', 'validFrom', 'validTo', 'remove', 'save'];

    public displayedColumnsSecond = ['user', 'validFrom', 'validTo'];

    public dataSource: MatTableDataSource<Impersonation>;
    public dataSourceSecond: MatTableDataSource<Impersonation>;
    public draftItem: Impersonation;

    private impersonations: Impersonation[];
    private impersonatables: Impersonation[];
    private allowImpersonate: Impersonation[];

    constructor(
        public dialogRef: MatDialogRef<ImpersonationComponent>,
        private impersonationService: ImpersonationService,
        private messageService: MessageService,
        private appUserService: AppUserService
    ) {}

    ngOnInit() {
        this.appUserService.mainUser$.pipe(takeUntil(this.subscriptions)).subscribe((employee) => {
            this.currentEmployee = employee;
            if (employee && employee.id) {
                this.loadData();
            }
        });
    }

    ngOnDestroy() {
        this.subscriptions.next(true);
        this.subscriptions.unsubscribe();
    }

    private loadData() {
        this.draftItem = {
            id: 0,
            user: null, // note: user picker gets employee dto not user dto
            validFrom: new Date(),
            impersonated: this.currentEmployee,
            validTo: new Date()
        };

        void this.impersonationService.getAll(this.currentEmployee).then((impersonations) => {
            if (impersonations) {
                this.impersonations = impersonations;
                this.impersonatables = this.impersonations.filter((e) => e.impersonated.id !== this.currentEmployee.id);
                this.allowImpersonate = this.impersonations.filter(
                    (e) => e.impersonated.id === this.currentEmployee.id
                );

                this.dataSourceSecond = new MatTableDataSource(this.allowImpersonate);
                this.dataSource = new MatTableDataSource(this.impersonatables);
            }
        });
    }

    updateItem(item: Impersonation) {
        void this.impersonationService
            .update({
                ...item,
                validFrom: format(new Date(item.validFrom), 'yyyy-MM-dd'),
                validTo: format(new Date(item.validTo), 'yyyy-MM-dd')
            })
            .then(() => {
                this.showMessage('control-center.general.save-success', 1);
                this.loadData();
            });
    }

    deleteItem(item: Impersonation) {
        void this.impersonationService.delete(item).then(() => {
            this.showMessage('control-center.general.save-success', 1);
            this.loadData();
        });
    }

    createItem() {
        const employee: Employee = this.draftItem.user as any as Employee;

        void this.impersonationService
            .create({
                ...this.draftItem,
                user: employee.user,
                validFrom: format(new Date(this.draftItem.validFrom), 'yyyy-MM-dd'),
                validTo: format(new Date(this.draftItem.validTo), 'yyyy-MM-dd')
            })
            .then(() => {
                this.showMessage('control-center.general.save-success', 1);
                this.loadData();
            });
    }

    showMessage(msg: string, type: number) {
        this.messageService.pushMessage({
            message: msg,
            title: 'Admin',
            type
        });
    }
}
