import { Inject, Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { from, Observable, of } from 'rxjs';
import { catchError, map, mergeMap, toArray } from 'rxjs/operators';
import { ENVIRONMENT } from '@ceres/frontend-helper';
import { ImpersonatedHttpClient } from '@ceres/shared/services';
import { SCDUser } from '../models';

@Injectable({
    providedIn: 'root'
})
export class SCDService {
    constructor(
        private http: ImpersonatedHttpClient,
        @Inject(ENVIRONMENT) private readonly environment: { edgeService: string; scdUrl: string }
    ) {}

    public searchUser(firstname: string, surname: string, email: string): Observable<SCDUser[]> {
        let params = new HttpParams();
        if (firstname) {
            params = params.append('firstName', `${firstname.trim().toLowerCase()}*`);
        }
        if (surname) {
            params = params.append('surName', `${surname.trim().toLowerCase()}*`);
        }
        if (email) {
            params = params.append('mail', `${email.trim().toLowerCase()}*`);
        }

        return this.http
            .get<{ records: SCDUser[] }>(`${this.environment.edgeService}/scd-user-directory`, { params })
            .pipe(
                map((result) => result.records || []),
                catchError(() => of([]))
            );
    }

    public searchByGID(users: SCDUser[]): Observable<SCDUser[]> {
        return from(users).pipe(
            mergeMap((user) =>
                this.getByGID(user.gid).pipe(
                    map((result) => result.records || []),
                    mergeMap((records) => of(...records))
                )
            ),
            toArray(),
            catchError(() => of([]))
        );
    }

    getByGID(gid: string): Observable<{ records: SCDUser[] }> {
        let params = new HttpParams();
        params = params.append('gid', `${gid}*`);

        return this.http.get<{ records: SCDUser[] }>(`${this.environment.edgeService}/scd-user-directory`, {
            params
        });
    }

    getByLocation(location: string): Promise<any> {
        return this.http
            .get<any>(`${this.environment.edgeService}/scd-user-directory/locations/${location}`)
            .toPromise();
    }
}
