@if (processing) {
    <div class="spinner-container-mod">
        <mat-spinner [diameter]="100"></mat-spinner>
    </div>
}
@if (!processing) {
    <div class="topbar">
        <mat-icon (click)="close()" class="material-icons close-icon">close</mat-icon>
    </div>
}
<div class="content">
    <pdf-viewer
        [src]="pdfSrc"
        [render-text]="true"
        (pages-initialized)="afterLoadComplete()"
        style="display: block"
    ></pdf-viewer>
</div>
