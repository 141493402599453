@if (processing) {
    <ceres-loading></ceres-loading>
}
@if (!processing && data.provider == 'microsoft') {
    <iframe [src]="safeUrl" width="100%" height="100%" frameborder="0"
        >This is an embedded <a target="_blank" href="http://office.com">Microsoft Office</a> document, powered by
        <a target="_blank" href="http://office.com/webapps">Office Online</a>.</iframe
    >
}
@if (!processing && data.provider == 'google') {
    <iframe [src]="safeUrl"></iframe>
}
