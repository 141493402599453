<div class="dialog-header">
    <h3 class="title">{{ data.title }}</h3>
</div>
<mat-dialog-content>
    @if (data.infoText) {
        <div class="info-text">
            {{ data.infoText }}
        </div>
    }
    <mat-form-field class="full-width">
        @if (data.options.length < 1) {
            <mat-label>{{ 'shared.select.no-selection' | transloco }}</mat-label>
        }
        @if (data.options.length > 0) {
            <mat-label>{{ 'shared.select.please-select-placeholder' | transloco }}</mat-label>
        }
        <mat-select (valueChange)="chooseOption($event)">
            @for (option of data.options; track option) {
                <mat-option [value]="option">{{ option[data.nameSelector || 'name'] }} </mat-option>
            }
        </mat-select>
    </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions class="dialog-actions">
    <ceres-button (click)="cancel()" [icon]="'close'" [style]="'grey'">{{
        'shared.buttons.cancel' | transloco
    }}</ceres-button>
</mat-dialog-actions>
