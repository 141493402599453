import { Directive, Input, TemplateRef } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[ceresWizardForm]'
})
export class WizardFormDirective {
    @Input('ceresWizardForm')
    title!: string;

    @Input('ceresWizardFormFormGroup')
    formGroup!: FormGroup;

    constructor(readonly templateRef: TemplateRef<unknown>) {}
}
