export class DepartmentDto {
    id: number;
    name: string;
    country?: string;
    are?: string;
    internalName?: string;
    parent: DepartmentDto | null;
    children: DepartmentDto[];
    createdManually: boolean;
    servicePricingPackages?: any;
    serviceDepartmentPackages?: any;
    orgId: string;
    orgId2: string;
    weight: number;
    ignoreImport?: boolean;
    businessArea?: any;
    dc?: string;
}

export interface DepartmentExportDto {
    internalName: string;
    name: string;
    are: string;
    dc: string;
    country: string;
    orgId: string;
    orgId2: string;
    weight: string | number;
    parentId: string | number;
    ignoreImport: boolean;
    createdManually: boolean;
    businessArea: string;
    commentsToHierarchy: string;
}
