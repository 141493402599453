import { ProjectProfile } from './project-profile';

export interface ProjectExternalCost {
    id: number;
    project: ProjectProfile;
    october: number;
    november: number;
    december: number;
    january: number;
    february: number;
    march: number;
    april: number;
    may: number;
    june: number;
    july: number;
    august: number;
    september: number;
    markup: number;
}

export interface ProjectExternalCostFilterValues {
    'project.mpNumber': number[];
    'project.mpTitle': string[];
}
