import { Pipe, PipeTransform } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

@Pipe({
    name: 'actualPrice'
})
export class ActualPricePipe implements PipeTransform {
    constructor(private translate: TranslocoService) {}

    transform(verrechnungsart: string, zyklus: string): any {
        if (verrechnungsart === 'FixedPrice' && zyklus === 'Quarterly') {
            return this.translate.translate('projects.tooltip.fixed-price-quaterly');
        }
        if (
            (verrechnungsart === 'FixedPrice' && zyklus === 'Monthly') ||
            (verrechnungsart === 'FixedPrice' && zyklus === 'Monthly')
        ) {
            return this.translate.translate('projects.tooltip.fixed-price-monthly');
        }
        if (verrechnungsart === 'TimeAndMaterial') {
            return this.translate.translate('projects.tooltip.material');
        }
        return this.translate.translate('projects.project-properties.actual-price');
    }
}
