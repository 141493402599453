import { Pipe, PipeTransform } from '@angular/core';
import { subMonths, addMonths, getMonth, getYear } from 'date-fns';
import { SelectedDate } from '../interfaces/selected-date.interface';

@Pipe({
    name: 'surroundingMonths'
})
export class SurroundingMonthsPipe implements PipeTransform {
    transform(month: SelectedDate): SelectedDate[] {
        if (!month) {
            return [];
        }
        const currentMonth = new Date(month.year, month.month, 1);
        const resp = [];
        for (let i = 12; i > 0; i--) {
            const prevMonth = subMonths(currentMonth, i);
            resp.push({ month: getMonth(prevMonth), year: getYear(prevMonth) });
        }
        resp.push(month);
        for (let i = 1; i < 13; i++) {
            const nextMonth = addMonths(currentMonth, i);
            resp.push({ month: getMonth(nextMonth), year: getYear(nextMonth) });
        }

        return resp;
    }
}
