import {
    Component,
    OnInit,
    EventEmitter,
    Input,
    Output,
    HostListener,
    ElementRef,
    ViewChild,
    OnDestroy
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FilterService, RangeFilter } from './../../../../lib';

interface MinMax {
    min: number;
    max: number;
}

@Component({
    selector: 'lib-range-filter',
    templateUrl: './range-filter.component.html',
    styleUrls: ['./range-filter.component.scss']
})
export class RangeFilterComponent implements OnInit, OnDestroy {
    @ViewChild('slider') slider: ElementRef;

    @Input() filter: RangeFilter;
    @Input() filterService: FilterService;

    @Output() close = new EventEmitter();

    private readonly destroy$ = new Subject<void>();

    selection: MinMax = { min: 0, max: 100 };
    filterValues: MinMax;

    target: 'min' | 'max';

    get range() {
        return this.filterValues.max - this.filterValues.min;
    }
    get min() {
        return this.filterValues.min + (this.range * this.selection.min) / 100;
    }
    get max() {
        return this.filterValues.min + (this.range * this.selection.max) / 100;
    }

    ngOnInit() {
        this.filterService
            .getFilterValues(this.filter)
            .pipe(takeUntil(this.destroy$))
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .subscribe((res: any) => {
                this.filterValues = res;

                if (this.filter.min !== undefined) {
                    this.selection.min = this.constrain(((this.filter.min - this.filterValues.min) * 100) / this.range);
                }

                if (this.filter.max !== undefined) {
                    this.selection.max = this.constrain(((this.filter.max - this.filterValues.min) * 100) / this.range);
                }
            });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    @HostListener('window:mousemove', ['$event'])
    move(ev: MouseEvent) {
        if (this.target) {
            ev.preventDefault();
            ev.stopPropagation();
            const pos = this.getPos(ev);
            if (this.target === 'min' && pos > this.selection.max) {
                this.selection.min = this.selection.max;
                this.target = 'max';
            }
            if (this.target === 'max' && pos < this.selection.min) {
                this.selection.max = this.selection.min;
                this.target = 'min';
            }
            this.selection[this.target] = pos;
        }
    }

    start(ev: MouseEvent) {
        const pos = this.getPos(ev);
        if (pos <= this.selection.min) {
            this.target = 'min';
        } else if (pos >= this.selection.max) {
            this.target = 'max';
        } else {
            this.target = this.selection.max - pos > pos - this.selection.min ? 'min' : 'max';
        }
        this.move(ev);
    }

    @HostListener('window:mouseup', ['$event'])
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    stop(ev: MouseEvent) {
        // this.move(ev);
        this.target = undefined;
    }

    private getPos(ev: MouseEvent) {
        // slider is 220px wide, 20 of which are padding (10px per side), convert to percentage
        const el: HTMLElement = this.slider.nativeElement;
        // console.log(ev.clientX, el.getBoundingClientRect());
        return this.constrain((ev.clientX - el.getBoundingClientRect().left - 10) / 2);
    }

    private constrain(num: number) {
        return Math.min(100, Math.max(0, num));
    }

    getSliderBackground() {
        const bg = '#c4cad0';
        const fg = '#2387aa';
        return `linear-gradient(to right, ${bg}, ${bg} ${this.selection.min}%, ${fg} 0, ${fg} ${this.selection.max}%, ${bg} 0)`;
    }

    apply() {
        this.filter.min = this.selection.min === 0 ? undefined : this.min;
        this.filter.max = this.selection.max === 100 ? undefined : this.max;

        this.filterService.applyFilter(this.filter);
        this.close.next(true);
    }
}
