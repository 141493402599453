import { Filter } from './filters';

export function filterItems<T extends { [key: string]: any }>(filters: Filter[], items: T[]) {
    if (!filters || !items) {
        return [];
    }
    if (filters.length === 0) {
        return items;
    }

    const filteredItems = items.filter((item) => filters.every((f) => f.predicate(f.valueAccessor(item))));

    return filteredItems;
}

export function generateFilterValuesFor<T>(self: Filter, filters: Filter[], items: T[]) {
    const otherFilters = filters.filter((f) => f.key !== self.key);
    const matched = filterItems(otherFilters, items);
    const values = matched
        .map((el) => self.valueAccessor(el))
        .flat()
        .filter((value) => value != null);
    return [...new Set(values)].sort(localeCompare);
}

export function localeCompare(a: any, b: any) {
    if (typeof a === 'string') {
        return a.localeCompare(b == null ? '' : b);
    }
    return a > b ? 1 : a < b ? -1 : 0;
}
