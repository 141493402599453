<div class="container d-flex align-items-center px-1">
    @for (step of steps; track step; let i = $index) {
        <div
            class="step"
            [ngClass]="{ active: i + 1 < activeStep }"
            [matTooltip]="step"
            matTooltipClass="offerTemplateTooltip"
        >
            @if (i + 1 >= activeStep) {
                <span>{{ i + 1 }}</span>
            }
            @if (i + 1 < activeStep) {
                <i class="icon a-icon a-icon--check-mark"></i>
            }
        </div>
        @if (steps.length != i + 1) {
            <div class="step-connector"></div>
        }
    }
</div>
