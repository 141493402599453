import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface SelectDialogData<T extends object> {
    title: string;
    options: T[];
    nameSelector?: string;
    infoText?: string;
}

@Component({
    selector: 'ceres-select-modal',
    templateUrl: './select-modal.component.html',
    styleUrls: ['./select-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectModalComponent<T extends object> {
    constructor(
        private readonly dialogRef: MatDialogRef<SelectModalComponent<T>, T>,
        @Inject(MAT_DIALOG_DATA) public readonly data: SelectDialogData<T>
    ) {}

    public chooseOption(value: T) {
        this.dialogRef.close(value);
    }

    public cancel() {
        this.dialogRef.close();
    }
}
