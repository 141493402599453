import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { ButtonModule } from '../button/button.module';
import { ValuePickerComponent } from './value-picker.component';

@NgModule({
    imports: [CommonModule, MatInputModule, ButtonModule, MatIconModule],
    declarations: [ValuePickerComponent],
    exports: [ValuePickerComponent]
})
export class ValuePickerModule {}
