import { Attribute, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'ceres-simple-card',
    templateUrl: './simple-card.component.html',
    styleUrls: ['./simple-card.component.scss']
})
export class SimpleCardComponent {
    @Input()
    title = '';

    @Input()
    extendable = false;

    @Input()
    hideChildren = false;

    @Output()
    hiddenChanged = new EventEmitter<boolean>();

    isSubCard: boolean;

    constructor(@Attribute('isSubCard') isSubCard: boolean | undefined) {
        this.isSubCard = !!isSubCard;
    }

    toggleHide() {
        this.hideChildren = !this.hideChildren;
        this.hiddenChanged.emit(this.hideChildren);
    }

    doNothing(event: MouseEvent) {
        event.stopPropagation();
    }
}
