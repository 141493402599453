export class ProjectFinancialsSnapshotResponse {
    projectProfileId!: number;
    snapshotId!: string;
    snapshotTimestamp!: Date;
    allocationType!: string;
    projectStatus!: string;
    startDate!: string;
    endDate!: string;
    settled!: boolean;
    allocationCycle!: string;
    orderValue?: number;
    travelCosts?: number;
    contractVolume?: number;
    pocOverallProject?: number;
    pocExternal?: number;
    plannedCostsHours?: number;
    plannedCostsExternal?: number;
    plannedPriceHours?: number;
    plannedPriceExternal?: number;
    accruedCostsExternal?: number;
    actualCostsExternal?: number;
    actualCostsHours?: number;
    actualPriceHours?: number;
    actualProfit?: number;
    pocProfit?: number;
    pocValue?: number;
    proportionalLinearRemainingBudget?: number;
    remainingBudget?: number;
    valueSettled?: number;
    valueToBeSettled?: number;
    deltaPocAllocation?: number;
    forecastCostsOverallFY?: number;
    forecastCostsExternalFY?: number;
}

export class ProjectFinancialsSnapshotChartResponse {
    name!: string;
    series!: ProjectFinancialsSnapshotChartEntry[];
}

export class ProjectFinancialsSnapshotChartEntry {
    value!: number;
    name!: string;
}
