import { Injectable } from '@angular/core';
import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { AppUserService } from '../app-user.service';

@Injectable({
    providedIn: 'root'
})
export class AdditionalRoleInterceptor implements HttpInterceptor {
    constructor(private readonly auth: AppUserService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        const additionalRole = this.auth.additionalRole$.value;

        if (additionalRole?.id) {
            req = req.clone({
                setHeaders: {
                    'x-additional-role': additionalRole.id + ''
                }
            });
        }
        return next.handle(req);
    }
}
