/* eslint-disable max-classes-per-file */
import {
    ApiProjectCalculation as CeresApiProjectCalculation,
    ApiProjectCalculationTemplate as CeresApiProjectCalculationTemplate,
    ProjectCalculation,
    BusinessArea
} from '@ceres/domain';

export class ApiProjectCalculation implements CeresApiProjectCalculation {
    ID?: number;
    Title: string;
    MPNumber: number;
    VersionDate: Date;
    Data: ProjectCalculation;

    constructor(item?: Partial<ApiProjectCalculation>) {
        this.Title = item.Title;
        this.MPNumber = item.MPNumber;
        this.VersionDate = item.VersionDate;
        if (typeof item.Data === 'string') {
            // //console.log('its a string');
            this.Data = JSON.parse(item.Data);
        } else {
            this.Data = item.Data;
        }
        if (item.ID) {
            this.ID = item.ID;
        }
    }
}

export class ApiProjectCalculationAdd {
    Title: string;
    MPNumber: number;
    VersionDate: Date;
    Data: string;

    constructor(item?: Partial<ApiProjectCalculation>) {
        this.Title = item.Title;
        this.MPNumber = item.MPNumber;
        this.VersionDate = item.VersionDate;
        this.Data = JSON.stringify(item.Data);
    }
}

export class ApiProjectCalculationTemplate implements CeresApiProjectCalculationTemplate {
    ID?: number;
    Title: string;
    BusinessArea: BusinessArea[];
    Template: ProjectCalculation;
}
