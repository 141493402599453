import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable, of } from 'rxjs';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { AppUserService } from '@ceres/shared/services';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AppUserSessionService } from '../../shared/services/app-user-session.service';
import { AuthUserService } from './auth-user.service';

@Injectable({
    providedIn: 'root'
})
export class UserGuard implements CanActivate {
    constructor(
        private oidcSecurityService: OidcSecurityService,
        private authUserService: AuthUserService,
        private appUserService: AppUserService,
        private appUserSessionService: AppUserSessionService
    ) {}

    canActivate(): Observable<boolean> | Promise<boolean> | boolean {
        return this.oidcSecurityService.getUserData().pipe(
            map((response) => {
                if (!response) {
                    return false;
                }

                return response;
            }),
            switchMap((authUser) => {
                if (!authUser) {
                    return of(false);
                }

                return this.appUserService.loggedInUser$.pipe(
                    map((user) => {
                        if (!user) {
                            void this.appUserSessionService.setSession(authUser);
                            this.authUserService.loggedInAuthUser$.next(authUser);
                        }

                        return true;
                    })
                );
            }),
            catchError(() => of(false))
        );
    }
}
