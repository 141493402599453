export interface StatusReport {
    id: number;
    title: string;
    achievements: string;
    comment: string;
    // fsgGesamtprojekt: number;
    nextSteps: string;
    financialStatus: string;
    // prLeader: string;
    program: any;
    // projektGruppierung: string;
    qualityRiskKPIs: string;
    reportingDate: string;
    risks: string;
    // accountingType: string;
    isEditable: boolean;
    programId?: number;
}
