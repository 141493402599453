import { Employee } from './employee';
import { Role } from './role';
import { BusinessArea } from './business-area';

export interface AdditionalRole {
    id: number;
    employee: Employee;
    role: Role;
    allBusinessAreas: BusinessArea[];
}
