import { TranslocoService } from '@ngneat/transloco';
import { Injectable } from '@angular/core';
import { MessageService } from '@ceres/shared/services';

@Injectable({
    providedIn: 'root'
})
export class CopyClipboardService {
    constructor(
        private translate: TranslocoService,
        private messageService: MessageService
    ) {}

    /**
     * Copies a string to clipboard.
     *
     * For the title and message parameter, pass in the translate code.
     *
     * @param text The text to be copied
     * @param titleTranslate The title of the confirmation notification (will be translated)
     * @param messageTranslate The message of the confirmation notificatiion (will be translated)
     */
    copy(text: string, titleTranslate: string, messageTranslate: string) {
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = text;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        document.execCommand('copy');
        this.messageService.pushMessage({
            message: this.translate.translate(messageTranslate),
            title: this.translate.translate(titleTranslate),
            type: 1
        });
    }
}
