import { Injectable } from '@angular/core';
import { Program, PaginationResponse, ProjectProfile } from '@ceres/domain';
import { map } from 'rxjs/operators';
import { ImpersonatedHttpClient } from '@ceres/shared/services';
import { environment } from '../../../environments/environment';

@Injectable()
export class ProgramService {
    public programColumnTitles = ['title', 'portfolio', 'statusReports', 'documents', 'classification', 'mpNumbers'];

    private cachedProgram: Program;

    constructor(private httpClient: ImpersonatedHttpClient) {}

    public get program(): Program {
        const cached = this.cachedProgram;
        this.cachedProgram = null;
        return cached;
    }

    public set program(val: Program) {
        this.cachedProgram = val;
    }

    async getAll(): Promise<Program[]> {
        return await this.httpClient
            .get<PaginationResponse<Program>>(`${environment.edgeService}/programs`)
            .pipe(map(({ data }) => data))
            .toPromise();
    }

    async getByID(id: number): Promise<Program> {
        return await this.httpClient.get<Program>(`${environment.edgeService}/programs/${id}/reports`).toPromise();
    }

    async create(program: Program) {
        return await this.httpClient.post<Program>(`${environment.edgeService}/programs`, program).toPromise();
    }

    async update(program: Program) {
        return await this.httpClient
            .patch<Program>(`${environment.edgeService}/programs/${program.id}`, program)
            .toPromise();
    }

    addProjects({ id: programId }: Program, projects: ProjectProfile[]) {
        const projectIds = projects.map(({ id }) => id);
        return this.httpClient.post(`${environment.edgeService}/programs/${programId}/project-profiles`, projectIds);
    }

    async delete(program: Program) {
        return await this.httpClient.delete<Program[]>(`${environment.edgeService}/programs/${program.id}`).toPromise();
    }
}
