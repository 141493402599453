import { Portfolio } from './portfolio';
export interface BusinessValueQuery {
    businessField: string;
    portfolio: string;
    accountingMonth: string | number;
}

export enum BusinessValueType {
    ACTUALS_SAP = 'aktuell sap',
    ACTUALS_CLEANED = 'aktuell bereinigt',
    ACTUALS_ESTIMATE = 'aktuell geschätzt'
}

export enum BusinessValueCategory {
    COSTS = 'Cost',
    CHARGING = 'Charging'
}
export interface BusinessValue {
    id: number;
    title: string;
    portfolio: Portfolio;
    accountingMonth: number;
    comment: string;
    sv: number;
    gc: number;
}

export interface ApiBusinessValues {
    Id: number;
    Portfolio: Portfolio;
    // PortfolioId: number;
    Title: string;
    AccountingMonth: number;
    Category: string;
    Value: number;
}
