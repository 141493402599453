import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SimpleCardComponent } from './simple-card.component';

@NgModule({
    imports: [CommonModule],
    declarations: [SimpleCardComponent],
    exports: [SimpleCardComponent]
})
export class SimpleCardModule {}
