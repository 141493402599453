import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { AuthModule } from 'angular-auth-oidc-client';
import { environment } from '../../environments/environment';
import { AuthClientConfig } from './models/auth-client.config';
import { TokenInjector } from './services/token-injector.interceptor';
import { AuthCallbackComponent } from './components/auth-callback.component';

@NgModule({
    declarations: [AuthCallbackComponent],
    exports: [AuthModule],
    imports: [
        AuthModule.forRoot({
            config: environment.authentication as AuthClientConfig
        })
    ],
    providers: [{ provide: HTTP_INTERCEPTORS, useClass: TokenInjector, multi: true }]
})
export class AuthAngularClientModule {}
