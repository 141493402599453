export interface Classification {
    id: number;
    questionId: number;
    value: number;
    programId: number;
    originalValue?: number;
    comment: string;
    originalComment?: string;
}

export interface ClassificationQuestion {
    id: number;
    title: string;
    titleEn: string;
    quantifier: number;
    category: string;
    descriptionDe: string;
    descriptionEn: string;
}
