import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'cascade',
    pure: false
})

// Affect the elements to display in a e.g. dropdown list based on given parameters
export class CascadePipe implements PipeTransform {
    transform(array: any[], parameters: any): any {
        if (!parameters[1]) {
            return array.filter((item) => item[parameters[0]] === parameters[1]);
        } else {
            return array;
        }
    }
}
