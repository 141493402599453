import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'find'
})
export class FindPipe implements PipeTransform {
    transform(input: any[], searchValue: any, property?: string): any {
        if (!Array.isArray(input)) {
            return undefined;
        }

        return input.find((item) => (property ? item && item[property] === searchValue : item === searchValue));
    }
}
