import { AfterViewInit, Component, HostListener, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { ProjectCountPercentageChartDataPoint } from '@ceres/domain';
import { debounce } from 'lodash-es';
import { FilterService } from '@ceres/filter';
import { ProjectChartService } from '../../../services/project-chart.service';

@Component({
    selector: 'ceres-project-chart-customer-department',
    templateUrl: './project-chart-customer-department.component.html',
    styleUrls: ['./project-chart-customer-department.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ProjectChartCustomerDepartmentComponent implements OnInit, OnDestroy, AfterViewInit {
    private destroy$: Subject<boolean> = new Subject<boolean>();

    @Input() filterService: FilterService;
    @Input() viewDividerValue = 1.125;
    view: any[] = [];
    colorScheme = {
        domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA']
    };

    countsByCustomerTypeEntry: ProjectCountPercentageChartDataPoint[];
    countsByCustomerDepartmentEntry: ProjectCountPercentageChartDataPoint[];

    constructor(
        public readonly projectChartService: ProjectChartService
        // readonly filterService: ProjectFilterService
    ) {}

    ngOnInit() {
        // TODO standalone filter options for widget
        // const initialFiscalYear = this.projectChartService.allFiscalYears[1];
        // this.filterService.applyFiscalYear(initialFiscalYear);

        // TODO andere Lösung finden, da DOM-Manipulationen in Angular verboten
        document.querySelector('body').classList.add('new-wrapper');

        this.subscribeToAppliedFilters();
        this.subscribeToChangedData();
        this.view = [window.innerWidth / this.viewDividerValue, 400];
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
        // TODO andere Lösung finden, da DOM-Manipulationen in Angular verboten
        document.querySelector('body').classList.remove('new-wrapper');
    }

    ngAfterViewInit() {
        this.onResize = debounce(this.onResize, 150, {
            leading: false,
            trailing: true
        });
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.view = [event.target.innerWidth / this.viewDividerValue, 400];
    }

    private subscribeToAppliedFilters() {
        this.filterService.appliedFilters$.pipe(takeUntil(this.destroy$), debounceTime(100)).subscribe((filters) => {
            this.projectChartService.appliedFilters = filters;
            this.projectChartService.getCountsByCustomerType();
        });
    }

    private subscribeToChangedData() {
        this.projectChartService.countsByCustomerTypeEntry$
            .pipe(takeUntil(this.destroy$), debounceTime(100))
            .subscribe((data) => {
                this.countsByCustomerDepartmentEntry = null;
                this.countsByCustomerTypeEntry = data;
            });
    }

    onSelect(event) {
        void this.projectChartService.getCountsByCustomerDepartment(event.name).then((value) => {
            this.countsByCustomerDepartmentEntry = value.map((obj) => {
                return { ...obj, name: obj.name ? obj.name : 'Null' };
            });
        });
    }

    public resetSelection() {
        this.countsByCustomerDepartmentEntry = null;
    }
}
