export interface ChartDataPoint<TValue = number> {
    name: string;
    value: TValue;
}

export interface ChartDataPointWithExtra<TExtra extends object, TValue = number> extends ChartDataPoint<TValue> {
    extra: TExtra;
}

export interface ChartDataPointPercentageExtra {
    percentage: number;
}

export type ChartDataPointWithPercentage = ChartDataPointWithExtra<ChartDataPointPercentageExtra>;
