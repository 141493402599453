import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, HostListener, Inject } from '@angular/core';

@Component({
    selector: 'ceres-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent {
    constructor(
        @Inject(MAT_DIALOG_DATA)
        public data: {
            text: string;
            amount?: number;
            header: string;
            disableClose: boolean;
            confirmButtonText: string;
            cancleButtonText: string;
            disableCancleButton: boolean;
            warningText?: string;
        },
        private matDialogRef: MatDialogRef<ConfirmDialogComponent>
    ) {}

    public cancel() {
        this.matDialogRef.close();
    }

    public confirm() {
        this.matDialogRef.close(true);
    }

    @HostListener('keydown.esc')
    public onEsc() {
        this.matDialogRef.close();
    }
}
