/* eslint-disable max-classes-per-file */
import { format } from 'date-fns';
import {
    FilterRecord,
    ValueRecord,
    MultiValueRecord,
    RangeRecord,
    DateRecord,
    SearchRecord,
    AvailableFilterRecords
} from './types';

/**
 * Generic filter, can't be used to filter a column!
 */
export abstract class Filter<R extends FilterRecord = AvailableFilterRecords> {
    scope: 'remote' | 'local';
    isApplied = false;

    constructor(public key: string) {}

    static from<R extends FilterRecord>(record: R): Filter<any> {
        switch (record.type) {
            case 'value':
                return new ValueFilter(record.key).deserialize(record as ValueRecord);
            case 'multivalue':
                return new MultiValueFilter(record.key).deserialize(record as MultiValueRecord);
            case 'range':
                return new RangeFilter(record.key).deserialize(record as RangeRecord);
            case 'date':
                return new DateFilter(record.key).deserialize(record as DateRecord);
            case 'search':
                return new SearchFilter(record.key).deserialize(record as SearchRecord);

            default:
                throw new Error('Unknwon filter record type: ' + record.type);
        }
    }

    transform(val: string) {
        return val;
    }

    valueAccessor(item: any) {
        let value = item;
        for (const prop of this.key.split('.')) {
            if (!hasValue(value)) {
                break;
            }
            if (value instanceof Array) {
                value = value.map((el) => el[prop]);
            } else {
                value = value[prop];
            }
        }
        return value;
    }

    /**
     * Alias for serialize() so that JSON.stringify serializes the Filters correctly.
     */
    toJSON(): R {
        return this.serialize();
    }

    /**
     * Returns true if the filter is active
     */
    abstract isActive(): boolean;

    /**
     * Converts the filter to a simple JSON object that can be saved or used to filter remotely
     */
    abstract serialize(): R;

    /**
     * Restores the state from the serialized object obtained by serialize()
     */
    abstract deserialize(serialized: R): this;

    /**
     * Function that implements the filter locally
     *
     * @returns true if item matches the filter, otherwise false
     */
    abstract predicate(value): boolean;
}

// /**
//  * A filter has to implement the FilterValueGenerator so that filter values can be generated locally.
//  */
// export interface FilterValueGenerator<T> {
//   /**
//    * Generates filter values locally
//    */
//   generateFilterValues(items: any): T[];
// }
// export function isFilterValueGenerator<T>(arg: any): arg is FilterValueGenerator<T> {
//   return hasValue((arg as FilterValueGenerator<T>).generateFilterValues);
// }

export interface ListFilterValue<T> {
    getFilterValue(): T[];
}
export interface MinMaxFilterValue<T> {
    getFilterValue(): { min: T; max: T };
}

/**
 * Helper functions
 */

export function hasValue(val: any): val is object {
    return val !== undefined && val !== null;
}

/**
 * Use this filter to filter for a specific value in a column.
 */
export class ValueFilter extends Filter<ValueRecord> implements ListFilterValue<string | number | boolean> {
    value: string | number | boolean;

    isActive() {
        return hasValue(this.value);
    }

    serialize(): ValueRecord {
        return {
            key: this.key,
            type: 'value',
            value: this.value
        };
    }

    deserialize(record: ValueRecord) {
        this.key = record.key;
        this.value = record.value;
        return this;
    }

    predicate(test: (string | number | boolean) | (string | number | boolean)[]) {
        if (!this.isActive()) {
            return true;
        }

        if (test instanceof Array) {
            return test.includes(this.value);
        } else {
            return test === this.value;
        }
    }

    getFilterValue(): (string | number | boolean)[] {
        return [this.value];
    }
}

export type MultiValueMatchingMode = 'one' | 'all' | 'same' | 'equals';

/**
 * Use this filter to filter for multiple possible values in a column.
 */
export class MultiValueFilter extends Filter<MultiValueRecord> implements ListFilterValue<string | number | boolean> {
    constructor(
        key: string,
        public selected: Array<string | number | boolean> = []
    ) {
        super(key);
    }

    /**
     * Set this to true to set the filter even if the selected array is empty.
     */
    forceFilter = false;

    /**
     * If the value is an array, this specifies the matching mode the filter uses:
     *
     * 'one': The item matches if one or more entries are selected
     * 'all': The item matches if all of the entries are selected
     * 'same': The item matches if all and only all entries are selected (duplicates are ignored)
     * 'equals': The item matches if all and only all items are selected and the order is equal
     */
    mode: MultiValueMatchingMode = 'one';

    isActive() {
        return this.selected && (this.forceFilter || this.selected.length > 0);
    }

    serialize(): MultiValueRecord {
        return {
            key: this.key,
            type: 'multivalue',
            mode: this.mode,
            values: this.selected
        };
    }

    deserialize(record: MultiValueRecord) {
        this.key = record.key;
        this.mode = record.mode || 'one';
        this.selected = record.values;
        return this;
    }

    predicate(test: (string | number | boolean) | (string | number | boolean)[]) {
        if (!this.isActive()) {
            return true;
        }

        if (test instanceof Array) {
            switch (this.mode) {
                case 'one':
                    return test.some((t) => this.selected.includes(t));
                case 'all':
                    return test.every((t) => this.selected.includes(t));
                case 'same':
                    return test.every((t) => this.selected.includes(t)) && this.selected.every((s) => test.includes(s));
                case 'equals':
                    return test.length === this.selected.length && test.every((t, i) => this.selected[i] === t);
                default:
                    throw new Error('Unknown operation mode ' + (this.mode as string));
            }
        } else {
            return this.selected.includes(test);
        }
    }

    getFilterValue() {
        return this.selected;
    }
}

/**
 * Use this filter to filter numbers that are in the specified range.
 */
export class RangeFilter extends Filter<RangeRecord> implements MinMaxFilterValue<number> {
    min: number;
    max: number;

    isActive() {
        return hasValue(this.min) || hasValue(this.max);
    }

    serialize(): RangeRecord {
        return {
            key: this.key,
            type: 'range',
            min: this.min,
            max: this.max
        };
    }

    deserialize(record: RangeRecord) {
        this.key = record.key;
        this.min = record.min;
        this.max = record.max;
        return this;
    }

    predicate(test: number) {
        if (!this.isActive()) {
            return true;
        }
        if (!hasValue(test)) {
            return false;
        }
        if (hasValue(this.min) && test < this.min) {
            return false;
        }
        if (hasValue(this.max) && test > this.max) {
            return false;
        }

        return true;
    }

    getFilterValue() {
        return { min: this.min, max: this.max };
    }
}

/**
 * Use this filter to filter dates. You can specify a start date, an end date or both.
 */
export class DateFilter extends Filter<DateRecord> {
    start: Date;
    end: Date;

    isActive() {
        return hasValue(this.start) || hasValue(this.end);
    }

    serialize(): DateRecord {
        return {
            key: this.key,
            type: 'date',
            from: this.start ? format(this.start, 'yyyy-MM-dd') : undefined,
            to: this.end ? format(this.end, 'yyyy-MM-dd') : undefined
        };
    }

    deserialize(record: DateRecord) {
        this.key = record.key;
        this.start = record.from ? new Date(record.from) : undefined;
        this.end = record.to ? new Date(record.to) : undefined;
        return this;
    }

    predicate(value: Date) {
        if (!this.isActive()) {
            return true;
        }
        if (!value) {
            return false;
        }

        const start = new Date(this.start).getTime(); // returns NaN if date is invalid
        const end = new Date(this.end).getTime();
        const date: any = new Date(value);

        if (isNaN(date) || !(date instanceof Date)) {
            throw new Error(
                `DateFilter can only process Date objects or ISO date strings! (got ${value as unknown as string})`
            );
        }

        const time = date.getTime();
        if (time < start || time > end) {
            // comparisons with NaN always return false
            return false;
        }

        return true;
    }
}

/**
 * Use this filter to search in a column.
 */
export class SearchFilter extends Filter<SearchRecord> {
    term = '';

    isActive() {
        return !!this.term;
    }

    serialize(): SearchRecord {
        return {
            key: this.key,
            type: 'search',
            term: this.term
        };
    }

    deserialize(record: SearchRecord) {
        this.key = record.key;
        this.term = record.term || '';
        return this;
    }

    predicate(test: string | string[]) {
        if (!this.isActive()) {
            return true;
        }

        if (test instanceof Array) {
            return test.some((t) => this.term.includes(t));
        } else {
            return this.term.includes(test);
        }
    }
}
