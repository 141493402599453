<div class="frame">
    <h4 class="title">{{ title }}</h4>
    <mat-slider class="slider" [max]="labels.length - 1" [min]="0" [step]="1" [disabled]="isDisabled">
        <input matSliderThumb [(ngModel)]="value" (valueChange)="changeValue($event)" />
    </mat-slider>

    <div class="description--container">
        @for (label of labels; track label) {
            <p class="description">
                {{ label }}
            </p>
        }
    </div>
    <div class="extras">
        <ng-content></ng-content>
    </div>
</div>
<div class="children">
    <ng-content select="ceres-slider-card"></ng-content>
</div>
