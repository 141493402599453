import { Portfolio as CeresPortfolio } from '@ceres/domain';

export class Portfolio implements CeresPortfolio {
    id = -1;
    abbreviation = '';
    title = '';

    constructor(object?: Partial<Portfolio>) {
        if (object) {
            Object.assign(this, object);
        }
    }
}
