import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HasPermissionModule } from '@ceres/ui';
import { SharedModule } from '../shared/shared.module';
import { FeaturesModule } from '../features/features.module';
import { HomeComponent } from './home.component';

@NgModule({
    declarations: [HomeComponent],
    imports: [
        CommonModule,
        SharedModule, // TODO ID1: NOT NEEDED MAYBE
        FeaturesModule.forChild(),
        HasPermissionModule
    ],
    exports: [HomeComponent]
})
export class HomeModule {}
