import { ChangeDetectionStrategy, Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
    selector: 'ceres-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LayoutComponent implements OnInit, OnDestroy {
    @Input()
    background: undefined | 'white' | 'gray' | 'grey';

    constructor(@Inject(DOCUMENT) private readonly doc: Document) {}

    ngOnInit(): void {
        this.doc.body.classList.add('new-wrapper');
    }

    ngOnDestroy(): void {
        this.doc.body.classList.remove('new-wrapper');
    }
}
