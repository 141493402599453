import { Inject, Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ENVIRONMENT } from '@ceres/frontend-helper';

@Injectable()
export class ApiKeyInterceptor implements HttpInterceptor {
    constructor(@Inject(ENVIRONMENT) private readonly environment: { apiKey: string }) {}

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        const authRequest = request.clone({
            headers: request.headers.append('x-api-key', this.environment.apiKey)
        });
        return next.handle(authRequest);
    }
}
