@if (data.isProjectLead) {
    <div>
        {{ 'reporting.dialogs.not-project-lead' | transloco }} <br />
        {{ 'reporting.dialogs.select-other-project' | transloco }}
    </div>
}
@if (!data.isProjectLead) {
    <div>
        {{ 'reporting.dialogs.only-project-lead' | transloco }}<br />
        {{ 'reporting.dialogs.no-project-lead' | transloco }}
    </div>
}
