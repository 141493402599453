export enum BVIMapperEnum {
    EBYDOS_SE = 'EBYDOS_SE',
    EBYDOS_DI = 'EBYDOS_DI',
    LEXA = 'LEXA',
    SITRIS = 'SITRIS',
    CONTROL_PAY_DI = 'CONTROL_PAY_DI',
    CONTROL_PAY_FLENDER = 'CONTROL_PAY_FLENDER',
    PEPS = 'PEPS',
    LP2 = 'LP2',
    FREIA = 'FREIA',
    CON_LOG = 'CON_LOG'
}
