import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnInit,
    ViewChild,
    Output,
    EventEmitter
} from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatTableDataSource } from '@angular/material/table';
import { FormControl } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { ListItem } from '@ceres/frontend-helper';
import { FilterBuilder2 } from '../../services/filter-builder2';

export interface Selection {
    allSelected: boolean;
    column: string;
    items?: ListItem[];
}

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'mat-header-cell[filter-builder]',
    templateUrl: './filter-header.component.html',
    styleUrls: ['./filter-header.component.scss']
})
export class FilterHeaderComponent implements OnInit {
    @Input() dataSource: MatTableDataSource<any>;
    @Input() filterBuilder: FilterBuilder2;
    @Input() column: string;
    @Input() sort: boolean;
    @Input() server: boolean;
    @Input() language: boolean;

    @ViewChild(MatMenuTrigger, {
        static: true
    })
    triggerMenu: MatMenuTrigger;

    @Input() searchFunction: boolean;

    @Output() selectedItems: EventEmitter<Selection> = new EventEmitter<{
        allSelected: true;
        column: string;
        items: [];
    }>();

    public get finalColumn(): string {
        return this.language === false ? this.column + 'En' : this.column;
    }

    searchField = new FormControl();
    filteredValues: ListItem[];
    $searchResults: Observable<ListItem[]>;

    allChecked: boolean;
    fewSelected: boolean;

    display = 'none';
    timeout: any;

    private storedElement: any;
    public get element(): any {
        return this.storedElement;
    }
    public set element(val) {
        this.storedElement = val;
    }

    @ViewChild('show', {
        static: true
    })
    show: ElementRef;
    @ViewChild('down', {
        static: true
    })
    down: ElementRef;
    @ViewChild('up', {
        static: true
    })
    up: ElementRef;

    constructor(private changeRef: ChangeDetectorRef) {}

    ngOnInit() {
        this.searchField.valueChanges.subscribe((term) => {
            this.$searchResults = this.filteredItemList(term);
        });
    }

    applyColumns() {
        const values = this.getValues().filter((sc) => sc.selected);
        const option = {
            selected: values,
            all: values.length === this.getValues().length
        };
        if (this.server) {
            this.filterBuilder.applyLoadFilter(this.finalColumn, option);
        } else {
            this.filterBuilder.onApplyMultiFilter(this.finalColumn, option);
        }
        this.selectedItems.emit(this.checkIfAllChecked(this.getValues()));
        this.changeRef.detectChanges();
    }

    preventClose(ev: MouseEvent) {
        ev.stopPropagation();
    }

    public get isDisabled(): boolean {
        return (
            !this.filterBuilder.filterValues[this.finalColumn] ||
            !this.filterBuilder.filterValues[this.finalColumn].length
        );
    }

    getValues() {
        let list: ListItem[];
        if (typeof this.filteredValues === 'undefined' || this.filteredValues == null) {
            list = this.filterBuilder.filterValues[this.finalColumn];
        } else {
            list = this.filteredValues;
        }

        if (typeof this.allChecked === 'undefined') {
            this.checkIfAllChecked(list);
        }

        return list;
    }

    deleteValue(ev: MouseEvent) {
        this.searchField.setValue('');
        this.preventClose(ev);
    }

    collapse(close?: boolean, event?: Event) {
        if (event) {
            this.element = (event.target as Node).parentElement.parentElement.parentElement;
        }

        if (close === true) {
            this.timeout = setTimeout(() => {
                this.show.nativeElement.style.display = 'none';
                this.element.style['background-color'] = 'rgb(235, 240, 245) !important';
                this.up.nativeElement.style.display = 'none';
                this.down.nativeElement.style.display = 'block';
            }, 2000);
        } else if (close === false) {
            if (this.timeout) {
                clearTimeout(this.timeout);
            }
            this.show.nativeElement.style.display = 'block';
            this.up.nativeElement.style.display = 'block';
            this.down.nativeElement.style.display = 'none';
            this.element.style['background-color'] = 'rgb(235, 240, 245) !important';
        } else {
            this.show.nativeElement.style.display = this.show.nativeElement.style.display === 'none' ? 'block' : 'none';
            this.element.style['background-color'] = 'rgb(235, 240, 245) !important';

            this.up.nativeElement.style.display = this.show.nativeElement.style.display === 'none' ? 'none' : 'block';
            this.down.nativeElement.style.display = this.show.nativeElement.style.display === 'none' ? 'block' : 'none';
        }
    }

    checkIfAllChecked(list: ListItem[]): Selection {
        if (list) {
            const selectedItems = list.filter((item) => item.selected);
            const length = selectedItems.length;
            if (length === list.length) {
                this.allChecked = true;
                this.fewSelected = false;
                return {
                    allSelected: true,
                    column: this.finalColumn,
                    items: selectedItems
                };
            } else {
                if (length === 0) {
                    this.allChecked = false;
                    this.fewSelected = false;
                } else {
                    this.allChecked = false;
                    this.fewSelected = true;
                }
                return {
                    allSelected: false,
                    column: this.finalColumn,
                    items: selectedItems
                };
            }
        }
    }

    selectAll() {
        if (this.fewSelected) {
            this.getValues().forEach((ac) => (ac.selected = true));
        } else {
            this.getValues().forEach((ac) => (ac.selected = this.allChecked));
        }
        this.checkIfAllChecked(this.getValues());
    }

    filteredItemList(term: string): Observable<ListItem[]> {
        if (term === '') {
            return (this.filteredValues = null);
        }
        this.filteredValues = this.filterBuilder.filterValues[this.finalColumn].filter((item) => {
            if (item.title == null) {
                return false;
            }

            const searchArray = term.split('*').filter((e) => e);
            if (searchArray.length && searchArray.length === 1) {
                term = term.replace('*', '');
            }
            if (searchArray.length && searchArray.length >= 2) {
                const matches = [];
                searchArray.forEach(() => {
                    matches.push({ boolean: false });
                });

                for (const { match, idx } of matches.map((e, i) => {
                    return {
                        match: e,
                        idx: i
                    };
                })) {
                    if (String(item.title).toLowerCase().indexOf(searchArray[idx].toLowerCase()) >= 0) {
                        if (!match.boolean) {
                            match.boolean = true;
                        }
                    }
                }
                return matches.filter((e) => !e.boolean).length === 0;
            } else {
                if (typeof item.title === 'number') {
                    return ((item.title as number) + '').startsWith(term);
                } else {
                    return item.title.toLowerCase().indexOf(term.toLowerCase()) !== -1;
                }
            }
        });

        return of(this.filteredValues);
    }
}
