import { NgControl } from '@angular/forms';
import { Directive, Input } from '@angular/core';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[disableControl]'
})
export class DisableControlDirective {
    constructor(private ngControl: NgControl) {}

    @Input() set disableControl(condition: boolean) {
        const action = condition ? 'disable' : 'enable';
        if (this.ngControl.control) {
            this.ngControl.control[action]();
        }
    }
}
