import { ExchangeRate } from './currency';
import { SkillSet } from './skill';

export interface Activity {
    id: number;
    artikelNummer: number;
    title: string;
    name?: string;
    translationKey: string;
    rankOrder: number;
    timeUnit: string;
    EK: number;
    VK: number;
    inActive: boolean;
    default?: boolean;
    currencyCode: string;
    additionalActivityDescription: string;
    exchangeRate: ExchangeRate;
    validFrom?: string;
    shortName?: string;
    colorCode?: string;
    skillSets?: SkillSet[];
}

export interface ApiActivity {
    Id: number;
    Artikelnummer: number;
    Title: string;
    translationKey: string;
    rankOrder: number;
    Zeiteinheit: string;
    Inactive: boolean;
    EK: number;
    VK: number;
}
