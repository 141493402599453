import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, DEFAULT_CURRENCY_CODE, NgModule } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import {
    HttpErrorInterceptor,
    ImpersonationInterceptor,
    AdditionalRoleInterceptor,
    ImpersonatedHttpClient,
    ApiKeyInterceptor,
    AppUserService,
    AuthService
} from '@ceres/shared/services';
import { ENVIRONMENT } from '@ceres/frontend-helper';
import { AppSettingsService } from '@ceres/features/admin-general';
import { filter, take, tap } from 'rxjs/operators';
import { EventTypes, OidcClientNotification, PublicEventsService } from 'angular-auth-oidc-client';
import { Observable } from 'rxjs';
import { BryntumSchedulerProModule } from '@bryntum/schedulerpro-angular';
import { environment } from '../environments/environment';
import { HomeModule } from './home/home.module';
import { AuthAngularClientModule } from './auth/auth.module';
import { TranslocoRootModule } from './transloco-root.module';
import { FeaturesModule } from './features/features.module';
import { SharedModule } from './shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AppRoutingModule,
        TranslocoModule,
        TranslocoRootModule,
        HomeModule,
        SharedModule.forRoot(),
        FeaturesModule.forRoot(),
        AuthAngularClientModule,
        BryntumSchedulerProModule
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ApiKeyInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpErrorInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AdditionalRoleInterceptor,
            multi: true
        },
        ImpersonationInterceptor,
        ImpersonatedHttpClient,
        {
            provide: DEFAULT_CURRENCY_CODE,
            useValue: 'EUR'
        },
        {
            provide: ENVIRONMENT,
            useValue: environment
        },
        {
            provide: APP_INITIALIZER,
            useFactory: initializeAppFactory,
            deps: [AuthService],
            multi: true
        }
    ],
    declarations: [AppComponent],
    bootstrap: [AppComponent]
})
export class AppModule {
    public constructor(
        private readonly eventService: PublicEventsService,
        private readonly appSettings: AppSettingsService,
        private readonly appUserService: AppUserService
    ) {
        // TODO ID1: move this logic out of here? move it into app.component at least
        this.appUserService.loggedInUser$
            .pipe(
                filter((user) => !!user),
                take(1)
            )
            .subscribe({
                next: () => {
                    this.appSettings.loadAppSettings();
                }
            });

        this.eventService
            .registerForEvents()
            .pipe(filter((notification: OidcClientNotification<any>) => notification.type === EventTypes.ConfigLoaded))
            .subscribe(() => {
                console.log('Oidc configuration successful loaded');
            });
    }
}

function initializeAppFactory(authService: AuthService): () => Observable<boolean> {
    return () =>
        authService.isAuthenticated$.pipe(
            filter((auth) => !auth),
            tap(() => {
                authService.login();
            })
        );
}
