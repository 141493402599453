import { Component, OnInit, Inject, ViewChild, AfterViewInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FilterService } from '@ceres/filter';
import { Bop } from '@ceres/domain';
import { BopService } from '../../services/bop.service';
import { FilterFactoryService } from '../../services/filter-factory.service';

@Component({
    selector: 'ceres-bop-add-dialog',
    templateUrl: './bop-add-dialog.component.html',
    styleUrls: ['./bop-add-dialog.component.scss']
})
export class BopAddDialogComponent implements OnInit, AfterViewInit {
    public filterService = new FilterService<Bop>();
    public dataSource = new MatTableDataSource<Bop>();
    public filters = {};

    displayedColumns = ['selected', 'bopNumber', 'bopTitle', 'businessPartner', 'bopLeader'];

    filterNames = ['bopNumber', 'bopTitle', 'businessPartner.fullName', 'bopLeader.name'];

    bopNumber: string;
    bopTitle: string;
    businessPartner: string;
    bopLeader: string;
    selectedBop: any;
    multiple = true;
    selectedBops = [];
    fullBop = false;

    filter = false;

    @ViewChild(MatSort, {
        static: true
    })
    sort: MatSort;
    @ViewChild(MatPaginator, {
        static: true
    })
    paginator: MatPaginator;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<BopAddDialogComponent>,
        public bopService: BopService,
        private filterFactory: FilterFactoryService
    ) {}

    ngOnInit() {
        this.filters = this.filterFactory.create(this.filterNames);
        this.filterService.init(Object.values(this.filters));
        this.filterService.dataChanged.subscribe((d) => {
            this.dataSource.data = d;
        });
        this.loadData();
    }

    ngAfterViewInit() {
        if (this.dataSource && !this.dataSource.paginator) {
            this.dataSource.paginator = this.paginator;
        }
        if (this.dataSource && !this.dataSource.sort) {
            this.dataSource.sort = this.sort;
            this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
        }
    }

    private loadData() {
        void this.bopService.getAll().then((bopData) => {
            this.filterService.setData(bopData);

            this.filter = true;
        });
    }

    changeSelected(element, event) {
        if (!this.multiple) {
            if (event.checked) {
                for (const bop of this.dataSource.data) {
                    (bop as any).selected = false;
                }
                this.selectedBop = element;
                element.selected = event.checked;
            } else {
                delete this.selectedBop;
            }
        } else {
            element.selected = event.checked;
            if (event.checked) {
                this.selectedBops.push(element);
            }
        }
    }

    public close() {
        this.dialogRef.close();
    }

    closeDialog() {
        this.dialogRef.close(this.selectedBops);
    }
}
