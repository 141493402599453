import { Inject, Injectable } from '@angular/core';
import { User, UserService } from '@ceres/shared/services';
import { ENVIRONMENT } from '@ceres/frontend-helper';
import { AuthUser } from '@ceres/domain';
import { Router } from '@angular/router';
import { AppUserService, ImpersonatedHttpClient } from '@ceres/shared/services';
import { SCDUser } from '../models';
import { NewBusinessPartnerService as BusinessPartnerService } from './new-business-partner.service';
import { SCDService } from './scd.service';

@Injectable({
    providedIn: 'root'
})
export class AppUserSessionService {
    constructor(
        @Inject(ENVIRONMENT)
        private readonly environment: { edgeService: string; guestAccess: boolean },
        private readonly router: Router,
        private readonly http: ImpersonatedHttpClient,
        private readonly userService: UserService,
        private readonly businessPartnerService: BusinessPartnerService,
        private readonly appUserService: AppUserService,
        private readonly scdService: SCDService
    ) {}

    public setSession(user: AuthUser): Promise<void> {
        return this.userService
            .getUser(user['custom:gid'])
            .then((user) => {
                this.loginSuccess(user);
            })
            .catch(async (error) => {
                if (this.environment?.guestAccess && error.status === 404) {
                    await this.createGuestUser(user);
                } else {
                    this.setSessionFailed();
                }
            });
    }

    private async createGuestUser(user: AuthUser): Promise<void> {
        try {
            let proceed = true;
            await this.userService.createGuestUserFromToken(user).catch(() => (proceed = false));

            const foundScdUsers: { records: SCDUser[] } = await this.scdService.getByGID(user.sub).toPromise();
            if (foundScdUsers && proceed) {
                await this.businessPartnerService.createBusinessPartnerFromSCDUser(foundScdUsers.records[0]);
            } else {
                throw new Error('[Authentication] No SCD User found or User exists in System.');
            }
            await this.router.navigate(['/']);
        } catch {
            this.setSessionFailed();
        }
    }

    private setSessionFailed(): void {
        this.appUserService.logout('/unauthorized');
    }

    private loginSuccess(user: User) {
        this.appUserService.setMainUser(user);
    }
}
