import { CapacityTypeEnum } from '../enums/capacity-type.enum';
import { ProjectProfile } from './project-profile';

export interface PreStaffingProject {
    projectId: number;
    projectName: string;
    projectLeaderName: string;
    projectGrouping: string;
    customer: string;
    startDate: string;
    endDate: string;
    activities: PreStaffingActivity[];
}

export interface PreStaffingActivity {
    activityId: number;
    activityTitle: string;
    activityCounter: number;
    requiredHours: number;
    plannedHours?: number | null;
}

export interface PreStaffingEmployee {
    userId: number;
    fullName: string;
    department: string;
    targetHours: Record<string, number> | number;
    businessArea: PreStaffingBusinessArea;
    activities: PreStaffingEmployeeActivity[];
    capacities: PreStaffingEmployeeCapacity[];
    employeeId: number;
    countryCode: string;
}

export interface PreStaffingBusinessArea {
    id: number;
    name: string;
}

export interface PreStaffingEmployeeActivity {
    id: number;
    name: string;
    shortName?: string;
    colorCode?: string;
}

/// changed for daily capacity
export interface PreStaffingEmployeeCapacity {
    projectId: number;
    projectTitle: string;
    activityId: number;
    isAbsence: boolean;
    from: string;
    to: string;
    hours: number;
}

export interface PreStaffingEditData {
    projectCapacities: PreStaffingEditDataCapacity[];
    otherCapacities: PreStaffingEditDataCapacity[];
}

export interface PreStaffingEditDataCapacity {
    hours: number;
    week: number;
    year: number;
}

export interface PreStaffingEditPutData {
    userId: number;
    activityId: number;
    employeeId: number;
    projectId: number;
    capacityType: CapacityTypeEnum;
    capacities: PreStaffingEditPutCapacity[];
}

export interface PreStaffingEditPutCapacity {
    from: string;
    to: string;
    hours: number;
}

export interface PreStaffingDeleteData {
    activityId: number;
    employeeId: number;
    projectId: number;
}

export interface PreStaffingEvent {
    id: number;
    startDate: string;
    endDate?: string;
    duration: number;
    durationUnit: string;
    name: string;
    projectName: string;
    projectId: number;
    projectType: string;
    mpNumber: number;
    shadowId?: string;
    cls?: string;
}

export interface PreStaffingPostEvent {
    startDate: string;
    endDate?: string;
    duration: number;
    durationUnit: string;
    name: string;
    projectName: string;
    projectId: number;
    shadowId?: string;
}

export interface PreStaffingPostEventWithProject {
    startDate: string;
    endDate?: string;
    duration: number;
    durationUnit: string;
    name: string;
    projectName: string;
    shadowId?: string;
    referenceProjectId: number;
}

export interface PreStaffingPatchEvent {
    id: number;
    startDate: string;
    endDate: string;
    duration: number;
    durationUnit: string;
    name: string;
    projectName: string;
    projectId: number;
    shadowId?: string;
}

export interface PreStaffingPostAssignment {
    event: number;
    resource: number[];
}

export interface PreStaffingPostAssignmentSingleEmployee {
    event: number;
    resource: number;
}

export interface PreStaffingPatchAssignment {
    event: number;
    resource: number[];
}

export interface PreStaffingPatchAssignmentSingleEmployee {
    event: number;
    resource: number;
}

export interface PreStaffingPostData {
    event: PreStaffingPostEvent;
    assignment: PreStaffingPostAssignment;
}

export interface PreStaffingPostDataPreliminary {
    event: PreStaffingPostEventWithProject;
    assignment: PreStaffingPostAssignment;
}

export interface PreStaffingPatchData {
    event: PreStaffingPatchEvent;
    assignment: PreStaffingPatchAssignment;
}

export interface PreStaffingPatchMpNumber {
    oldMpNumber: number;
    newMpNumber: number;
}

export interface PreStaffingPatchProjectType {
    isPreliminary: boolean;
    projectId: number;
}

export interface PreStaffingProjectV2 {
    projectId: number;
    projectName: string;
    mpNumber: number;
    employeeIds: string[];
    details: ProjectProfile;
}

export interface PreStaffingMappedProjectV2 {
    id: number;
    name: string;
}

export interface PreStaffingCalendar {
    id: string;
    name: string;
    unspecifiedTimeIsWorking: boolean;
    intervals: Partial<PreStaffingCalendarInterval>[];
}

export interface PreStaffingCalendarInterval {
    recurrentEndDate: string;
    recurrentStartDate: string;
    startDate: string;
    endDate: string;
    isWorking: boolean;
    cls: string;
}

// export interface PreStaffingEditPutCapacity {
//   weekNo: number;
//   year: number;
//   from: string; // date
//   to: string; // date
//   dailyCapacities:  PreStaffingEditPutCapacityDaily[];
// }
//
// export interface PreStaffingEditPutCapacityDaily {
//   hours: number;
//   date: string;
// }

export type Seniority = 'junior' | 'senior' | 'noTitle';
