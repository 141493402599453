export type CallbackFunction<T> = (data: T) => any;

export class EventEmitter<T> {
    private subscribers: CallbackFunction<T>[] = [];

    subscribe(cbk: CallbackFunction<T>) {
        this.subscribers.push(cbk);
    }

    emit(data: T) {
        this.subscribers.forEach((cbk) => {
            cbk(data);
        });
    }
}
