import { Component, Input, EventEmitter, Output, ViewChild } from '@angular/core';
import { MatTabGroup } from '@angular/material/tabs';
import { DateFilter } from '../../../../lib/filters';
import { FilterService } from '../../../../lib/filter-service';

@Component({
    selector: 'lib-date-filter',
    templateUrl: './date-filter.component.html',
    styleUrls: ['./date-filter.component.scss']
})
export class DateFilterComponent {
    @ViewChild('tabGroup') tabs: MatTabGroup;

    _filter: DateFilter;

    @Input() filter: DateFilter;
    @Input() filterService: FilterService;

    @Output() close = new EventEmitter();

    startDate: Date;
    endDate: Date;

    index = 0;

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    constructor() {}

    apply() {
        this.filter.start = this.startDate;
        this.filter.end = this.endDate;
        this.filterService.applyFilter(this.filter);
        this.close.next(true);
    }

    clear() {
        this.startDate = undefined;
        this.endDate = undefined;
        this.apply();
    }
}
