import { IUser, Role } from '@ceres/domain';
import { Employee } from './employee';

export class User implements IUser {
    id!: number;
    gid!: string;
    email!: string;
    name!: string;
    nameShort!: string;
    department?: string;
    country!: string;
    corporateName!: string;
    isActive!: boolean;
    details?: Employee;
    role!: Role;

    constructor(object?: Partial<User>) {
        if (object) {
            Object.assign(this, object);
        }
    }
}
