@for (bar of cellData; track bar?.projectId) {
    @if (bar.data !== null) {
        <div
            class="bar bar--filled"
            [ngStyle]="{
                'background-color': '#' + bar.bgColor,
                color: bar.textColor
            }"
            [ngClass]="{
                'bar--before': bar.data.before,
                'bar--after': bar.data.after
            }"
            (click)="handleClick($event, bar)"
        >
            {{ bar.data.percent.toFixed(2) }} %
            <span class="bar--tooltip">
                {{ bar.projectTitle }}
            </span>
        </div>
    }
    @if (bar.data === null) {
        <div class="bar"></div>
    }
}
