import { Component, Input, EventEmitter, Output, ViewChild } from '@angular/core';
import { Upload } from './upload-file.model';

@Component({
    selector: 'ceres-upload-file',
    templateUrl: './upload-file.component.html',
    styleUrls: ['./upload-file.component.scss']
})
export class UploadFileComponent {
    @Input() uploads: Upload[] = [];
    @Input() dndFiles: File[] = [];
    @Input() uploading = false;
    @Input() multipleUpload = true;
    @Input() validFileTypes: string[] | undefined;

    @Output() dndFilesChange: EventEmitter<File[]> = new EventEmitter<File[]>();
    @Output() validationMessage: EventEmitter<string> = new EventEmitter<string>();

    @ViewChild('fileInput', { static: true }) fileInput: HTMLInputElement | undefined;

    public dragging = false;

    public dragOver(event: DragEvent) {
        event.stopPropagation();
        return false;
    }

    public dragEnd() {
        this.dragging = false;
    }

    public dragStart() {
        this.dragging = true;
    }

    public drop(event: DragEvent) {
        event.preventDefault();
        const fileList = event.dataTransfer?.files;
        this.dragging = false;
        this.addFiles(fileList);
    }

    public fileClicked(fileList: FileList) {
        this.addFiles(fileList);
        this.fileInput.value = '';
    }

    private checkValidFormat(file: File) {
        let result = true;
        if (this.validFileTypes) {
            result = this.validFileTypes.includes(file.type);
        }

        return result;
    }

    private addFiles(fileList: FileList) {
        let message = '';
        if (this.multipleUpload) {
            for (let i = 0; i < fileList.length; i++) {
                this.dndFiles.push(fileList.item(i));
            }
        } else if (fileList.length > 0) {
            if (this.checkValidFormat(fileList[0])) {
                this.dndFiles = [fileList[0]];
            } else {
                message = 'Only: ' + this.validFileTypes?.join(', ');
                this.validationMessage.emit(message);
            }
        }

        this.dndFilesChange.emit(this.dndFiles);
    }

    public removeFromUpload(index: number) {
        this.dndFiles.splice(index, 1);
        this.dndFilesChange.emit(this.dndFiles);
    }

    protected get allowedFileTypes(): string {
        if (this.validFileTypes) {
            return this.validFileTypes.join(', ');
        }
        return '';
    }
}
