import { Employee } from './employee';
import { SignedURLUploadResponse } from './document';

export interface DataImportRequest {
    id: number;
    name: string;
    createdAt: Date;
    lastUpdatedAt: Date;
    status: DataImportRequestStatus;
    currentProgressValue: number;
    key: string;
    createdBy: Employee;
    details: string;
    options?: string;
    type: DataImportRequestType;
}

export enum DataImportRequestStatus {
    'JOB_CREATED',
    'WORKER_STARTED',
    'FILE_DOWNLOADED_SUCCESSFULLY',
    'WRITING',
    'SUCCESS',
    'FAILED'
}

export enum DataImportRequestType {
    'BILLING_JOURNAL',
    'COST_REPORT',
    'BILLING_JOURNAL_DIFA',
    'BVI_REPORT'
}

export interface PostDataImportRequestResponse {
    dataImportRequest: DataImportRequest;
    uploadParameters: SignedURLUploadResponse;
}

export interface ServiceImportResponse {
    data: any;
    message: string;
}

export interface DataImportSignedURLUploadRequest {
    name: string;
    contentType: string;
    options?: string;
}
