import { Injectable, EventEmitter } from '@angular/core';
import isObject from 'lodash-es/isObject';

const round = (value: number) => Math.round((Number.EPSILON + value) * 100) / 100;

@Injectable()
export class ExchangeRateScopeService {
    changes = new EventEmitter<{
        currencyCode: string;
        exchangeRate: number;
    }>();

    toDefaultCurrency(value: number) {
        if (value == null) {
            return value;
        }
        return +value / this.rate;
    }

    toLocalCurrency(value: number): number {
        if (value == null) {
            return value;
        }
        return round(+value * this.rate);
    }

    private _value: Partial<{
        currencyCode: string;
        exchangeRate:
            | number
            | {
                  value: number;
              };
    }>;

    get value() {
        return this._value;
    }

    set value(val: ExchangeRateScopeService['_value']) {
        this._value = val;
        this.changes.emit({
            currencyCode: this.value ? this.value.currencyCode : undefined,
            exchangeRate: this.rate
        });
    }

    get rate() {
        if (!this.value) {
            return 1;
        }
        return isObject(this.value.exchangeRate) ? this.value.exchangeRate.value : this.value.exchangeRate;
    }
}
