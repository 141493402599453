export interface TranslationOption {
    id: number;
    name: string;
    translationKey: string;
    rankOrder: number;
    hasTenantName?: boolean;
}

export interface TranslationOptionProbabilityClass extends TranslationOption {
    probability: number;
}

export interface TranslationOptionContractCurrency extends TranslationOption {
    symbol: string;
}
