import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MonthSelectorComponent } from './month-selector.component';

@NgModule({
    imports: [CommonModule, TranslocoModule, MatSelectModule, MatIconModule],
    declarations: [MonthSelectorComponent],
    exports: [MonthSelectorComponent]
})
export class MonthSelectorModule {}
