import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';
import { MatTableModule } from '@angular/material/table';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSortModule } from '@angular/material/sort';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ReactiveFormsModule } from '@angular/forms';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { DialogHeaderModule } from '../dialog-header/dialog-header.module';
import { ButtonModule } from '../button/button.module';
import { SelectFromTableDialogComponent } from './select-from-table-dialog.component';

@NgModule({
    imports: [
        CommonModule,
        TranslocoModule,
        MatTableModule,
        MatDialogModule,
        ButtonModule,
        DialogHeaderModule,
        MatSortModule,
        MatCheckboxModule,
        ReactiveFormsModule,
        MatPaginatorModule,
        MatIconModule,
        MatInputModule,
        MatFormFieldModule
    ],
    declarations: [SelectFromTableDialogComponent],
    exports: [SelectFromTableDialogComponent]
})
export class SelectFromTableDialogModule {}
