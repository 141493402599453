import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LoadingComponent } from './loading.component';

@NgModule({
    imports: [CommonModule, TranslocoModule, MatProgressSpinnerModule],
    declarations: [LoadingComponent],
    exports: [LoadingComponent]
})
export class LoadingModule {}
