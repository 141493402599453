@if (!isIE && (appUserService.loggedInUser$ | async)) {
    @if (news && news.length) {
        <div class="container">
            <div class="row news-list">
                @for (info of news; track info; let i = $index) {
                    @if (i < maxNews) {
                        <mat-card
                            [ngClass]="{
                                critical: info.critical,
                                info: !info.critical,
                                collapsed: info.collapsed
                            }"
                            class="col-12 mr-3 news-tile"
                        >
                            @if (info.collapsed) {
                                <mat-card-content>
                                    <table>
                                        <tr>
                                            <td id="news-img">
                                                <div>
                                                    <mat-icon>{{ info.critical ? 'flash_on' : 'info' }}</mat-icon>
                                                </div>
                                            </td>
                                            <td class="news-text">
                                                <strong>{{ info.title }}</strong>
                                                <p [innerHtml]="info.content"></p>
                                            </td>
                                            <td (click)="info.collapsed = false" class="news-close">
                                                <mat-icon>close</mat-icon>
                                            </td>
                                        </tr>
                                    </table>
                                </mat-card-content>
                            }
                            @if (!info.collapsed) {
                                <mat-card-content (click)="info.collapsed = true">
                                    <div class="news-text">
                                        <strong>{{ info.title }}</strong>
                                    </div>
                                </mat-card-content>
                            }
                        </mat-card>
                    }
                }
                @if (news && news.length > maxNews) {
                    <mat-card (click)="maxNews = maxNews + 3" class="news-tile info">
                        <mat-card-content>
                            <div class="news-text">
                                <strong>{{ 'my-ceres.buttons.show-more-messages' | transloco }}</strong>
                            </div>
                        </mat-card-content>
                    </mat-card>
                }
            </div>
        </div>
    }
    <div class="container spacer-page">
        @if (userName) {
            <h1 class="my-2 fs-4 fw-bold">{{ ('header.welcome' | transloco) + userName }}!</h1>
        }
        <div class="row">
            @for (card of cards; track card; let i = $index) {
                <ng-container *ceresRequiredFeatures="card.features">
                    @if (card.permission | hasPermission: permissions) {
                        <div [routerLink]="card.routerLink" class="col-md-3 col-sm-6 mb-3 mr-3">
                            <div class="tile-container">
                                <div class="tile-content" [style.backgroundImage]="'url(' + card.imageUrl + ')'">
                                    <div class="tile-icon">
                                        <i class="icon a-icon a-icon--{{ card.icon }} {{ card.icon }}"></i>
                                        <h4 class="card-title">{{ card.title | transloco }}</h4>
                                        <p class="caption">{{ card.content | transloco }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </ng-container>
            }
        </div>
    </div>
}
