import { Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { Message, MessageService, MessageType } from '@ceres/shared/services';
import { TranslocoService } from '@ngneat/transloco';

@Component({
    selector: 'ceres-control-center',
    templateUrl: './control-center.component.html',
    styleUrls: ['./control-center.component.scss']
})
export class ControlCenterComponent implements OnInit, OnDestroy {
    public showMessageInstantly = true;
    public showMessageList = false;
    public toast: Message | null = null;
    listOn = false;
    private readonly YOUVE_GOT_MESSAGES_ID: number = NaN;

    private readonly subscription = new Subscription();

    private timeOut = undefined;
    private readonly toastList$$ = new BehaviorSubject<Message[]>([]);
    public readonly toastList$: Observable<Message[]> = this.toastList$$.asObservable();

    constructor(
        public readonly messageService: MessageService,
        private readonly translationService: TranslocoService
    ) {}

    ngOnInit(): void {
        this.subscription.add(
            this.messageService.messages$.subscribe((msgs) => {
                if (this.listOn) {
                    return;
                }
                if (msgs.length < 6) {
                    if (msgs.length > 0) {
                        this.toastList$$.next([...this.toastList$$.getValue(), msgs[msgs.length - 1]]);
                    }
                } else {
                    const bulkMsgs = new Message(
                        this.YOUVE_GOT_MESSAGES_ID,
                        'control-center.general.new-messages',
                        this.translationService.translate('control-center.general.title'),
                        this.messagesType(msgs)
                    );
                    this.toastList$$.next([bulkMsgs]);
                }
            })
        );

        this.subscription.add(
            this.toastList$.subscribe(() => {
                if (this.toastList$$.getValue().length > 0) {
                    if (!this.timeOut) {
                        const toastList = this.toastList$$.getValue();
                        this.toast = toastList[toastList.length - 1];
                        this.timeOut = setTimeout(() => {
                            const toastList = [...this.toastList$$.getValue()];
                            toastList.shift();
                            this.timeOut = undefined;
                            this.toastList$$.next(toastList);
                        }, 4000);
                    }
                } else {
                    this.toast = null;
                }
            })
        );
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public toggleMessageList() {
        this.showMessageList = !this.showMessageList;
        if (this.showMessageList) {
            clearTimeout(this.timeOut);
            this.timeOut = undefined;
            this.toastList$$.next([]);
            this.toast = null;
            this.listOn = true;
        } else {
            this.listOn = false;
        }
    }

    noMessageArray(message: Message) {
        return message.message && !Array.isArray(message.message);
    }

    messagesType(messages: Message[]): MessageType {
        return messages.map((msg) => msg.type).includes(MessageType.ALERT)
            ? MessageType.ALERT
            : messages[messages.length - 1].type;
    }
}
