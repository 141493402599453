import { Month } from './month';

export class DropdownMonth {
    title: string;
    date: Date;
    value: string;

    constructor(object?: Partial<Month>) {
        Object.assign(this, object);
    }
}
