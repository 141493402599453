export enum ProjectTagType {
    None = 'None',
    InvestRoiContribution = 'InvestRoiContribution',
    InnovationNoRoi = 'InnovationNoRoi',
    Sales = 'Sales',
    CostCollectionCustomerRelated = 'CostCollectionCustomerRelated',
    CostCollectionGMSInternal = 'CostCollectionGMSInternal',
    HQOrderCZ = 'HQOrderCZ'
}

export interface ProjectTags {
    id: number;
    translationKey: string;
    tag: ProjectTagType;
}
