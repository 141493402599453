import { Injectable } from '@angular/core';
import { Employee } from '@ceres/shared/services';
import { CeresEmployeeConfig } from '../../../configs/employee/interfaces/ceres-employee-config.interface';
import EMPLOYEECONFIG from '../../../configs/employee/employee.config';

@Injectable()
export class EmployeeConfigService {
    employeeConfig: CeresEmployeeConfig = EMPLOYEECONFIG;

    getDisabledValues(): Partial<Employee> {
        const formData: Partial<Employee> = {};
        const conf = this.employeeConfig;

        // eslint-disable-next-line guard-for-in
        for (const k in conf) {
            const isActive = conf[k];
            if (!isActive) {
                switch (k) {
                    case 'employee.KFM':
                        formData.KFM = false;
                        break;
                    case 'employee.FK':
                        formData.FK = false;
                        break;
                    case 'employee.corporate-name':
                        formData.corporateName = 'SIEMENS';
                        break;
                    case 'employee.sollstunden':
                        formData.sollstunden = 40;
                        break;
                    case 'employee.urlaubstage':
                        formData.urlaubstage = 30;
                        break;
                    case 'employee.assigned-employees':
                        formData.assignedEmployees = [];
                        break;
                    case 'employee.other-activities':
                        formData.activities = [];
                        break;
                    case 'employee.internal-psp-element':
                        formData.internalPspElement = null;
                        break;
                    default:
                        break;
                }
            }
        }
        return formData;
    }

    validateLabel(label: string): boolean {
        return this.employeeConfig[label];
    }
}
