import { Holiday as CeresHoliday, Location } from '@ceres/domain';

export class Holiday implements CeresHoliday {
    id: number;
    title: string;
    location: Location[];
    date: string;

    constructor(object?: Partial<Holiday>) {
        if (object) {
            Object.assign(this, object);
        }
    }
}
