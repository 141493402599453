export enum MessageType {
    ALERT = 0,
    INFO = 1,
    WARN = 2
}

export class Message {
    id: number;
    title: string;
    obj?: object;
    message: string | string[];
    type: MessageType;
    source?: string;

    constructor(
        id: number,
        message: string | string[],
        title: string,
        type: MessageType,
        obj?: object,
        source?: string
    ) {
        this.id = id;
        this.message = message;
        this.title = title;
        this.type = type;
        this.obj = obj;
        this.source = source;
    }
}
