import { ProjectProfile as CeresProjectProfile } from '../entities/project-profile';
import { Employee } from '../entities/employee';
import { Technology } from '../entities/technology';
import { Location } from '../entities/location';
import { Activity } from '../entities/activity';
import { Program } from '../entities/program';
import { BusinessPartner } from '../entities/business-partner';
import { MaterialMaster } from '../entities/material-master';
import { BusinessArea } from '../entities/business-area';
import { PspElement } from '../entities/psp-element';
import { ExchangeRate } from '../entities/currency';
import { ProjectType } from '../entities/project-type';
import { TranslationOption } from '../entities/translation-option';

export class ProjectProfileDto implements CeresProjectProfile {
    id: number;
    copiedFrom?: number;
    classificationGrade: string;
    classificationScore: string;
    conAktiv: any;
    mpNumber: number;
    mpTitle: string;
    hasDetails: boolean;
    projektGruppierung: string;
    allBusinessAreas?: BusinessArea[];
    employees: Employee[];
    fsgGesamtprojekt: number;
    verrechnungsart: string;
    auftragswert: number;
    projektName: string;
    projektbeschreibung: string;
    url: string;
    dokumentationUrl: string;
    projektKategorie: string;
    projektMethode: string;
    lighthouseInnovation: boolean;
    productive: boolean;
    gmsServices: string;
    gmsValueAdd: string;
    task: string;
    technologies: Technology[];
    interfaceificationGrade: string;
    interfaceificationScore: number;
    result: string;
    projectLead: Employee;
    abteilungKunde: string;
    validFrom: string;
    validTo: string;
    location: Location;
    EmployeesId: number[];
    TechnologiesId: number[];
    activity: Activity[];
    originalActivity: Activity[];
    isFavorite: boolean;
    originalIsFavorite: boolean;
    isRemovable: boolean;
    favoriteId: number;
    sortOrder: number;
    originalSortOrder: number;
    singleActivity: Activity;
    planpreis: number;
    program: Program[];
    previousProgram: Program[];
    businessPartnerPre: string;
    businessPartnerSur: string;
    projectStatus: string;
    plankosten: number;
    istkosten: number;
    externalCosts: number;
    costCenterInternal: string;
    businessPartner: BusinessPartner;
    businessPartnerId: number;
    startDate: string;
    endDate: string;
    bestellwert: number;
    orgID: string;
    bestellnummer: string;
    angebotsnummer: number;
    verrechnungsland: string;
    abgerechnet: boolean;
    kundenart: string;
    verrechnungszyklus: string;
    invest: boolean;
    auslandskunde: boolean;
    istpreis: number;
    fsgExtern: number;
    approvalTec: boolean;
    approvalCom: boolean;
    accountingRelevance: number;
    orderQuantity: number;
    orderPosNr: number;
    materialMaster: MaterialMaster;
    istkostenStunden: number;
    istpreisStunden: number;
    istkostenExtern: number;
    istpreisExtern: number;
    leistungsempfaenger: BusinessArea;
    fsgWert: number;
    abgerechneterWert: number;
    spanne: number;
    abzurechnen: number;
    plannedExternalCosts: number;
    isaStatus: number;
    fSGModified: Date;
    commercialComment: string;
    projectComment: string;
    multipleBusinessPartner: boolean;
    folderId: number;

    forecastFY: number;
    forecastCostsOverallFY: number;
    forecastCostsExternalFY: number;

    // calculated values

    plannedCostsHours: number;

    plannedPriceHours: number;

    plannedCostsExternal: number;

    plannedPriceExternal: number;

    accruedCostsExternal: number;

    actualCosts: number;

    actualCostsExternal: number;

    actualCostsHours: number;

    actualPrice: number;

    actualPriceExternal: number;

    actualPriceHours: number;

    actualProfit: number;

    margin: number;

    pocProfit: number;

    pocValue: number;

    proportionalLinearRemainingBudget: number;

    remainingBudget: number;

    valueSettled: number;

    valueToBeSettled: number;

    vsp: number;

    pspElement: PspElement;

    prLeiterGid?: string;

    contractNumber?: string;

    partnerDepthStructure: string;

    currencyCode: string;

    constructor(object?: Partial<ProjectProfileDto>) {
        Object.assign(this, object);
    }

    exchangeRate: ExchangeRate;

    projectType?: ProjectType;

    maturityLevel?: TranslationOption;

    markedForDeletionTimestamp?: Date;

    projectSpaceTitle?: string;

    projectSpaceLink?: string;

    updatedBy?: string;
}
