import { AppUserService } from '@ceres/shared/services';
import { TranslocoService } from '@ngneat/transloco';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { IUser, rolePermissions } from '@ceres/domain';
import { Observable } from 'rxjs';
import { FeatureOf, FEATURES } from '@ceres/feature-toggles';
import { CeresFeaturesConfig } from '../../../features/interfaces/ceres-features-config.interface';
import { isIE } from '../../app.component';
import { TasksService } from '../services/tasks.service';
import { LoadingService } from '../services/loading.service';
import { ThemeService } from '../services/theme.service';
import { SearchComponent } from './search/search.component';

@Component({
    selector: 'ceres-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
    public isIE = isIE;
    public isNavigationPartlyOpen = false;
    public isNavigationOpen = false;
    public isUserMenuPartlyOpen = false;
    public isUserMenuOpen = false;
    public isSearchOpen = false;
    public currentUser: IUser;
    public permission = rolePermissions;
    isLoading = false;
    impersonatedEmployee$: Observable<IUser>;
    openTasksCount = 0;

    @ViewChild(SearchComponent) searchComp: SearchComponent;
    public checked = false;

    constructor(
        @Inject(FEATURES) public readonly features: FeatureOf<CeresFeaturesConfig>,
        private loadingService: LoadingService,
        private translateService: TranslocoService,
        public appUserService: AppUserService,
        private tasksService: TasksService,
        private themeService: ThemeService
    ) {}

    changeTheme() {
        this.checked = !this.checked;
        if (this.checked) {
            this.themeService.setDarkMode();
        } else {
            this.themeService.setLightMode();
        }
    }

    ngOnInit() {
        this.themeService.darkMode$.subscribe((darkMode) => {
            this.checked = darkMode;
        });

        this.appUserService.loggedInUser$.asObservable().subscribe((user) => {
            this.currentUser = user;
        });

        this.loadingService.state.subscribe((newState) => {
            this.isLoading = newState;
        });

        this.tasksService.tasks.subscribe((tasks) => {
            if (tasks) {
                this.openTasksCount = tasks.filter((t) => t.status === 'open').length;
            }
        });
        this.impersonatedEmployee$ = this.appUserService.impersonatedEmployee$.asObservable();
    }

    public check() {
        this.isSearchOpen = false;
    }
    public openSearch() {
        this.isSearchOpen = true;
        this.searchComp.focus();
    }

    public changeLanguage(): void {
        this.translateService.setActiveLang(this.translateService.getActiveLang() === 'en' ? 'de' : 'en');
    }

    public logout() {
        this.appUserService.logout();
    }
}
