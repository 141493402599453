import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'nearestQuarter',
    pure: false
})
export class DecimalQuarterPipe implements PipeTransform {
    transform(value: number): string {
        if (!value) {
            return '';
        }
        if (value % 1 !== 0) {
            const formattedNumber = (Math.floor(value * 4) / 4).toFixed(2);
            if (formattedNumber.includes('.00')) {
                return formattedNumber.split('.')[0];
            }
            return formattedNumber;
        }
        return value.toString();
    }
}
