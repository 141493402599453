<div
    class="drag"
    [ngClass]="{ active: dragging }"
    (dragover)="dragOver($event)"
    (dragenter)="dragStart()"
    (dragleave)="dragEnd()"
    (drop)="drop($event)"
    (click)="fileInput.click()"
>
    {{ 'shared.miscellaneous.drop' | transloco }}
</div>
<input
    type="file"
    #fileInput
    hidden
    (change)="fileClicked($event.target.files)"
    [multiple]="true"
    [accept]="allowedFileTypes"
/>
@if (!uploading) {
    <div class="upload-container">
        @for (file of dndFiles; track file; let i = $index) {
            <div class="upload-item">
                <span class="upload-name">{{ file.name }}</span>
                <span>
                    <i class="material-icons" (click)="removeFromUpload(i)">delete</i>
                </span>
            </div>
        }
    </div>
}

@if (uploading) {
    <div class="upload-container">
        @for (upload of uploads; track upload) {
            <div class="upload-item">
                <div class="upload-name">{{ upload.name }}</div>
                <mat-progress-bar mode="determinate" [value]="upload.progress"></mat-progress-bar>
            </div>
        }
    </div>
}
