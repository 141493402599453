import { MaterialMaster } from './material-master';
import { ProjectProfile } from './project-profile';

export interface AccountingElement {
    id: number;
    year: number;
    month: number;
    orgID: string;
    wertVerrechnung: number;
    bestellposition: string;
    anmerkung: string;
    anmerkungKFM: string;
    freigabePL: boolean;
    freigabeKfm: boolean;
    leistungszeitraumVon: Date | string; //TODO longterm remove date - frontend works with string and backend with date obj
    leistungszeitraumBis: Date | string; //TODO longterm remove date - frontend works with string and backend with date obj
    bestellmenge: number;
    pricePerUnit: number;
    bestellnummer: string;
    materialMaster: MaterialMaster;
    project: ProjectProfile | number;
    tmpElement?: boolean;
    projectProfileId?: number;
}
