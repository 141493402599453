import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {
    Component,
    Inject,
    OnInit,
    ViewChild,
    AfterViewChecked,
    ElementRef,
    OnDestroy,
    ViewEncapsulation
} from '@angular/core';
import { BusinessPartner, SearchQuery, SearchCategory, SearchResponse } from '@ceres/domain';
import { MatDialog } from '@angular/material/dialog';
import { FilterService } from '@ceres/filter';
import { Subject } from 'rxjs';
import { GlobalSearchService } from '@ceres/shared/services';
import { FormControl } from '@angular/forms';
import { takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { NewBusinessPartnerService as BusinessPartnerService } from '../services/new-business-partner.service';
import { BusinessPartnerNewComponent } from '../business-partner-new/business-partner-new.component';

@Component({
    selector: 'ceres-business-partner-selection',
    templateUrl: './business-partner-selection.component.html',
    styleUrls: ['./business-partner-selection.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class BusinessPartnerSelectionComponent implements OnInit, AfterViewChecked, OnDestroy {
    public filterService = new FilterService<BusinessPartner>();
    public busy = true;
    term = '';

    displayedColumns = ['selected', 'fullName', 'department', 'eMail', 'status'];

    public dataSource = new MatTableDataSource<any>([]);
    selectedPartners: BusinessPartner[] = [];

    multiple = false;
    partners: BusinessPartner[] = [];

    allChecked = false;
    fewSelected = false;

    @ViewChild(MatSort) sort!: MatSort;

    @ViewChild('searchInput', { static: true }) searchField!: ElementRef;

    public subscriptions = new Subject<boolean>();
    public termChanged = new Subject<string>();

    @ViewChild(MatPaginator)
    public paginator!: MatPaginator;
    public filterValue = new FormControl();

    public filters = {};

    businessPartnerCategory: SearchCategory<BusinessPartner> = {
        key: 'businesspartner',
        name: 'Business Partner',
        list: 'GMS_BusinessPartner',
        titleColumn: 'fullName',
        subtitleColumn: 'department',
        additionalSearchColumns: ['gid', 'eMail', 'location'] // Function?, Status?
    };
    categories = [this.businessPartnerCategory];

    searchQuery: SearchQuery = {
        term: ' ',
        count: 25,
        categories: this.categories,
        index: 'business-partner'
    };
    results: SearchResponse[] = [];

    constructor(
        public dialogRef: MatDialogRef<BusinessPartnerSelectionComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialog: MatDialog,
        private businessPartnerService: BusinessPartnerService,
        private search: GlobalSearchService
    ) {
        this.multiple = this.data.multiple;
        if (this.data && this.data.partners && this.data.partners.length) {
            this.selectedPartners = this.data.partners.filter((e: any) => e.id);
        }
    }

    ngOnInit(): void {
        setTimeout(() => {
            this.searchField.nativeElement.focus();
        }, 1000);
        this.filterValue.valueChanges
            .pipe(takeUntil(this.subscriptions), debounceTime(750), distinctUntilChanged())
            .subscribe((term) => {
                if (term.length > 0) {
                    this.busy = true;
                    this.dataSource.data = [];
                    this.searchQuery.term = term.trim();
                    void this.search.search(this.searchQuery).then((results) => {
                        this.results = results;
                        this.dataSource.data = this.results;
                        this.busy = false;
                    });
                }
            });
        this.busy = false;
    }

    ngAfterViewChecked(): void {
        if (this.dataSource && !this.dataSource.paginator) {
            this.dataSource.paginator = this.paginator;
        }
        if (this.dataSource && !this.dataSource.sort && this.sort) {
            this.dataSource.sort = this.sort;
            this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.next(true);
        this.subscriptions.unsubscribe();
    }

    changeSelected(element, event) {
        if (this.selectedPartners) {
            if (!this.multiple) {
                if (event.checked) {
                    for (const user of this.dataSource.data) {
                        user.selected = false;
                    }
                    this.selectedPartners = element;
                    element.selected = event.checked;
                } else {
                    delete this.selectedPartners;
                }
            } else {
                element.selected = event.checked;
                if (event.checked && this.selectedPartners) {
                    this.selectedPartners.push(element);
                } else {
                    this.selectedPartners.forEach((item, idx) => {
                        if (item.id === element.id) {
                            this.selectedPartners.splice(idx, 1);
                        }
                    });
                }
                if (this.dataSource.filteredData.length === this.selectedPartners.length) {
                    this.allChecked = true;
                    this.fewSelected = false;
                } else {
                    this.fewSelected = this.selectedPartners.length > 0;
                    this.allChecked = false;
                }
            }
        }
    }

    selectAll() {
        if (this.allChecked || this.fewSelected) {
            for (const user of this.dataSource.filteredData) {
                user.selected = true;
            }

            this.selectedPartners = [...this.dataSource.filteredData];
            this.fewSelected = false;
            this.allChecked = true;
        } else if (!this.allChecked) {
            this.selectedPartners = [];
            for (const user of this.dataSource.filteredData) {
                user.selected = false;
            }
        }
    }

    remove(element) {
        this.changeSelected(element, { checked: false });
        for (const item of this.dataSource.data) {
            if (item === element) {
                item.selected = false;
            }
        }
    }
    openNewBusinessPartnerDialog() {
        const dia = this.dialog.open(BusinessPartnerNewComponent, {
            disableClose: true,
            width: '1200px',
            height: '690px',
            data: { closePopup: true }
        });
        dia.afterClosed().subscribe((data) => {
            if (data) {
                this.selectedPartners.push(data);
                this.closeDialog(data);
            }
        });
    }

    closeDialog(selectedPartners) {
        if (!selectedPartners || selectedPartners.length === 0) {
            this.dialogRef.close(null);
        } else {
            this.dialogRef.close(selectedPartners);
        }
    }
}
