import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'select'
})
export class SelectPipe implements PipeTransform {
    transform(input: object, property: string): any {
        if (!input) {
            return input;
        }

        return input[property];
    }
}
