import { Injectable } from '@angular/core';
import { ImpersonatedHttpClient } from '@ceres/shared/services';
import { environment } from '../../../environments/environment';

@Injectable()
export class FolderService {
    constructor(private http: ImpersonatedHttpClient) {}

    public async ensureFolderExists(folderName: string, type: string) {
        const url = `${environment.edgeService}/folders/${type}/${folderName}`;
        try {
            await this.http.head(url).toPromise();
            return true;
        } catch (e) {
            return false;
        }
    }
    /**
     * @param contractId
     * @param type
     */
    public async createFolder(contractId: string, type: string) {
        return await this.http.post(`${environment.edgeService}/folders`, { name: contractId, type }).toPromise();
    }
}
