import { Portfolio as PortfolioEntity } from './portfolio';
import { PortfolioCategory as PortfolioCategoryEntity } from './portfolio-category';

export interface PortfolioReport {
    Id: number;
    Name: string;
    Portfolio: PortfolioEntity;
    Category: PortfolioCategoryEntity;
    Workload: number;
    BusinessResults: string;
    Productivity: string;
    Strategy: string;
    Acquisition: string;
    EmployeesFixed: number;
    EmployeesLeasing: number;
    EmployeesIntern: number;
    AccountingMonth: string;
    BOPVolume: number;
    AEActual: number;
    AEbereinigt: number;
    LESalesReal: number;
    LESalesPeriod: number;
    LECostReal: number;
    LECostPeriod: number;
    LESalesConservative: number;
    LECostConservative: number;
    LESalesOptimistic: number;
    LECostOptimistic: number;
    ChargingQuote: number;
}
