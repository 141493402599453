import { MatDialog } from '@angular/material/dialog';
import { MessageService } from '@ceres/shared/services';

export interface Card {
    title: string;
    icon?: string;
    routerLink?: string;
    role?: number; // 0 : all except Merchant/Erfasser, 1 : only admin, 2: admin/groupleader/teamleader
    draggable?: boolean;
    dialog?: MatDialog;
    message?: MessageService;
    tooltip?: string;
    imageUrl?: string;
    hoverImageUrl?: string;
    colorOverlay?: string;
    content?: string;
    permission?: string | string[];
    features: Array<unknown>;

    click?(opt?: any): void;
}
