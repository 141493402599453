import { Component, OnInit, Input, EventEmitter, Output, ViewEncapsulation, OnDestroy } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslocoService } from '@ngneat/transloco';
import { MultiValueFilter, FilterService } from './../../../../lib';

@Component({
    selector: 'lib-multi-select-filter',
    templateUrl: './multi-select-filter.component.html',
    styleUrls: ['./multi-select-filter.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class MultiSelectFilterComponent implements OnInit, OnDestroy {
    @Input() filter: MultiValueFilter;
    @Input() filterService: FilterService;
    @Input() isTranslationKey: boolean;
    @Input() translationPrefix: string;

    @Output() close = new EventEmitter();
    private destroy$ = new Subject<void>();

    selection = new SelectionModel<string | number | boolean>(true, []);

    allFilterValues: (string | number | boolean)[] = [];
    filterValues: (string | number | boolean)[] = [];

    searchTerm = '';

    allSelected = true;
    fewSelected = false;

    addToFilter = false;
    isLoading = false;

    constructor(private translocoService: TranslocoService) {}

    ngOnInit() {
        this.selection.changed.subscribe(() => {
            const count = this.selection.selected.length;
            this.allSelected = count === this.filterValues.length;
            this.fewSelected = !this.allSelected && count > 0;
        });
        this.init();
    }

    ngOnDestroy() {
        this.selection.changed.unsubscribe();
        this.destroy$.next();
        this.destroy$.complete();
    }

    search() {
        const s = this.searchTerm.toLowerCase();
        this.filterValues = this.allFilterValues.filter((el) => {
            const translatedString = this.getTranslation(String(el));
            return translatedString.toLowerCase().includes(s);
        });

        this.selection.clear();
        this.selection.select(...this.filterValues);
    }

    init() {
        this.isLoading = true;
        this.searchTerm = '';
        this.filterService
            .getFilterValues(this.filter)
            .pipe(takeUntil(this.destroy$))
            .subscribe(
                (vals) => {
                    this.allFilterValues = vals;
                    this.filterValues = vals;
                    this.selection.clear();

                    if (!this.filter.isActive()) {
                        // Filter not set means all values are selected
                        this.selection.select(...this.filterValues);
                    } else {
                        // Restore selection with available options
                        const sel = this.filter.selected.filter((f) => this.allFilterValues.includes(f));
                        this.selection.select(...sel);
                    }

                    this.isLoading = false;
                },
                () => (this.isLoading = false)
            );
    }

    apply() {
        if (this.selection.selected.length === this.allFilterValues.length) {
            // Remove filter if all options are selected
            this.selection.clear();
        } else if (this.searchTerm.length > 0 && this.addToFilter) {
            // Select old selection
            this.selection.select(...this.filter.selected);
        }
        this.filter.selected = this.selection.selected;
        this.filterService.applyFilter(this.filter);
        this.close.next(true);
    }

    toggleAll() {
        if (this.allSelected) {
            this.selection.clear();
        } else {
            this.selection.select(...this.filterValues);
        }
    }

    getTranslation(key: string): string {
        return this.translocoService.translate(key);
    }
}
