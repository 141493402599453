import { Document } from './document';

export enum FolderType {
    Default = 'Default',
    Program = 'Program',
    Project = 'Project',
    Contract = 'Contract'
}

export interface Folder {
    id: number;
    name: string;
    subFolders: Folder[];
    parentFolder?: Folder;
    parentFolderId: number;
    documents: Document[];
    type: FolderType;
}

export interface DeleteFilesAndFolders {
    folderIds: number[];
    documentIds: number[];
}
