export interface AuthUser {
    'custom:country': string;
    'custom:org_code': string;
    'custom:gid': string;
    'custom:company'?: string;
    preferred_username: string;
    given_name: string;
    family_name: string;
    email: string;
    sub: string;
    iat: number;
    exp: number;
    iss: string;
}
