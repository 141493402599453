<mat-form-field appearance="fill" class="number-cell">
    <mat-label></mat-label>
    <input
        matInput
        [value]="localValue"
        (blur)="handleChange($event)"
        [placeholder]="placeholder"
        type="number"
        [disabled]="disabled"
    />
</mat-form-field>
