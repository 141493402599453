<ceres-layout>
    <div class="filter-container" header>
        <div class="d-flex justify-content-between flex-wrap gap-10">
            <div class="title">{{ 'admin.general.settings.title' | transloco }}</div>
        </div>
        <div class="d-flex justify-content-between flex-wrap gap-20">
            <div class="d-flex flex-wrap gap-10">
                <ceres-button [routerLink]="['/admin']" icon="newton_product-catalogue">
                    <span class="d-none d-lg-block">{{ 'admin.general.admin-title' | transloco }}</span>
                </ceres-button>
                <ceres-button (click)="saveSettings()" [disabled]="settingsForm.invalid" icon="check-mark">
                    <span class="d-none d-lg-block">{{ 'admin.general.settings.save' | transloco }}</span>
                </ceres-button>
            </div>
        </div>
    </div>

    <form [formGroup]="settingsForm">
        <div class="row">
            <div class="col-4">
                <h4>
                    {{ 'admin.general.settings.date-range-type.title' | transloco }}
                </h4>
                <p>
                    {{ 'admin.general.settings.date-range-type.description' | transloco }}
                </p>
                <div class="my-2">
                    <mat-form-field class="mat-custom" appearance="fill">
                        <mat-label>{{ 'admin.general.settings.date-range-type.label' | transloco }}</mat-label>
                        <mat-select formControlName="dateRangeType">
                            <mat-option [value]="DateRangeType.FiscalYear">{{
                                'admin.general.settings.date-range-type.option.fiscal-year' | transloco
                            }}</mat-option>
                            <mat-option [value]="DateRangeType.Dynamic">{{
                                'admin.general.settings.date-range-type.option.dynamic' | transloco
                            }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </form>
</ceres-layout>
