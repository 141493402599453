import { TransactionalWorkload } from '..';

export interface TimeSheetViewEntity {
    id?: number;
    workingHours: number;
    originalWorkingHours: number;
    comment: string;
    originalComment: string;
    accountingMonth: string;
}

export interface TimeSheetGetBaseRequest {
    from: Date;
    to: Date;
    employeeId: number;
}

export interface TimeSheetGetFiltersResponse {
    projects: {
        id: number;
        mpNumber: number;
        name: string;
    }[];
    activities: {
        id: number;
        name: {
            de: string;
            en: string;
        };
    }[];
    businessPartners: {
        id: number;
        name: string;
    }[];
}

export interface TimeSheetGetAggregatesResponse {
    filtered: {
        sum: number;
        dates: {
            [date: string]: number;
        };
    };
    total: {
        sum: number;
        dates: {
            [date: string]: number;
        };
    };
}

export interface TimeSheetGetRequest extends TimeSheetGetBaseRequest {
    activities?: number[];
    projects?: number[];
    search?: string;
    page?: number;
    size?: number;
}

export interface TimeSheetGetResponse {
    project: {
        id: number;
        mpNumber: number;
        name: string;
    };
    businessPartner: string;
    activity: {
        id: number;
        name: string;
        translationKey: string;
    };
    transactionalWorkloads?: TransactionalWorkload[]; //TODO not used
    workloads: {
        [key: string]: Omit<TimeSheetViewEntity, 'originalWorkingHours' | 'originalComment'>;
    };
    isSum?: boolean;
    readonly?: boolean;
}

export interface TimeSheetPutRequest {
    id?: number;
    projectId: number;
    activityId: number;
    employeeId: number;
    workingHours: number;
    comment: string;
    accountingMonth: string;
}

export interface TimeSheetDeleteResponse {
    success: boolean;
    id?: number;
    message?: any;
}

export interface TimeSheetViewRow extends TimeSheetGetResponse {
    workloads: {
        [key: string]: TimeSheetViewEntity;
    };
}

export interface TimeSheetViewChange extends TimeSheetPutRequest {
    originalWorkingHours: number;
    originalComment: string;
}
