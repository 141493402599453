import { PaginationResponse } from './pagination';

export interface CostReport {
    fiscalYear: number;
    period: number;
    costCenter: string;
    coObjectName: string;
    costElement: number;
    costElementDescr: string;
    nameOfsettingAccount: string;
    nameCostReport: string;
    valueRepCur: number;
    valueTranCur: number;
    transCurrency: string;
    purchasingDocument: number;
    item: number;
    totalQuantity: number;
    postedUnitMeasure: string;
    purchaseOrderText: string;
    documentHeader: string;
    mpNumber: number;
    partnerGcc: string;
    coPartnerObjName: string;
    tradingPartner: string;
    postingDate: Date;
    documentDate: Date;
    createdOn: Date;
    timeOfEntry: Date;
    personnelNumber: number;
    documentNumber: string;
    postingRow: number;
    refDocNumber: string;
    offsettingAccount: string;
}

export interface PaginationCostResponse<T> extends PaginationResponse<T> {
    total: number;
    data: T[];
    sumValueRepCur: number;
    sumValueTransCur: number;
}

export interface CostReportFilterValues {
    fiscalYear: number[];
    period: number[];
    costCenter: string[];
    coObjectName: string[];
    costElement: number[];
    costElementDescr: string[];
    nameOfsettingAccount: string[];
    nameCostReport: string[];
    valueRepCur: number[];
    valueTranCur: number[];
    transCurrency: string[];
    purchasingDocument: number[];
    item: number[];
    totalQuantity: number[];
    postedUnitMeasure: string[];
    purchaseOrderText: string[];
    documentHeader: string[];
    mpNumber: number[];
    partnerGcc: string[];
    coPartnerObjName: string[];
    tradingPartner: string[];
    postingDate: Date;
    documentDate: Date;
    createdOn: Date;
    timeOfEntry: Date;
    personnelNumber: number[];
    documentNumber: string[];
    postingRow: number[];
    refDocNumber: string[];
    offsettingAccount: string[];
}
