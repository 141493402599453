<mat-form-field class="mat-custom w-100" [ngClass]="{ 'custom-input-invalid': invalid }" appearance="fill">
    <mat-label>{{ placeholder }}</mat-label>
    <input matInput [required]="required" [value]="valueTransformer(value)" readonly />
    @if (!isDisabled) {
        <div matSuffix>
            @if (!value) {
                <mat-icon (click)="openSelection($event)" [ngClass]="{ invalid: invalid }" class="mat-custom"
                    >edit</mat-icon
                >
            } @else {
                <mat-icon (click)="removeSelected($event)" class="mat-custom">delete</mat-icon>
            }
        </div>
    }
</mat-form-field>
