import { TranslocoService } from '@ngneat/transloco';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { Injectable } from '@angular/core';

@Injectable()
export class PaginatorService extends MatPaginatorIntl {
    constructor(private translate: TranslocoService) {
        super();

        this.translate.langChanges$.subscribe(() => {
            this.getTranslations();
        });

        this.getTranslations();
    }

    getTranslations() {
        this.translate
            .selectTranslate([
                'pagination.items-per-page',
                'pagination.next-page',
                'pagination.previous-page',
                'pagination.first-page',
                'pagination.last-page'
            ])
            .subscribe((translation) => {
                this.itemsPerPageLabel = translation['pagination.items-per-page'];
                this.nextPageLabel = translation['pagination.next-page'];
                this.previousPageLabel = translation['pagination.previous-page'];
                this.firstPageLabel = translation['pagination.first-page'];
                this.lastPageLabel = translation['pagination.last-page'];

                this.changes.next();
            });
    }

    getRangeLabel = (page: number, pageSize: number, length: number) => {
        if (length === 0 || pageSize === 0) {
            return `0 ${this.translate.translate('pagination.range-page')} ${length}`;
        }
        length = Math.max(length, 0);
        const startIndex = page * pageSize;
        const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
        return `${startIndex + 1} - ${endIndex} ${this.translate.translate('pagination.range-page')} ${length}`;
    };
}
