import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { GlobalSearchService, SearchQuery, SearchCategory, SearchResponse } from '@ceres/shared/services';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil, filter } from 'rxjs/operators';
import { ProjectProfile, Bop, Contract, BusinessPartner } from '@ceres/domain';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'ceres-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit, OnDestroy {
    public searchControl: FormControl = new FormControl('');
    public isLoading = false;

    @Output() public closeSearch: EventEmitter<void> = new EventEmitter<void>();
    @ViewChild('searchInput', { static: true }) input: ElementRef;

    projectsCategory: SearchCategory<ProjectProfile> = {
        key: 'projects',
        name: 'projects.general.title-projects',
        list: 'GMSProjects',
        titleColumn: 'mpTitle',
        subtitleColumn: 'mpNumber',
        additionalSearchColumns: [
            'projectLead',
            'projektGruppierung',
            'businessPartnerPre',
            'businessPartnerSur',
            'costCenterInternal'
        ]
    };
    contractsCategory: SearchCategory<Contract> = {
        key: 'contract',
        name: 'offers.general.title',
        list: 'ContractsMetaData',
        titleColumn: 'title',
        subtitleColumn: 'id',
        additionalSearchColumns: [
            'contractDescription',
            'businessArea',
            'mpNumber',
            // 'contractManager',
            'serviceProvider',
            'customer'
        ]
    };
    bopCategory: SearchCategory<Bop> = {
        key: 'bop',
        name: 'bop.general.bop-title',
        list: 'GMS_BOP',
        titleColumn: 'bopTitle',
        subtitleColumn: 'bopNumber',
        additionalSearchColumns: ['bopLeader', 'businessPartner']
    };
    businessPartnerCategory: SearchCategory<BusinessPartner> = {
        key: 'businesspartner',
        name: 'businesspartner.general.title',
        list: 'GMS_BusinessPartner',
        titleColumn: 'fullName',
        subtitleColumn: 'department',
        additionalSearchColumns: ['gid', 'eMail', 'location'] // Function?, Status?
    };

    categories = [this.projectsCategory, this.contractsCategory, this.bopCategory, this.businessPartnerCategory];

    searchQuery: SearchQuery = {
        term: '',
        count: 10,
        categories: this.categories
    };

    results: SearchResponse[];

    private destroy: Subject<boolean> = new Subject<boolean>();

    constructor(private search: GlobalSearchService) {}

    ngOnInit() {
        this.searchControl.valueChanges
            .pipe(
                debounceTime(750),
                filter((term) => term !== null && term !== undefined),
                takeUntil(this.destroy)
            )
            .subscribe(() => {
                const term = this.searchControl.value;
                if (term.length > 0) {
                    this.isLoading = true;
                    this.searchQuery.term = term.trim();
                    void this.search.search(this.searchQuery).then((results) => {
                        this.results = results;
                        this.lockMainScrolling(true);
                        this.isLoading = false;
                    });
                }
            });
    }

    ngOnDestroy(): void {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }

    onNavigate() {
        this.results = undefined;
        this.searchControl.reset();
        this.lockMainScrolling(false);
        this.closeSearch.emit();
    }

    clear() {
        this.results = undefined;
        this.searchControl.reset();
        this.focus();
        this.lockMainScrolling(false);
    }

    focus() {
        requestAnimationFrame(() => {
            if (this.input) {
                this.input.nativeElement.focus();
            }
        });
    }

    lockMainScrolling(lock: boolean) {
        document.documentElement.style.position = lock ? 'fixed' : 'initial';
    }

    closeSearchField() {
        this.clear();
        this.blur();
    }

    blur() {
        if (!this.searchControl.touched || this.searchControl.value === '' || this.searchControl.value === null) {
            this.lockMainScrolling(false);
            this.closeSearch.emit();
        }
    }
}
