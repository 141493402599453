<div class="breadcrumb">
    @for (item of items; track item; let i = $index) {
        <span>
            @if (i) {
                <span class="seperator">/</span>
            }
            {{ item }}
        </span>
    }
</div>
