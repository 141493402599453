export class SCDResponse {
    records!: SCDUser[];
}

export interface SCDUser {
    gid: string;
    alternatePhone: string;
    building: string;
    cn: string;
    commonNameNational: string;
    commonNickName: string;
    companyCode: string;
    contractStatus: string;
    costLocation: string;
    costLocationUnit: string;
    department: string;
    departmentNumber: string;
    departmentText: string;
    displayName: string;
    fax: string;
    gender: string;
    gidManager: string;
    firstName: string;
    firstNameNat: string;
    graduateTitle: string;
    initials: string;
    joinDate: string;
    location: string;
    mail: string;
    function: string;
    mobile: string;
    nickName: string;
    organization: string;
    orgId: string;
    organizationUnit: string;
    personalFax: string;
    personalIdNumber: string;
    personalTitle: string;
    preferredSN: string;
    representation: string;
    room: string;
    secretary: string;
    surName: string;
    surnameAdd: string;
    surnameAmpr: string;
    sponsor: string;
    status: string;
    surNameNat: string;
    phone: string;
    unit: string;
    unitId: string;
    country: string;
    dateRetrieved: number;
}
