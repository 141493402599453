import { TranslocoService } from '@ngneat/transloco';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { MessageService } from '@ceres/shared/services';

@Component({
    selector: 'ceres-session-timer',
    templateUrl: './session-timer.component.html',
    styleUrls: ['./session-timer.component.scss']
})
export class SessionTimerComponent implements OnInit, OnDestroy {
    public timeLeft: number;
    private timer: NodeJS.Timeout;

    constructor(
        private oidcSecurityService: OidcSecurityService,
        private messageService: MessageService,
        private translateService: TranslocoService
    ) {}

    async ngOnInit() {
        this.timeLeft = await this.calculateMinutesTillTokenExpiration();
        this.startTimer();
    }

    ngOnDestroy() {
        clearInterval(this.timer);
    }

    private async getTokenExpiredValue() {
        const token = await this.oidcSecurityService.getIdToken().toPromise();
        return JSON.parse(atob(token.split('.')[1])).exp;
    }

    private minuteDifferenceBetweenDates(dt2: Date, dt1: Date): number {
        let diff = (dt2.getTime() - dt1.getTime()) / 1000;
        diff /= 60;
        return Math.abs(Math.round(diff));
    }

    private async calculateMinutesTillTokenExpiration(): Promise<number> {
        const exp = await this.getTokenExpiredValue();
        return this.minuteDifferenceBetweenDates(new Date(exp * 1000), new Date());
    }

    private startTimer() {
        this.timer = setInterval(async () => {
            if (this.timeLeft > 0) {
                this.timeLeft = await this.calculateMinutesTillTokenExpiration();
            } else {
                this.timeLeft <= 0;
                clearInterval(this.timer);
            }
        }, 60000);
    }

    public renewToken() {
        this.oidcSecurityService.forceRefreshSession().subscribe(
            async () => {
                this.timeLeft = await this.calculateMinutesTillTokenExpiration();
                this.messageService.pushMessage({
                    message: 'control-center.session-timer.success-renew-token',
                    title: this.translateService.translate('control-center.session-timer.extend-session'),
                    type: 1
                });
            },
            () => {
                this.messageService.pushMessage({
                    message: 'control-center.session-timer.error-renew-token',
                    title: this.translateService.translate('control-center.session-timer.extend-session'),
                    type: 2
                });
            }
        );
    }
}
