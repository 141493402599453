import { NgModule } from '@angular/core';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TranslocoModule } from '@ngneat/transloco';
import { DialogHeaderComponent } from './dialog-header.component';

@NgModule({
    imports: [DragDropModule, TranslocoModule],
    declarations: [DialogHeaderComponent],
    exports: [DialogHeaderComponent]
})
export class DialogHeaderModule {}
