import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { AppUserService } from '@ceres/shared/services';
import { flatten } from 'lodash-es';
import {
    addDays,
    addMinutes,
    addMonths,
    addYears,
    differenceInCalendarMonths,
    endOfMonth,
    format,
    getDate,
    getDay,
    getMonth,
    getWeek,
    getWeeksInMonth,
    getYear,
    isSaturday,
    isSunday,
    isWeekend,
    lastDayOfMonth,
    lastDayOfWeek,
    max,
    min,
    setWeek,
    startOfMonth,
    startOfWeek,
    subDays
} from 'date-fns';
import { CapacityTypeEnum, Employee, Holiday, Location } from '@ceres/domain';
import * as moment from 'moment';
import { Moment } from 'moment';
import { adjustDateForTimezone } from '../helpers/timezone';
import { CapacityUnitModel } from '../interfaces/capacity-unit.model';
import { Day, Month, Week } from '../models';
import { HolidayService } from './holiday.service';

const now = new Date();
const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());

@Injectable({
    providedIn: 'root'
})
export class DateService {
    static DB_DATE_FORMAT = 'yyyy-MM-dd';

    currentWeekNo: number;
    currentMonth: Month;
    displayedMonths: Month[];
    monthsDE = [
        'Januar',
        'Februar',
        'März',
        'April',
        'Mai',
        'Juni',
        'Juli',
        'August',
        'September',
        'Oktober',
        'November',
        'Dezember'
    ];
    monthsEN = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
    ];
    displayedDays = {};
    allCalendars: Week[];
    private currentBusinessYear: { date: Date; dateEnd: Date; label: string };

    constructor(
        private holidayService: HolidayService,
        private translate: TranslocoService,
        private appUserService: AppUserService
    ) {
        this.generateMonths();
        this.currentWeekNo = DateService.getWeekNumber(new Date());
    }

    static getWeekendDates(startDate: Date, endDate: Date): Set<string> {
        startDate.setFullYear(startDate.getFullYear() - 1);
        endDate.setFullYear(endDate.getFullYear() + 1);

        const result = new Set<string>();

        let dateIndex = new Date(startDate);
        while (format(dateIndex, DateService.DB_DATE_FORMAT) <= format(endDate, DateService.DB_DATE_FORMAT)) {
            // Day of the week, Monday = 1 (weeks starts from 1 / Monday)
            const dayOfWeek = getDay(dateIndex) === 0 ? 7 : getDay(dateIndex);

            if (dayOfWeek > 5) {
                result.add(format(dateIndex, DateService.DB_DATE_FORMAT));
            }
            dateIndex = addDays(dateIndex, 1);
        }

        return result;
    }

    static getBusinessYearRangeNew(date: Date): Date[] {
        const currentMonth = getMonth(date);
        const currentYear = getYear(date);
        const start = new Date(currentMonth < 9 ? currentYear - 1 : currentYear, 9, 1);
        const end = new Date(currentMonth >= 9 ? currentYear + 1 : currentYear, 8, 30);

        return [addMinutes(start, -date.getTimezoneOffset()), addMinutes(end, -date.getTimezoneOffset())];
    }

    static getStartOfFiscalYearNew(date: Date | string | number): Moment {
        return moment(this.getBusinessYearRangeNew(new Date(date))[0]);
    }

    static getEndOfFiscalYearNew(date: Date | string | number): Moment {
        return moment(this.getBusinessYearRangeNew(new Date(date))[1]);
    }

    static getFiscalQuarterNumber(date: Date): number {
        const fiscalYearStart = this.getStartOfFiscalYearNew(date).toDate();
        const monthDiff = differenceInCalendarMonths(date, fiscalYearStart);

        // +1 so we are not indexing from 0
        return Math.floor(monthDiff / 3) + 1;
    }

    static offsetDateBasedOnCapacityType(value: Date, unitType: CapacityTypeEnum): Date {
        let offset;

        if (unitType === CapacityTypeEnum.Daily) {
            offset = addMonths(value, 1);
            offset = subDays(offset, 1);
        } else if (unitType === CapacityTypeEnum.Weekly) {
            offset = lastDayOfWeek(value);
            offset = new Date(getYear(offset), getMonth(offset) + 1, 0);
            if (isSaturday(offset)) {
                offset = subDays(offset, 1);
            }
            if (isSunday(offset)) {
                offset = subDays(offset, 2);
            }
            offset = lastDayOfWeek(offset, { weekStartsOn: 0 });
        } else {
            offset = addYears(value, 1);
            offset = subDays(offset, 1);
        }

        // If the unit type is a week, make sure to put the offset on the last day of the week e.g. Friday
        if (unitType === CapacityTypeEnum.Weekly) {
            offset = addDays(startOfWeek(offset, { weekStartsOn: 1 }), 4);
        }

        return offset;
    }

    static getStartEndDateInterval(value: Date, unitType: CapacityTypeEnum): [Date, Date] {
        const currentMonth = value.getUTCMonth();
        const currentYear = value.getUTCFullYear();
        let firstDay = new Date(currentYear, currentMonth, 1);
        if (unitType === CapacityTypeEnum.Weekly) {
            if (isWeekend(firstDay)) {
                firstDay = addDays(firstDay, 2);
            }
            firstDay = startOfWeek(firstDay, { weekStartsOn: 1 });
        } else if (unitType === CapacityTypeEnum.Monthly && currentMonth !== 0) {
            firstDay.setMonth(0);
        } else if (
            unitType === CapacityTypeEnum.Quarterly &&
            currentMonth !== DateService.getStartOfFiscalYearNew(firstDay).month()
        ) {
            const firstFiscalYearDate = DateService.getStartOfFiscalYearNew(firstDay);
            firstDay.setFullYear(firstFiscalYearDate.year());
            firstDay.setMonth(firstFiscalYearDate.month());
        }

        return [firstDay, DateService.offsetDateBasedOnCapacityType(firstDay, unitType)];
    }

    static formatDate(date: Date): string {
        return format(date, this.DB_DATE_FORMAT);
    }

    static areSameDay(firstDate: Date, secondDate: Date): boolean {
        return this.formatDate(firstDate) === this.formatDate(secondDate);
    }

    static advanceDateByUnitType(date: Date, unitType: CapacityTypeEnum): Date {
        switch (unitType) {
            case CapacityTypeEnum.Daily:
                return addDays(date, 1);
            case CapacityTypeEnum.Weekly:
                return addDays(date, 7);
            case CapacityTypeEnum.Monthly:
                return addMonths(date, 1);
            case CapacityTypeEnum.Quarterly:
                return addMonths(date, 3);
        }
    }

    static getEndDateByUnitType(startDate: Date, unitType: CapacityTypeEnum): Date {
        switch (unitType) {
            case CapacityTypeEnum.Daily:
                return new Date(startDate);
            case CapacityTypeEnum.Weekly:
                return addDays(startDate, 4);
            case CapacityTypeEnum.Monthly:
                return lastDayOfMonth(startDate);
            case CapacityTypeEnum.Quarterly:
                return subDays(addMonths(startDate, 3), 1);
        }
    }

    static getWeekNumber(d: Date) {
        const date = new Date(d.getTime());
        date.setHours(0, 0, 0, 0);
        date.setDate(date.getDate() + 3 - ((date.getDay() + 6) % 7));
        const week1 = new Date(date.getFullYear(), 0, 4);

        return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000 - 3 + ((week1.getDay() + 6) % 7)) / 7);
    }

    static mapCalendarWeeksToCapacityUnitModel(weeks: Week[]): CapacityUnitModel[] {
        return weeks.map(
            (week) =>
                ({
                    unitType: CapacityTypeEnum.Weekly,
                    unit: week.weekNo,
                    startDate: week.first.date,
                    endDate: week.last.date
                }) as CapacityUnitModel
        );
    }
    /**
     * Given a month an a years, it returns the number of business days available
     * @param month : the month as a number, should be in interval [1, 12]
     * @param year : the year as a number
     * @param holidays : a set of dates representing unavailable dates
     */
    static getNumberOfBusinessDaysForMonth(month: number, year: number, holidays?: Set<string>): number {
        const dateSet = new Set<string>();
        let currentDate = new Date(year, month, 1);
        const endDate = lastDayOfMonth(currentDate);
        while (currentDate <= endDate) {
            const dateString = format(currentDate, DateService.DB_DATE_FORMAT);
            if (!isWeekend(currentDate) && !holidays?.has(dateString)) {
                dateSet.add(dateString);
            }
            currentDate = addDays(currentDate, 1);
        }
        return dateSet.size;
    }
    static getNumberOfBusinessDaysForInterval(from: Date, to: Date, holidays?: Set<string>): number {
        const dateSet = new Set<string>();
        let currentDate = new Date(from);
        while (currentDate <= to) {
            const dateString = format(currentDate, DateService.DB_DATE_FORMAT);
            if (!isWeekend(currentDate) && !holidays?.has(dateString)) {
                dateSet.add(dateString);
            }
            currentDate = addDays(currentDate, 1);
        }
        return dateSet.size;
    }

    static adaptCapacityInterval(
        capacityInterval: { from: string | Date; to: string | Date },
        projectInterval: { startDate: string | Date; endDate: string | Date }
    ): { from: Date; to: Date } {
        return {
            from: max([new Date(capacityInterval.from), new Date(projectInterval.startDate)]),
            to: min([new Date(capacityInterval.to), new Date(projectInterval.endDate)])
        };
    }

    static adaptCapacityIntervalFormatted(
        capacityInterval: { from: string | Date; to: string | Date },
        projectInterval: { startDate: string | Date; endDate: string | Date }
    ): { from: string; to: string } {
        const { from: adaptedFrom, to: adaptedTo } = this.adaptCapacityInterval(capacityInterval, projectInterval);
        return {
            from: format(adaptedFrom, this.DB_DATE_FORMAT),
            to: format(adaptedTo, this.DB_DATE_FORMAT)
        };
    }

    translateUnit(unit: number, unitType: CapacityTypeEnum): string {
        const translationPrefix = 'shared.calendar.';
        switch (unitType) {
            case CapacityTypeEnum.Daily:
                return `${this.translate.translate(translationPrefix + 'abbreviations.weekdays-in-numbers.' + unit)}`;

            case CapacityTypeEnum.Weekly:
                return `${this.translate.translate(translationPrefix + 'abbreviations.calendar-week')} ${unit}`;

            case CapacityTypeEnum.Monthly:
                return `${this.translate.translate(translationPrefix + 'months-in-numbers.' + unit)}`;

            case CapacityTypeEnum.Quarterly:
                return `${this.translate.translate(translationPrefix + 'abbreviations.quarter')}${unit}`;
        }

        return '';
    }

    /**
     * @Deprecated use the firstDayOfCurrentMonthNew instead!
     */
    public get firstDayOfCurrentMonth(): Date {
        return new Date(new Date().setDate(1));
    }

    public get firstDayOfCurrentMonthNew(): Moment {
        const now = new Date();
        return moment(addMinutes(startOfMonth(now), -now.getTimezoneOffset()));
    }

    public get lastDayOfCurrentMonth() {
        return addMinutes(
            endOfMonth(new Date(this.currentMonth.Date.getFullYear(), this.currentMonth.Date.getMonth(), 0)),
            -now.getTimezoneOffset()
        );
    }

    /**
     * @Deprecated use the getStartOfFiscalYearNew instead!
     */
    public getStartOfFiscalYear(date: Date | string | number): Date {
        return this.getBusinessYearRange(new Date(date))[0];
    }

    /**
     * @Deprecated use the getEndOfFiscalYearNew instead!
     */
    public getEndOfFiscalYear(date: Date | string | number): Date {
        return this.getBusinessYearRange(new Date(date))[1];
    }

    public getStartOfFiscalYearNew(date: Date | string | number): Moment {
        return moment(this.getBusinessYearRangeNew(new Date(date))[0]);
    }

    public getEndOfFiscalYearNew(date: Date | string | number): Moment {
        return moment(this.getBusinessYearRangeNew(new Date(date))[1]);
    }

    public get fiscalEndDate() {
        if (!this.currentBusinessYear) {
            this.getBusinessYears();
        }
        return new Date(this.currentBusinessYear.dateEnd.getFullYear(), this.currentBusinessYear.dateEnd.getMonth(), 0);
    }

    generateMonths() {
        if (this.displayedMonths) {
            return this.displayedMonths;
        }

        const date = new Date();
        const result = [];

        let count = 0;
        let year = date.getFullYear() - 1;

        const currentMonth = `${date.getMonth() + 1}-${date.getFullYear()}`;

        for (let start = date.getMonth(); start < 12; start++) {
            const element: Month = new Month(this.translate);
            element.TitleDE = `${this.monthsDE[start]} ${year}`;
            element.TitleEN = `${this.monthsEN[start]} ${year}`;
            element.Date = new Date(year, start, 1);
            element.Value = `${start + 1}-${year}`;

            result.push(element);
            if (element.Value === currentMonth) {
                this.currentMonth = element;
            }
            count++;
            if (count === 24) {
                this.displayedMonths = result;
                return this.displayedMonths;
            } else if (start === 11) {
                start = -1;
                year++;
            }
        }
    }

    monthDiff(date1: Date, date2: Date) {
        let difference;
        difference = (date2.getFullYear() - date1.getFullYear()) * 12;
        difference -= date1.getMonth() + 1;
        difference += date2.getMonth() + 1;
        return difference <= 0 ? 0 : difference;
    }

    public dayDiff(date1: Date, date2: Date) {
        const diffTime = Math.abs(new Date(date2).getTime() - new Date(date1).getTime());
        return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    }

    // NOT USED! REMOVE?
    generateDaysWithoutHolidays(selectedMonth: Month) {
        const indexOfSelectedMonth = this.displayedMonths.findIndex((e) => e.Value === selectedMonth.Value);
        const previousMonth = this.displayedMonths[indexOfSelectedMonth - 1];
        const nextMonth = this.displayedMonths[indexOfSelectedMonth + 1];

        const days = {};

        const months = [previousMonth, selectedMonth, nextMonth];
        for (const month of months) {
            const result: Day[] = [];
            if (month) {
                const date = new Date(month.Date);
                let endDate;
                if (date.getMonth() + 1 === 13) {
                    endDate = new Date(date.getFullYear() + 1, 1, 1);
                } else {
                    endDate = new Date(date.getFullYear(), date.getMonth() + 1, 1);
                }
                while (date < endDate) {
                    const day = new Day(this.translate);
                    day.value = date.getDay();
                    day.day = date.getDate();
                    day.month = date.getMonth() + 1;
                    day.year = date.getFullYear();
                    day.isHoliday = false;
                    day.holiday = null;
                    day.date = new Date(date.getFullYear(), date.getMonth(), date.getDate());
                    day.weekNo = DateService.getWeekNumber(day.date);

                    result.push(day);
                    date.setDate(date.getDate() + 1);
                }
                if (date >= endDate) {
                    days[month.Value] = result;
                }
            }
        }
        return days;
    }

    generateDaysWithLocationHolidays(
        selectedMonth: Month,
        employee?: Employee,
        isCacheEnabled = false
    ): Promise<Day[]> {
        return new Promise<Day[]>((resolve) => {
            const { previousMonth, nextMonth } = this.getPreviousAndNextMonth(selectedMonth);
            if (isCacheEnabled && this.isSelectedMonthAlreadyGenerated(selectedMonth, previousMonth, nextMonth)) {
                resolve(this.displayedDays[selectedMonth.Value]);
            } else {
                let location = this.appUserService.getLocation();
                if (employee) {
                    location = employee.location;
                }

                void this.getHolidays(location).then((holidays) => {
                    const months = [previousMonth, selectedMonth, nextMonth];
                    this.generateDaysMap(months, holidays, false);
                    resolve(this.displayedDays[selectedMonth.Value]);
                });
            }
        });
    }

    generateDaysWithAllHolidays(selectedMonth: Month, location: Location): Promise<Day[]> {
        return new Promise<Day[]>((resolve) => {
            const { previousMonth, nextMonth } = this.getPreviousAndNextMonth(selectedMonth);

            void this.getAdminHolidays(location).then((holidays) => {
                const months = [previousMonth, selectedMonth, nextMonth];
                this.generateDaysMap(months, holidays, false);
                resolve(this.displayedDays[selectedMonth.Value]);
            });
        });
    }

    private isSelectedMonthAlreadyGenerated(selectedMonth: Month, previousMonth: Month, nextMonth: Month): boolean {
        return (
            this.displayedDays[selectedMonth.Value] &&
            previousMonth &&
            this.displayedDays[previousMonth.Value] &&
            nextMonth &&
            this.displayedDays[nextMonth.Value]
        );
    }

    private getPreviousAndNextMonth(selectedMonth: Month) {
        const indexOfSelectedMonth = this.displayedMonths.findIndex((e) => e.Value === selectedMonth.Value);
        const previousMonth = this.displayedMonths[indexOfSelectedMonth - 1];
        const nextMonth = this.displayedMonths[indexOfSelectedMonth + 1];
        return { previousMonth, nextMonth };
    }

    private generateDaysMap(months: Month[], holidays: Holiday[], isCacheEnabled = true) {
        for (const month of months) {
            if (month && (!this.displayedDays[month.Value] || !isCacheEnabled)) {
                const result: Day[] = [];
                const date = new Date(month.Date);
                let endDate;
                if (date.getMonth() + 1 === 12) {
                    endDate = new Date(date.getFullYear() + 1, 0, 1);
                } else {
                    endDate = new Date(date.getFullYear(), date.getMonth() + 1, 1);
                }
                while (date < endDate) {
                    const day = new Day(this.translate);
                    day.value = date.getDay();
                    day.day = date.getDate();
                    day.month = date.getMonth() + 1;
                    day.year = date.getFullYear();
                    day.isHoliday = false;
                    day.holiday = null;
                    day.date = new Date(date.getFullYear(), date.getMonth(), date.getDate());
                    day.weekNo = DateService.getWeekNumber(day.date);

                    const hday = holidays.find((e) => {
                        const holiday = new Date(e.date);
                        return (
                            date.getFullYear() === holiday.getFullYear() &&
                            date.getMonth() === holiday.getMonth() &&
                            date.getDate() === holiday.getDate()
                        );
                    });
                    if (hday) {
                        day.isHoliday = true;
                        day.holiday = hday;
                    }

                    result.push(day);
                    date.setDate(date.getDate() + 1);
                }
                if (date >= endDate) {
                    this.displayedDays[month.Value] = result;
                }
            }
        }
    }

    // NOT USED
    /* generateWeeks(month: Month): Week[] {
    const filter = this.displayedMonths.filter(e => e.Value === month.Value);
    if (filter.length && filter[0].Weeks) {
      return filter[0].Weeks;
    }
    const endResult: Week[] = [];
    let firstDayOfWeek: any;
    let added = false;

    for (const { day, index } of this.displayedDays[month.Value].map(
      (e, idx) => ({ day: e, index: idx })
    )) {
      if (index === 0 || day.value === 1) {
        added = false;
        firstDayOfWeek = day;
      }

      if (
        day.value === 5 ||
        (index === this.displayedDays[month.Value].length - 1 && !added)
      ) {
        added = true;
        const week = new Week(this.translate);
        week.first = firstDayOfWeek;
        week.last = day;
        week.number = endResult.length;

        endResult.push(week);
      }
    }
    filter[0].Weeks = endResult;
    return endResult;
  }*/

    getDaysForCalendarUnits(first: CapacityUnitModel, second: CapacityUnitModel) {
        const result = [];
        for (const month of Object.keys(this.displayedDays)) {
            result.push(
                ...this.displayedDays[month].filter((e: Day) => e.date >= first.startDate && e.date <= second.endDate)
            );
        }
        return result;
    }

    getDays(first: Week, second: Week) {
        const result = [];
        for (const month of Object.keys(this.displayedDays)) {
            result.push(
                ...this.displayedDays[month].filter(
                    (e: Day) => e.date >= first.first.date && e.date <= second.last.date
                )
            );
        }
        return result;
    }

    // NOT USED
    /* getWeeks(first: Week, second: Week) {
    return this.displayedMonths
      .map(e => e.CalendarWeeks)[0]
      .filter(
        e =>
          e.first.date >= first.first.date &&
          second.last.date <= second.last.date
      );
  }*/

    generateCalendarWeeks(selectedMonth: Month): Promise<Week[]> {
        // Helper function to create a day object from a specific date
        const generateDay = (date: Date): Day => {
            const day = new Day(this.translate);
            day.date = date;
            day.day = getDate(date);
            day.holiday = null;
            day.isHoliday = false;
            day.month = getMonth(date) + 1;
            day.value = getDay(date);
            day.weekNo = getWeek(date, {
                weekStartsOn: 1,
                firstWeekContainsDate: 4
            });
            day.year = getYear(date);
            return day;
        };

        // Get current month
        // Used for a sideeffect, which should be prohibited
        const filter = this.displayedMonths.find((e) => e.Value === selectedMonth.Value);
        if (filter && filter.CalendarWeeks) {
            return new Promise((resolve) => resolve(filter.CalendarWeeks));
        }

        // 4. Januar ist immer in KW 1, die Woche startet am Montag (1)
        // Get first week of the month
        const firstWeek = getWeek(selectedMonth.Date, {
            weekStartsOn: 1,
            firstWeekContainsDate: 4
        });
        // Get week count for the month
        const weeksCount = getWeeksInMonth(selectedMonth.Date, { weekStartsOn: 1 });
        const filteredWeeks: Week[] = [];

        for (let i = 0; i < weeksCount; i++) {
            let weekNoForCw; // we need to set this to 1 so that the weeks in january are not 53, 54, 55, ....
            let weekNoForCalc;
            /* setWeek needs the "real" cw, e.g. Sunday 2023 is still in cw52 and the 1st of January.
      The system internally works with weeks starting on sundays.
      If the sunday would be still in a cw from the last year but the monday would be in cw1 of the new year(happened for example in 2022 and 2023),
      the weekDay variable would be the first weekday of cw1 in the last year
      Hence for display purposes we nee weekNoForCw and for calculation purposes we need weekNoForCalc */
            if (selectedMonth.TitleDE.includes('Januar') && i > 0) {
                weekNoForCw = 0 + i;
                weekNoForCalc = firstWeek + i;
            } else {
                weekNoForCw = firstWeek + i;
                weekNoForCalc = firstWeek + i;
            }

            const weekDay = setWeek(selectedMonth.Date, weekNoForCalc, {
                weekStartsOn: 1,
                firstWeekContainsDate: 4
            });
            const firstDay = startOfWeek(weekDay, { weekStartsOn: 1 });
            const lastDay = addDays(firstDay, 4);
            if (i > 0 || getMonth(lastDay) === getMonth(selectedMonth.Date)) {
                const week = new Week(this.translate);
                week.first = generateDay(firstDay);
                week.last = generateDay(lastDay);
                week.weekNo = weekNoForCw;
                week.number = i;
                filteredWeeks.push(week);
            }
        }
        // Map filteredWeeks to set right number
        const weeks = filteredWeeks.map((value, index) => {
            value.number = index;
            return value;
        });
        // Sideeffect to set current displayedMonth.CalendarWeeks
        filter.CalendarWeeks = weeks;
        return new Promise((resolve) => resolve(weeks));
    }

    async generateAllCalendarWeeks() {
        if (this.allCalendars) {
            return this.allCalendars;
        }
        const currentDate = new Date();
        const date = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0);
        await Promise.all(this.displayedMonths.map((e) => this.generateDaysWithLocationHolidays(e)));

        const filteredDisplayedMonths = this.displayedMonths.filter((e) => e.Date >= date);
        for (const month of filteredDisplayedMonths) {
            void this.generateCalendarWeeks(month);
        }

        const mappedCalendarWeeks = filteredDisplayedMonths.map((e) => e.CalendarWeeks);
        this.allCalendars = flatten(mappedCalendarWeeks).filter((e, idx, arr) => {
            if (idx === 0) {
                return true;
            }
            return e.weekNo !== arr[idx - 1].weekNo;
        });
        return this.allCalendars;
    }

    getHolidays(location: Location) {
        return this.holidayService.getAllByLocation(location);
    }

    private getAdminHolidays(location: Location) {
        if (location) {
            return this.holidayService.getAllManualAndGeneratedByLocationHolidays(location);
        }
        return this.holidayService.getAllManualHolidays();
    }

    getBusinessYearRange(date: Date) {
        date = adjustDateForTimezone(date);
        const currentMonth = date.getMonth();
        const d1 = new Date(date.getFullYear(), date.getMonth(), date.getDate());
        const d2 = new Date(date.getFullYear(), date.getMonth(), date.getDate());

        if (currentMonth < 9) {
            d1.setMonth(d1.getMonth() - 12);
        } else {
            d2.setMonth(d2.getMonth() + 12);
        }

        return [
            adjustDateForTimezone(new Date(d1.getFullYear(), 9, 1)),
            adjustDateForTimezone(new Date(d2.getFullYear(), 8, 30))
        ];
    }

    getBusinessYearRangeNew(date: Date): Date[] {
        const currentMonth = getMonth(date);
        const currentYear = getYear(date);
        const start = new Date(currentMonth < 9 ? currentYear - 1 : currentYear, 9, 1);
        const end = new Date(currentMonth >= 9 ? currentYear + 1 : currentYear, 8, 30);

        return [addMinutes(start, -date.getTimezoneOffset()), addMinutes(end, -date.getTimezoneOffset())];
    }

    getBusinessYearsWithTimezoneAdjustment() {
        const currentBusinessYearRange = this.getBusinessYearRange(today);
        const currentBusinessYearStart = currentBusinessYearRange[0];
        const previousYear = new Date(
            currentBusinessYearStart.getFullYear() - 1,
            currentBusinessYearStart.getMonth(),
            currentBusinessYearStart.getDate()
        );
        const currentYear = new Date(
            currentBusinessYearStart.getFullYear(),
            currentBusinessYearStart.getMonth(),
            currentBusinessYearStart.getDate()
        );
        const nextYear = new Date(
            currentBusinessYearStart.getFullYear() + 1,
            currentBusinessYearStart.getMonth(),
            currentBusinessYearStart.getDate()
        );
        const twoYears = new Date(
            currentBusinessYearStart.getFullYear() + 2,
            currentBusinessYearStart.getMonth(),
            currentBusinessYearStart.getDate()
        );
        const currentBusinessYear = this.getBusinessYear(today, '/');
        const previousBusinessYear = this.getBusinessYear(previousYear, '/');
        const nextBusinessYear = this.getBusinessYear(nextYear, '/');
        this.currentBusinessYear = {
            date: currentBusinessYearStart,
            dateEnd: subDays(nextYear, 1),
            label: currentBusinessYear
        };

        return [
            {
                date: previousYear,
                dateEnd: subDays(currentYear, 1),
                label: previousBusinessYear
            },
            this.currentBusinessYear,
            {
                date: nextYear,
                dateEnd: subDays(twoYears, 1),
                label: nextBusinessYear
            }
        ];
    }

    getBusinessYears() {
        const currentBusinessYearRange = this.getBusinessYearRange(today);
        const currentBusinessYearStart = currentBusinessYearRange[0];
        const previousYear = new Date(
            currentBusinessYearStart.getFullYear() - 1,
            currentBusinessYearStart.getMonth(),
            currentBusinessYearStart.getDate()
        );
        const nextYear = new Date(
            currentBusinessYearStart.getFullYear() + 1,
            currentBusinessYearStart.getMonth(),
            currentBusinessYearStart.getDate()
        );
        const twoYears = new Date(
            currentBusinessYearStart.getFullYear() + 2,
            currentBusinessYearStart.getMonth(),
            currentBusinessYearStart.getDate()
        );
        const currentBusinessYear = this.getBusinessYear(today, '/');
        const previousBusinessYear = this.getBusinessYear(previousYear, '/');
        const nextBusinessYear = this.getBusinessYear(nextYear, '/');

        this.currentBusinessYear = {
            date: addMinutes(currentBusinessYearStart, -now.getTimezoneOffset()),
            dateEnd: addMinutes(subDays(nextYear, 1), -now.getTimezoneOffset()),
            label: currentBusinessYear
        };

        return [
            {
                date: addMinutes(previousYear, -now.getTimezoneOffset()),
                dateEnd: addMinutes(subDays(currentBusinessYearStart, 1), -now.getTimezoneOffset()),
                label: previousBusinessYear
            },
            {
                date: addMinutes(currentBusinessYearStart, -now.getTimezoneOffset()),
                dateEnd: addMinutes(subDays(nextYear, 1), -now.getTimezoneOffset()),
                label: currentBusinessYear
            },
            {
                date: addMinutes(nextYear, -now.getTimezoneOffset()),
                dateEnd: addMinutes(subDays(twoYears, 1), -now.getTimezoneOffset()),
                label: nextBusinessYear
            }
        ];
    }

    public getBusinessYear(date: Date, separator?: string): string {
        const dates = this.getBusinessYearRange(date);
        return [
            dates[0].getFullYear().toString().substring(2, 4),
            dates[1].getFullYear().toString().substring(2, 4)
        ].join(separator || '');
    }
}
