import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AuthUser } from '@ceres/domain';

@Injectable({
    providedIn: 'root'
})
export class AuthUserService {
    public loggedInAuthUser$: BehaviorSubject<AuthUser> = new BehaviorSubject<AuthUser>(null);
}
