import { Pipe, PipeTransform } from '@angular/core';
import { ProjectProfile } from '@ceres/domain';

@Pipe({
    name: 'projectSelected'
})
export class ProjectSelectedPipe implements PipeTransform {
    transform(project: ProjectProfile, selectedProjects: Map<number, ProjectProfile>): boolean {
        if (!project || !selectedProjects || selectedProjects.size === 0) {
            return false;
        }
        return selectedProjects.has(project.mpNumber);
    }
}
