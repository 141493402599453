import { Month as CeresMonth } from '@ceres/domain';
import { TranslocoService } from '@ngneat/transloco';
import { Week } from './week';

export class Month implements CeresMonth {
    TitleDE: string;
    TitleEN: string;
    Date: Date;
    Value: string;
    Weeks: Week[];
    CalendarWeeks: Week[];

    constructor(
        private translate: TranslocoService,
        object?: Partial<Month>
    ) {
        Object.assign(this, object);
    }

    public title?(): string {
        return this.translate.getActiveLang() === 'en' ? this.TitleEN : this.TitleDE;
    }
}
