<div class="row dialog-header" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <div class="col-11 left">
        <h4>
            {{ (multiple ? 'scd.addPersons' : 'shared.buttons.scd-add-person') | transloco }}
        </h4>
    </div>
    <div class="col-1 right">
        <button (click)="dialogRef.close()">
            <i class="a-icon a-icon--close"></i>
        </button>
    </div>
</div>
<div class="row mb-2">
    <div class="col-6 col-md-12">
        <ceres-search-field [dataSource]="dataSource" fixMargin="true"></ceres-search-field>
    </div>
</div>
<div class="row">
    <div class="col-12">
        <div class="table-container">
            <mat-table [dataSource]="dataSource" matSort>
                <ng-container matColumnDef="selected">
                    <mat-header-cell *matHeaderCellDef>
                        @if (multiple) {
                            @if (!fewSelected) {
                                <mat-checkbox (change)="selectAll()" [(ngModel)]="allChecked"></mat-checkbox>
                            }
                            @if (fewSelected) {
                                <mat-checkbox (change)="selectAll()" class="mat-checkbox-semichecked"> </mat-checkbox>
                            }
                        }
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <mat-checkbox
                            [(ngModel)]="element.selected"
                            [disabled]="activity && !element.selected && element.assignedActivities.length === 0"
                            (change)="changeSelected(element, $event)"
                        ></mat-checkbox>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="title">
                    <mat-header-cell *matHeaderCellDef
                        ><span>{{ 'shared.miscellaneous.name' | transloco }}</span></mat-header-cell
                    >
                    <mat-cell *matCellDef="let element">
                        {{ element.name ? element.name : element.user ? element.user.title : element.title }}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="status-ico">
                    <mat-header-cell
                        filter
                        *matHeaderCellDef
                        [sort]="true"
                        [filter]="filterService.getColumnFilters('isActive')"
                        [filterService]="filterService"
                        [isTranslationKey]="true"
                        [translationPrefix]="'shared.miscellaneous.'"
                        column="status-ico"
                    ></mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        @if (element.isActive) {
                            <i class="material-icons">lock</i>
                        }
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="portfolio">
                    <mat-header-cell
                        filter
                        *matHeaderCellDef
                        [sort]="true"
                        name="{{ 'shared.properties.portfolio' | transloco }}"
                        [filter]="filterService.getColumnFilters('portfolio.title')"
                        [filterService]="filterService"
                        column="portfolio.title"
                    >
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">{{ element.portfolio?.title }}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="teamName">
                    <mat-header-cell
                        filter
                        *matHeaderCellDef
                        [sort]="true"
                        name="{{ 'shared.properties.business-area' | transloco }}"
                        [filter]="filterService.getColumnFilters('businessAreas.name')"
                        [filterService]="filterService"
                        column="teamName"
                    ></mat-header-cell>
                    <mat-cell *matCellDef="let element">{{
                        element.allBusinessAreas
                            ? filterBusinessAreas(element.allBusinessAreas)
                            : element.businessArea?.name
                    }}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="squadName">
                    <mat-header-cell
                        filter
                        *matHeaderCellDef
                        [sort]="true"
                        name="{{ 'shared.properties.squad' | transloco }}"
                        [filter]="filterService.getColumnFilters('squads.name')"
                        [filterService]="filterService"
                        column="squadName"
                    ></mat-header-cell>
                    <mat-cell *matCellDef="let element">{{
                        element.allBusinessAreas
                            ? filterSquads(element.allBusinessAreas)
                            : filterSquads([element.businessArea])
                    }}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="activity">
                    <mat-header-cell *matHeaderCellDef
                        ><span>{{ 'employee.properties.activity' | transloco }}</span></mat-header-cell
                    >
                    <mat-cell *matCellDef="let element">
                        @if (getEmployeeActivities(element)) {
                            <mat-select
                                [(ngModel)]="element.assignedActivities"
                                [compareWith]="compareLookups"
                                multiple
                            >
                                @for (item of getEmployeeActivities(element); track item?.id) {
                                    <mat-option [value]="item"> {{ item.translationKey | transloco }}</mat-option>
                                }
                            </mat-select>
                        }
                    </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
            </mat-table>
            @if (busy) {
                <ceres-loading [insideTable]="true"></ceres-loading>
            }
        </div>
        <mat-paginator #paginator [length]="dataSource.data.length" [pageSizeOptions]="[15, 30, 50]" [pageSize]="15">
        </mat-paginator>
    </div>
</div>
<div class="row mt-2">
    <div class="col-12">
        <div class="d-flex gap-10">
            <ceres-button
                (click)="closeDialog(selectedEmployees)"
                [disabled]="activity && !selectedHaveActivities(selectedEmployees)"
                [icon]="'check-mark'"
            >
                {{ 'shared.buttons.apply' | transloco }}
            </ceres-button>
            <ceres-button (click)="closeDialog(allChecked)" [style]="'red'" [icon]="'close'">
                {{ 'shared.buttons.cancel' | transloco }}
            </ceres-button>
        </div>
    </div>
</div>
