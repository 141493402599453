// TODO refactor: this navigation object is not used correctly or needs to be removed
export const navigation = [
    {
        title: 'My CERES',
        open: false,
        icon: 'user-account',
        translation: 'my-ceres.general.my-ceres-title',
        children: [
            {
                title: 'Tasks',
                open: false,
                link: '/tasks',
                translation: 'my-ceres.general.tasks-title'
            }
            // {
            //   title: 'Dashboard',
            //   open: false,
            //   link: '/',
            //   translation: 'navigation.dashboard'
            // },
        ]
    },
    {
        title: 'Business partner',
        translation: 'businesspartner.general.title',
        open: false,
        icon: 'newton_product-catalogue',
        children: [
            {
                title: 'Business partner',
                open: false,
                link: '/businesspartner',
                translation: 'businesspartner.general.title'
            }
            // {
            //   title: 'Campaigns (soon)',
            //   open: false,
            //   link: '',
            //   translation: 'businesspartner.general.campaign-title',
            // },
        ]
    },
    {
        title: 'Sales',
        open: false,
        icon: 'calculate',
        translation: 'sales-activity.general.sales-title',
        children: [
            {
                title: 'Business Opportunities (BOP)',
                open: false,
                link: '/bop',
                translation: 'bop.general.bop-title'
            },
            {
                title: 'Sales Activity',
                open: false,
                link: '/sales-activity',
                translation: 'sales-activity.general.title'
            },
            {
                title: 'Sales Funnel',
                open: false,
                link: '/controlling/sales-funnel',
                translation: 'salesfunnel.general.title'
            },
            {
                title: 'Contract Database',
                open: false,
                link: '/contract',
                translation: 'offers.general.title'
            }
        ]
    },
    {
        title: 'Projects',
        open: false,
        icon: 'list-view',
        translation: 'projects.general.title-projects',
        children: [
            {
                title: 'Projects',
                open: false,
                link: '/projects',
                translation: 'projects.general.title-projects'
            },
            {
                title: 'Programs',
                open: false,
                link: '/projectgroup',
                translation: 'projects.project-properties.project-group'
            },
            {
                title: 'Accounting',
                open: false,
                link: '',
                translation: 'projects.general.title-accounting'
            },
            {
                title: 'External costs keying',
                open: false,
                link: '/projects/extern',
                translation: 'projects.project-external-costs.title'
            },
            {
                title: 'NRS View',
                open: false,
                link: '/projects/nrs',
                translation: 'projects.project-nrs-view.title'
            }
        ]
    },
    {
        title: 'Time management',
        open: false,
        icon: 'apps',
        translation: 'time-management.general.title',
        children: [
            {
                title: 'Time sheets',
                open: false,
                link: '/timesheet',
                translation: 'time-management.general.time-sheets-title'
            },
            {
                title: 'Capacity',
                open: false,
                link: '/capacities',
                translation: 'time-management.general.capacity-planning-title'
            },
            {
                title: 'Absenses',
                open: false,
                link: '/absences',
                translation: 'time-management.general.absences-title'
            },
            {
                title: 'Workload',
                open: false,
                link: '/workload',
                translation: 'time-management.general.workload-title'
            },
            {
                title: 'Favorites',
                open: false,
                link: '/projects/favorites',
                translation: 'projects.project-favorite.title'
            }
        ]
    },
    {
        title: 'Reporting',
        open: false,
        icon: 'forum',
        translation: 'reporting.general.title',
        children: [
            {
                title: 'Quality',
                open: false,
                link: '/controlling/quality-projects',
                translation: 'reporting.general.data-quality-title'
            },
            {
                title: 'Skill-Matrix',
                open: false,
                link: 'https://pulse.siemens.cloud/project/gmsreporting/dash/2506',
                translation: 'reporting.general.title'
            },
            {
                title: 'GMS Reporting Demo',
                open: false,
                link: '/gmsreporting',
                translation: 'reporting.general.gms-reporting-title'
            },
            {
                title: 'Reports',
                open: false,
                link: '/reports',
                translation: 'reporting.general.reports-title'
            },
            {
                title: 'GMS Controlling',
                open: false,
                link: '/controlling',
                translation: 'projects.project-detail.controlling.cost-controlling-title'
            },
            {
                title: 'Quality dashboard',
                open: false,
                link: '/controlling/quality-projects',
                translation: 'reporting.general.data-quality-title'
            }
        ]
    },
    {
        title: 'Employee',
        open: false,
        icon: 'forum',
        translation: 'employee.general.employee-title',
        children: [
            {
                title: 'Employees',
                open: false,
                link: '/employees',
                translation: 'employee.general.employees-title'
            }
            // {
            //   title: 'One Pager Profile (soon)',
            //   open: false,
            //   translation: 'employee.general.profile-title',
            // },
        ]
    },
    {
        title: 'FAQ',
        translation: 'shared.miscellaneous.faq',
        open: false,
        link: 'https://confluence.gms.siemens.cloud/display/CER/CERES+Knowledge+Base'
    },
    {
        title: 'Ticket tool',
        translation: 'shared.miscellaneous.ticket-tool',
        open: false,
        link: 'https://jira.gms.siemens.cloud/servicedesk/customer/portal/13/create/249'
    },
    {
        title: 'Services',
        translation: 'services.general.title',
        open: false,
        icon: 'newton_product-catalogue',
        children: [
            {
                title: 'Services',
                open: false,
                link: '/services',
                translation: 'services.general.title'
            }
        ]
    },
    {
        title: 'Admin',
        open: false,
        icon: 'service',
        translation: 'admin.general.admin-title',
        children: [
            {
                title: 'Admin',
                open: false,
                link: '/admin',
                translation: 'admin.general.admin-title'
            },
            {
                title: 'News (soon)',
                open: false,
                translation: 'admin.general.news-title'
            }
        ]
    }
];
