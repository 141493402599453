import { Day as CeresDay, days, daysEN, Holiday } from '@ceres/domain';
import { TranslocoService } from '@ngneat/transloco';
import { format } from 'date-fns';

export class Day implements CeresDay {
    totalSum: number;
    filteredSum: number;
    value: number;
    day: number;
    month: number;
    year: number;
    date: Date;
    weekNo: number;
    isHoliday: boolean;
    holiday: Holiday;
    placeholder: string;
    sum: any;

    constructor(public translate: TranslocoService) {}

    toFormatString(): string {
        return format(this.date, 'dd.MM.yyyy');
    }

    abbr?(): string {
        return this.translate.getActiveLang() === 'en' ? daysEN[this.value] : days[this.value];
    }
}
