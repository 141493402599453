@if (!multiple) {
    <mat-form-field class="mat-custom w-100" [ngClass]="{ 'input-invalid': condition === true }" appearance="fill">
        <mat-label>{{ placeholder }}</mat-label>
        <input matInput [required]="required" [value]="getValue()" readonly />
        @if (isEmployeeSet()) {
            <mat-icon (click)="openEmployeeSelection()" class="mat-custom icon-pointer" matSuffix>{{
                isEdit ? 'edit' : 'person_add'
            }}</mat-icon>
        } @else {
            @if (!readonly) {
                <mat-icon (click)="removeSelected(null)" class="mat-custom icon-pointer" matSuffix>delete</mat-icon>
            }
        }
    </mat-form-field>
} @else {
    @if (!readonly) {
        <mat-form-field class="pwo-no-underline">
            <mat-chip-grid #chipList>
                @for (employee of value; track employee) {
                    <mat-chip
                        (removed)="removeSelected(employee)"
                        [matTooltip]="activity ? getAssignedActivities(employee) : ''"
                        matTooltipClass="pwo-tooltip"
                    >
                        {{ employee?.name ? employee?.name : employee?.title ? employee?.title : employee?.scdName }}
                        @if (dpt && employee.businessArea?.title) {
                            &nbsp;-&nbsp;{{ employee?.businessArea?.title }}
                        }
                        @if (!readonly) {
                            <mat-icon matChipRemove>cancel</mat-icon>
                        }
                    </mat-chip>
                }
                @if (buttonText) {
                    @if (!readonly) {
                        <ceres-button [disabled]="condition" (click)="openEmployeeSelection(true)" icon="plus">{{
                            buttonText
                        }}</ceres-button>
                    }
                } @else {
                    @if (!readonly) {
                        <mat-chip (click)="openEmployeeSelection(true)" [ngClass]="{ invalid: condition === true }">
                            <i class="a-icon a-icon--plus"></i>
                        </mat-chip>
                    }
                }
                <input
                    [disabled]="readonly"
                    [matChipInputFor]="chipList"
                    placeholder="{{ placeholder }}"
                    readonly
                    hidden
                />
            </mat-chip-grid>
        </mat-form-field>
    }
    @if (readonly) {
        <mat-chip-set>
            @for (employee of value; track employee) {
                <mat-chip
                    (removed)="removeSelected(employee)"
                    [matTooltip]="activity ? getAssignedActivities(employee) : ''"
                    matTooltipClass="pwo-tooltip"
                >
                    {{ employee?.name ? employee?.name : employee?.title ? employee?.title : employee?.scdName }}
                    @if (dpt && employee.businessArea?.title) {
                        &nbsp;-&nbsp;{{ employee?.businessArea?.title }}
                    }
                    @if (!readonly) {
                        <mat-icon matChipRemove>cancel</mat-icon>
                    }
                </mat-chip>
            }
            @if (buttonText) {
                @if (!readonly) {
                    <ceres-button [disabled]="condition" (click)="openEmployeeSelection(true)" icon="plus">{{
                        buttonText
                    }}</ceres-button>
                }
            } @else {
                @if (!readonly) {
                    <mat-chip (click)="openEmployeeSelection(true)" [ngClass]="{ invalid: condition === true }">
                        <i class="a-icon a-icon--plus"></i>
                    </mat-chip>
                }
            }
        </mat-chip-set>
    }
}
