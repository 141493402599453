import {
    Employee as CeresEmployee,
    EmployeeContractType,
    Location,
    Portfolio,
    Role,
    Capacity,
    Workload,
    Activity,
    Favorite,
    ProgramFavorite,
    BusinessAreaDto,
    PspElement
} from '@ceres/domain';
import { EmployeeSkill } from './skill';
import { User } from './user';

export class Employee implements CeresEmployee {
    id!: number;
    email!: string; // TODO remove after user implementation
    gid!: string; // TODO remove after user implementation
    department!: string; // TODO remove after user implementation
    country!: string; // TODO remove after user implementation
    corporateName!: string; // TODO remove after user implementation
    are!: string;
    businessArea!: BusinessAreaDto;
    allBusinessAreas!: BusinessAreaDto[];
    FK!: boolean;
    KFM!: boolean;
    contractType!: EmployeeContractType;
    location!: Location;
    role?: Role; // TODO remove after user implementation
    isActive!: boolean; // TODO remove after user implementation
    portfolio!: Portfolio;
    sollstunden!: number;
    urlaubstage!: number;
    name!: string; // TODO remove after user implementation
    nameShort!: string; // TODO remove after user implementation
    assignedEmployees!: CeresEmployee[]; // TODO remove after user implementation
    supervisors!: CeresEmployee[]; // TODO remove after user implementation
    capacity!: Capacity[];
    workload!: number | Workload[];
    defaultActivity!: Activity;
    activities: Activity[] = [];
    previouslyAssignedActivities!: Activity[];
    assignedActivities!: Activity[];
    favorite!: Favorite;
    programFavorite!: ProgramFavorite;
    lastUpdateBySCD!: Date;
    assignedUsers!: User[]; // TODO eventuell wird assignedUsers nicht benötigt da wir von der employees seite daten laden und nicht von user -> employee
    supervisorUsers!: User[];
    user?: User;
    skills!: EmployeeSkill[];
    internalPspElement?: PspElement;

    constructor(object?: Partial<Employee>) {
        if (object) {
            Object.assign(this, object);
        }
    }
}

/* class Employee_v2 implements IEmployee {
  id: number;
  are: string;
  businessArea: BusinessArea;
  contractType: EmployeeContractType;
  location?: Location;
  portfolio?: Portfolio;
  targetHours?: number;
  holidays?: number;
  assignedEmployees: User[];
  supervisors: User[];
  defaultActivity?: Activity;
  activities: Activity[];
  projectFavorites: Favorite[];
  programFavorites: ProgramFavorite[];
  workload: Workload[];
  capacity: Capacity[];
  lastUpdateBySCD: Date;
}*/
