import { DateFilter, Filter, FilterService, MultiValueFilter } from '@ceres/filter';
import { ListItem } from '@ceres/frontend-helper';
import { FormControl } from '@angular/forms';
import { DateService } from '../../services/date.service';

export function saveProjectsFilter(filterService: FilterService) {
    const startDate = filterService.globalFilters.find(({ key }) => key === 'startDate') as DateFilter;
    const endDate = filterService.globalFilters.find(({ key }) => key === 'endDate') as DateFilter;
    const newFilterOptions = [
        {
            filterName: 'startDate',
            filterValue: startDate?.start
        },
        {
            filterName: 'endDate',
            filterValue: endDate?.end
        },
        {
            filterName: 'customer-type',
            filterValue: (filterService.getColumnFilters('kundenart') as MultiValueFilter).selected
        },
        {
            filterName: 'business-partner',
            filterValue: (filterService.getColumnFilters('businessPartner.fullName') as MultiValueFilter).selected
        },
        {
            filterName: 'customer-department',
            filterValue: (filterService.getColumnFilters('abteilungKunde') as MultiValueFilter).selected
        },
        {
            filterName: 'project-lead',
            filterValue: (filterService.getColumnFilters('projectLead.name') as MultiValueFilter).selected
        },
        {
            filterName: 'project-grouping',
            filterValue: (filterService.getColumnFilters('projektGruppierung') as MultiValueFilter).selected
        },
        {
            filterName: 'project-status',
            filterValue: (filterService.getColumnFilters('projectStatus') as MultiValueFilter).selected
        },
        {
            filterName: 'contract-type',
            filterValue: (filterService.getColumnFilters('verrechnungsart') as MultiValueFilter).selected
        },
        {
            filterName: 'accounting-cycle',
            filterValue: (filterService.getColumnFilters('verrechnungszyklus') as MultiValueFilter).selected
        },
        {
            filterName: 'beneficial',
            filterValue: (filterService.getColumnFilters('leistungsempfaenger.title') as MultiValueFilter).selected
        },
        {
            filterName: 'program',
            filterValue: (filterService.getColumnFilters('program.title') as MultiValueFilter).selected
        },
        {
            filterName: 'maturityLevel',
            filterValue: (filterService.getColumnFilters('maturityLevel.translationKey') as MultiValueFilter).selected
        },
        {
            filterName: 'costCenterInternal',
            filterValue: (filterService.getColumnFilters('costCenterInternal') as MultiValueFilter).selected
        },
        {
            filterName: 'accountingCountry',
            filterValue: (filterService.getColumnFilters('verrechnungsland') as MultiValueFilter).selected
        },
        {
            filterName: 'customerPspElement',
            filterValue: (filterService.getColumnFilters('customerPspElement.title') as MultiValueFilter).selected
        },
        {
            filterName: 'updatedByEmail',
            filterValue: (filterService.getColumnFilters('updatedByEmail') as MultiValueFilter).selected
        },
        {
            filterName: 'offerNumber',
            filterValue: (filterService.getColumnFilters('angebotsnummer') as MultiValueFilter).selected
        },
        {
            filterName: 'filter-object',
            filterValue: buildFilterObject(filterService, startDate, endDate)
        }
    ];
    return newFilterOptions;
}

export function saveOffersFilter(filterService: FilterService) {
    const startDate = filterService.globalFilters.find(({ key }) => key === 'startdate') as DateFilter;
    const endDate = filterService.globalFilters.find(({ key }) => key === 'expiryDate') as DateFilter;
    const newFilterOptions = [
        {
            filterName: 'startDate',
            filterValue: startDate?.start
        },
        {
            filterName: 'endDate',
            filterValue: endDate?.end
        },
        {
            filterName: 'contract-status',
            filterValue: (filterService.getColumnFilters('contractStatus.translationKey') as MultiValueFilter).selected
        },
        {
            filterName: 'contract-type',
            filterValue: (filterService.getColumnFilters('contractType.translationKey') as MultiValueFilter).selected
        },
        {
            filterName: 'business-area',
            filterValue: (filterService.getColumnFilters('businessArea.title') as MultiValueFilter).selected
        },
        {
            filterName: 'customer-name',
            filterValue: (filterService.getColumnFilters('customer.fullName') as MultiValueFilter).selected
        },
        {
            filterName: 'customer-department',
            filterValue: (filterService.getColumnFilters('customer.department') as MultiValueFilter).selected
        },
        {
            filterName: 'mp-number',
            filterValue: (filterService.getColumnFilters('mpNumber') as MultiValueFilter).selected
        },
        {
            filterName: 'isa-included',
            filterValue: (filterService.getColumnFilters('isaIncluded') as MultiValueFilter).selected
        },
        {
            filterName: 'service-provider-name',
            filterValue: (filterService.getColumnFilters('serviceProvider.name') as MultiValueFilter).selected
        },
        {
            filterName: 'service-provider-country',
            filterValue: (filterService.getColumnFilters('serviceProvider.country') as MultiValueFilter).selected
        },
        {
            filterName: 'updatedByEmail',
            filterValue: (filterService.getColumnFilters('updatedByEmail') as MultiValueFilter).selected
        },
        {
            filterName: 'filter-object',
            filterValue: buildFilterObject(filterService, startDate, endDate)
        }
    ];
    return newFilterOptions;
}

export function saveServicesFilter(filterService: FilterService) {
    const startDate = filterService.globalFilters.find(({ key }) => key === 'startDate') as DateFilter;
    const endDate = filterService.globalFilters.find(({ key }) => key === 'endDate') as DateFilter;
    const newFilterOptions = [
        {
            filterName: 'startDate',
            filterValue: startDate?.start
        },
        {
            filterName: 'endDate',
            filterValue: endDate?.end
        },
        {
            filterName: 'service-number',
            filterValue: (filterService.getColumnFilters('serviceNumber') as MultiValueFilter).selected
        },
        {
            filterName: 'country',
            filterValue: (filterService.getColumnFilters('country') as MultiValueFilter).selected
        },
        {
            filterName: 'department',
            filterValue: (filterService.getColumnFilters('department') as MultiValueFilter).selected
        },
        {
            filterName: 'internal-customer-name',
            filterValue: (filterService.getColumnFilters('internalCustomerName') as MultiValueFilter).selected
        },
        {
            filterName: 'are',
            filterValue: (filterService.getColumnFilters('are') as MultiValueFilter).selected
        },
        {
            filterName: 'dc',
            filterValue: (filterService.getColumnFilters('dc') as MultiValueFilter).selected
        },
        {
            filterName: 'cc',
            filterValue: (filterService.getColumnFilters('cc') as MultiValueFilter).selected
        },
        {
            filterName: 'profit-center',
            filterValue: (filterService.getColumnFilters('profitCenter') as MultiValueFilter).selected
        },
        {
            filterName: 'crm-kam',
            filterValue: (filterService.getColumnFilters('crmKam') as MultiValueFilter).selected
        },
        {
            filterName: 'gbs-service-id',
            filterValue: (filterService.getColumnFilters('gbsServiceId') as MultiValueFilter).selected
        },
        {
            filterName: 'local-mn',
            filterValue: (filterService.getColumnFilters('localMN') as MultiValueFilter).selected
        },
        {
            filterName: 'service-line-item',
            filterValue: (filterService.getColumnFilters('serviceLineItem') as MultiValueFilter).selected
        },
        {
            filterName: 'name',
            filterValue: (filterService.getColumnFilters('name') as MultiValueFilter).selected
        },
        {
            filterName: 'local-currency',
            filterValue: (filterService.getColumnFilters('localCurrency') as MultiValueFilter).selected
        },
        {
            filterName: 'pricing-method',
            filterValue: (filterService.getColumnFilters('pricingMethod') as MultiValueFilter).selected
        },
        {
            filterName: 'forecast-method',
            filterValue: (filterService.getColumnFilters('forecastMethod') as MultiValueFilter).selected
        },
        {
            filterName: 'team',
            filterValue: (filterService.getColumnFilters('team') as MultiValueFilter).selected
        },
        {
            filterName: 'portfolio',
            filterValue: (filterService.getColumnFilters('portfolio') as MultiValueFilter).selected
        },
        {
            filterName: 'origin-dc',
            filterValue: (filterService.getColumnFilters('originDC') as MultiValueFilter).selected
        },
        {
            filterName: 'org-id',
            filterValue: (filterService.getColumnFilters('orgId') as MultiValueFilter).selected
        },
        {
            filterName: 'billing-platform',
            filterValue: (filterService.getColumnFilters('billingPlatform') as MultiValueFilter).selected
        },
        {
            filterName: 'to-be-charged',
            filterValue: (filterService.getColumnFilters('toBeCharged') as MultiValueFilter).selected
        },
        {
            filterName: 'po',
            filterValue: (filterService.getColumnFilters('po') as MultiValueFilter).selected
        },
        {
            filterName: 'po-position',
            filterValue: (filterService.getColumnFilters('poPosition') as MultiValueFilter).selected
        },
        {
            filterName: 'mvc',
            filterValue: (filterService.getColumnFilters('mvc') as MultiValueFilter).selected
        },
        {
            filterName: 'filter-object',
            filterValue: buildFilterObject(filterService, startDate, endDate)
        }
    ];
    return newFilterOptions;
}

export function saveBOPFilter(filterService: FilterService) {
    const newFilterOptions = [
        {
            filterName: 'bop-leader',
            filterValue: (filterService.getColumnFilters('bopLeader.name') as MultiValueFilter).selected
        },
        {
            filterName: 'bop-leader-country',
            filterValue: (filterService.getColumnFilters('bopLeader.country') as MultiValueFilter).selected
        },
        {
            filterName: 'business-area',
            filterValue: (filterService.getColumnFilters('businessArea.title') as MultiValueFilter).selected
        },
        {
            filterName: 'business-partner',
            filterValue: (filterService.getColumnFilters('businessPartner.fullName') as MultiValueFilter).selected
        },
        {
            filterName: 'bp-department',
            filterValue: (filterService.getColumnFilters('businessPartner.department') as MultiValueFilter).selected
        },
        {
            filterName: 'bp-country-code',
            filterValue: (filterService.getColumnFilters('businessPartner.countryCode') as MultiValueFilter).selected
        },
        {
            filterName: 'probability',
            filterValue: (filterService.getColumnFilters('probabilityClass.probability') as MultiValueFilter).selected
        },
        {
            filterName: 'bop-status',
            filterValue: (filterService.getColumnFilters('bopStatus.translationKey') as MultiValueFilter).selected
        },
        {
            filterName: 'updatedByEmail',
            filterValue: (filterService.getColumnFilters('updatedByEmail') as MultiValueFilter).selected
        },
        {
            filterName: 'filter-object',
            filterValue: buildFilterObject(filterService)
        }
    ];
    return newFilterOptions;
}

export function saveBPFilter(filterService: FilterService) {
    const newFilterOptions = [
        {
            filterName: 'gp-type',
            filterValue: (filterService.getColumnFilters('gpType.translationKey') as MultiValueFilter).selected
        },
        {
            filterName: 'function',
            filterValue: (filterService.getColumnFilters('function.translationKey') as MultiValueFilter).selected
        },
        {
            filterName: 'subject-area',
            filterValue: (filterService.getColumnFilters('subjectArea.translationKey') as MultiValueFilter).selected
        },
        {
            filterName: 'department',
            filterValue: (filterService.getColumnFilters('department') as MultiValueFilter).selected
        },
        {
            filterName: 'main-customer-contact',
            filterValue: (filterService.getColumnFilters('mainCustomerContact.name') as MultiValueFilter).selected
        },
        {
            filterName: 'main-customer-department',
            filterValue: (filterService.getColumnFilters('mainCustomerContact.department') as MultiValueFilter).selected
        },
        {
            filterName: 'status',
            filterValue: (filterService.getColumnFilters('status.translationKey') as MultiValueFilter).selected
        },
        {
            filterName: 'christmas-card',
            filterValue: (filterService.getColumnFilters('christmasCard') as MultiValueFilter).selected
        },
        {
            filterName: 'email',
            filterValue: (filterService.getColumnFilters('eMail') as MultiValueFilter).selected
        },
        {
            filterName: 'gid',
            filterValue: (filterService.getColumnFilters('gid') as MultiValueFilter).selected
        },
        {
            filterName: 'updatedByEmail',
            filterValue: (filterService.getColumnFilters('updatedByEmail') as MultiValueFilter).selected
        },
        {
            filterName: 'filter-object',
            filterValue: buildFilterObject(filterService)
        }
    ];
    return newFilterOptions;
}

export function saveEmployeesFilter(filterService: FilterService) {
    const newFilterOptions = [
        {
            filterName: 'employee',
            filterValue: (filterService.getColumnFilters('name') as MultiValueFilter).selected
        },
        {
            filterName: 'contract-type',
            filterValue: (filterService.getColumnFilters('contractType.translationKey') as MultiValueFilter).selected
        },
        {
            filterName: 'role',
            filterValue: (filterService.getColumnFilters('role.translationKey') as MultiValueFilter).selected
        },
        {
            filterName: 'business-area',
            filterValue: (filterService.getColumnFilters('allBusinessAreas.name') as MultiValueFilter).selected
        },
        {
            filterName: 'portfolio',
            filterValue: (filterService.getColumnFilters('portfolio.title') as MultiValueFilter).selected
        },
        {
            filterName: 'location',
            filterValue: (filterService.getColumnFilters('location.title') as MultiValueFilter).selected
        },
        {
            filterName: 'updatedByEmail',
            filterValue: (filterService.getColumnFilters('updatedByEmail') as MultiValueFilter).selected
        },
        {
            filterName: 'filter-object',
            filterValue: buildFilterObject(filterService)
        }
    ];
    return newFilterOptions;
}

export function buildFilterObject(filterService: FilterService, startDate?: DateFilter, endDate?: DateFilter) {
    const appliedFilters = [];

    if (startDate && endDate) {
        appliedFilters.push(startDate);
        appliedFilters.push(endDate);
    }

    filterService.columnFilters
        .filter(
            (variable) => (variable as MultiValueFilter).selected && (variable as MultiValueFilter)?.selected.length > 0
        )
        .forEach((variable) => {
            appliedFilters.push(variable);
        });

    return appliedFilters;
}

export function getNewFilterOptions(filterSection: string, filterService: FilterService) {
    switch (filterSection) {
        case 'projects':
            return saveProjectsFilter(filterService);
        case 'bop':
            return saveBOPFilter(filterService);
        case 'offers':
            return saveOffersFilter(filterService);
        case 'business-partner':
            return saveBPFilter(filterService);
        case 'employees':
            return saveEmployeesFilter(filterService);
        case 'services':
            return saveServicesFilter(filterService);
    }
}

export function resetColumnFilters(filterSection: string, filterService: FilterService, dateService: DateService) {
    filterService.columnFilters.forEach((filter) => {
        (filter as MultiValueFilter).selected = [];
        (filter as MultiValueFilter).isApplied = false;
    });
    if (filterSection === 'projects' || filterSection === 'offers' || filterSection === 'services') {
        filterService.globalFilters.forEach((filter) => {
            const now = new Date();
            (filter as DateFilter).start = dateService.getStartOfFiscalYear(now);
            (filter as DateFilter).end = dateService.getEndOfFiscalYear(now);
        });
    }
}

export function isColumnFilterApplied(displayedColumns: ListItem[], initialColumns: ListItem[]): boolean {
    displayedColumns = displayedColumns.filter((col) => col.selected);
    initialColumns = initialColumns.filter((col) => col.selected);
    if (displayedColumns.length !== initialColumns.length) {
        return false;
    }

    displayedColumns = displayedColumns.sort((a, b) => a.title.localeCompare(b.title));
    initialColumns = initialColumns.sort((a, b) => a.title.localeCompare(b.title));

    for (let i = 0; i < displayedColumns.length; i++) {
        if (
            displayedColumns[i].title !== initialColumns[i].title ||
            displayedColumns[i].selected !== initialColumns[i].selected
        ) {
            return false;
        }
    }

    // All elements match
    return true;
}

export function applyDateRangeFilterFormControl(
    filters: Filter[],
    startDateFilterKey: string,
    range: FormControl
): void {
    const startDateFilter: DateFilter = filters.find((filter) => filter.key === startDateFilterKey) as DateFilter;
    if (startDateFilter) {
        range.setValue(
            { start: startDateFilter.start, end: startDateFilter.end ? startDateFilter.end : null },
            { emitEvent: false, emitViewToModelChange: false }
        );
    }
}
