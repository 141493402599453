import { Pipe, PipeTransform } from '@angular/core';
import { SelectedDate } from '../interfaces/selected-date.interface';

const monthsDE = [
    'Januar',
    'Februar',
    'März',
    'April',
    'Mai',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'Dezember'
];
const monthsEN = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
];

@Pipe({
    name: 'displayMonth'
})
export class DisplayMonthPipe implements PipeTransform {
    transform(month: SelectedDate, language = 'de'): string {
        if (language === 'en') {
            return `${monthsEN[month.month]} ${month.year}`;
        } else {
            return `${monthsDE[month.month]} ${month.year}`;
        }
    }
}
