import { Workload } from './workload';
import { Employee } from './employee';
import { Program } from './program';

export interface ProgramFavorite {
    id: number;
    program: Program;
    employee: Employee;
    sortOrder: number;
    businessPartner: string;
    isFavorite: boolean;
    workload: Workload[];
}
