import { Injectable } from '@angular/core';
import { News, Employee } from '@ceres/domain';
import { ImpersonatedHttpClient } from '@ceres/shared/services';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class NewsService {
    constructor(private httpClient: ImpersonatedHttpClient) {}

    public async getAll(): Promise<News[]> {
        return await this.httpClient.get<News[]>(`${environment.edgeService}/news`).toPromise();
    }

    public async getByEmployee(employee: Employee) {
        if (employee) {
            // guest users are no employees
            return await this.httpClient
                .get<
                    News[]
                >(`${environment.edgeService}/news/business/area/${employee.businessArea.id}/portfolio/${employee.portfolio.id}`)
                .toPromise();
        }
    }

    public async create(news: News) {
        return await this.httpClient.post<News>(`${environment.edgeService}/news`, news).toPromise();
    }

    public async update(news: News) {
        return await this.httpClient.patch<News>(`${environment.edgeService}/news/${news.id}`, news).toPromise();
    }

    public async delete(news: News) {
        return await this.httpClient.delete<News>(`${environment.edgeService}/news/${news.id}`).toPromise();
    }
}
