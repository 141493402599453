import { Holiday } from './holiday';

export const days = ['SO', 'MO', 'DI', 'MI', 'DO', 'FR', 'SA'];
export const daysEN = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];

export interface Month {
    TitleDE: string;
    TitleEN: string;
    Date: Date;
    Value: string;
    Weeks: Week[];
    CalendarWeeks: Week[];
}

export interface Week {
    number: number;
    first: Day;
    last: Day;
    weekNo: number;
}

export interface Day {
    value: number;
    day: number;
    month: number;
    year: number;
    date: Date;
    weekNo: number;
    isHoliday: boolean;
    holiday: Holiday;
    placeholder: string;
}
