import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'orderPosNum'
})
export class OrderPosNumPipe implements PipeTransform {
    transform(value: string): string {
        if (value.length === 0 || value.length > 6) {
            return;
        }
        return value.padStart(6, '0');
    }
}
