import { NgModule } from '@angular/core';
import { MatSelectModule } from '@angular/material/select';
import { MatDialogModule } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';
import { ButtonModule } from '../button/button.module';
import { SelectModalComponent } from './select-modal.component';

@NgModule({
    declarations: [SelectModalComponent],
    imports: [CommonModule, MatSelectModule, MatDialogModule, TranslocoModule, ButtonModule],
    exports: [SelectModalComponent]
})
export class SelectModalModule {}
