import { BusinessPartner } from './business-partner';
import { Employee } from './employee';
import { Bop } from './bop';
import { BusinessArea } from './business-area';
import { TranslationOption } from './translation-option';

export interface SalesActivity {
    id: number;
    employee: Employee;
    businessPartner: BusinessPartner;
    date: Date;
    contactOptions: TranslationOption;
    resonance: TranslationOption;
    bopNumber: Bop[];
    notes: string;
    recurringDate: Date;
    businessPartnerId: number;
    title: string;
    businessAreas: BusinessArea[];
}

export interface SalesActivityFilterValues {
    'contactOptions.translationKey': string[];
    'businessPartner.fullName': string[];
    'bopNumber.bopNumber': string[];
    'businessAreas.title': string[];
    'employee.name': string[];
    'resonance.translationKey': string[];
    'businessPartner.department': string[];
}

export interface SalesActivityMetadata {
    contactOptions?: TranslationOption[];
    resonanceOptions?: TranslationOption[];
}
