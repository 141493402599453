import { ApiBusinessValues as CeresApiBusinessValues, Portfolio } from '@ceres/domain';

export class ApiBusinessValues implements CeresApiBusinessValues {
    Id: number;
    Portfolio: Portfolio;
    PortfolioId: number;
    Title: string;
    AccountingMonth: number;
    Category: string;
    Value: number;

    constructor(object?: Partial<ApiBusinessValues>) {
        Object.assign(this, object);
    }
}
