import { StatusReport } from './status-report';
import { ProjectProfile } from './project-profile';
import { Portfolio } from './portfolio';

export interface Program {
    id: number;
    title: string;
    statusReports: StatusReport[];
    projects: ProjectProfile[];
    interfaceificationGrade: string;
    classificationGrade: string;
    isFavorite: boolean;
    isRemovable: boolean;
    favoriteId: number;
    sortOrder: number;
    originalSortOrder: number;
    portfolio: Portfolio;
    originalPortfolio: Portfolio;
    active: boolean;
    rootFolderId: number;
}

export interface ProgramFilterValues {
    'portfolio.title': string[];
}
