import { Employee } from './employee';
import { TranslationOption } from './translation-option';

export interface UnconvertedBusinessPartner {
    id: number;
    gid: string;
    department?: string;
    salutation?: string;
    title?: string;
    firstName: string;
    lastName: string;
    personalRelation?: string;
    phone?: string;
    fax?: string;
    mobile?: string;
    email?: string;
    location?: string;
    street?: string;
    poBox?: string;
    zip?: string;
    city?: string;
    country?: string;
    countryCode?: string;
    remarks?: string;
    newsletterCandidate: boolean;
    advertisingBarrier: boolean;
    newsletterBarrier: boolean;
    christmasCard: boolean;
    deleteBookmarked: boolean;
    inFocus: boolean;
    companyNames: string[];
    are: string;
    mainCustomerContact?: Employee;
    customerContacts: Employee[];
    lastUpdateBySCD: Date;
    gpType: TranslationOption;
    subjectArea?: TranslationOption;
    function?: TranslationOption;
    budgetResponsibility?: TranslationOption;
    language?: TranslationOption;
    status?: TranslationOption;
}

export interface GuestBusinessPartner {
    id: number;
    gpType?: TranslationOption;
    gid: string;
    department: string;
    salutation: string;
    firstName: string;
    surName: string;
    fullName: string;
    subjectArea?: TranslationOption;
    function?: TranslationOption;
    budgetResponsibility?: TranslationOption;
    customerContactId?: Employee[] | { results: [number] };
    customerContact: Employee[];
    mainCustomerContact: Employee;
    //mainCustomerContactId: number;
    personalRelation?: string;
    phone: string;
    fax: string;
    mobile: string;
    eMail: string;
    location: string;
    street: string;
    zip: string;
    countryCode: string;
    language?: TranslationOption;
    status?: TranslationOption;
    newsletterCandidate: boolean;
    advertisingBarrier: boolean;
    newsletterBarrier: boolean;
    christmasCard: boolean;
    deleteBookmarked: boolean;
    inFocus: boolean;
    companyNames: { name: string }[];
    are?: string;
    costCenter: string;
    orgId: string;
    title: string;
    city: string;
    country: string;
    poBox?: string;
    lastUpdateBySCD: Date;
}

export interface BusinessPartner {
    id: number;
    gpType: TranslationOption;
    gid: string;
    department: string;
    salutation: string;
    firstName: string;
    surName: string;
    fullName: string;
    subjectArea: TranslationOption;
    function: TranslationOption;
    budgetResponsibility: TranslationOption;
    customerContactId: Employee[] | { results: [number] };
    customerContact: Employee[];
    mainCustomerContact: Employee;
    mainCustomerContactId: number;
    personalRelation: string;
    phone: string;
    fax: string;
    mobile: string;
    eMail: string;
    location: string;
    street: string;
    zip: string;
    countryCode: string;
    language: TranslationOption;
    status: TranslationOption;
    newsletterCandidate: boolean;
    advertisingBarrier: boolean;
    newsletterBarrier: boolean;
    christmasCard: boolean;
    deleteBookmarked: boolean;
    inFocus: boolean;
    companyNames: { name: string }[];
    are: string;
    costCenter: string;
    orgId: string;
    title: string;
    city: string;
    country: string;
    poBox: string;
    lastUpdateBySCD: Date;
}

export interface BusinessPartnerFilterValues {
    'gpType.translationKey': string[];
    'function.translationKey': string[];
    'subjectArea.translationKey': string[];
    'mainCustomerContact.name': string[];
    'status.translationKey': string[];
    department: string[];
    christmasCard: boolean[];
}

export interface BusinessPartnerMetadata {
    subjectAreas?: TranslationOption[];
    functions?: TranslationOption[];
    languageOptions?: TranslationOption[];
    statusOptions?: TranslationOption[];
    gpTypeOptions?: TranslationOption[];
    budgetOptions?: TranslationOption[];
}
