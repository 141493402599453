<div class="pwo-filter filter-header">
    <div class="input">
        <mat-form-field class="mat-custom w-100 mat-fix-padding" appearance="fill">
            <mat-label>{{ 'shared.miscellaneous.search-dots' | transloco }}</mat-label>
            <input [(ngModel)]="searchTerm" (ngModelChange)="search()" (keyup.enter)="selected && apply()" matInput />
        </mat-form-field>
    </div>
</div>

@if (filterValues) {
    <div class="pwo-filter filter-values">
        <mat-radio-group [(ngModel)]="selected">
            @for (f of filterValues; track f) {
                <mat-radio-button [value]="f">
                    {{
                        isTranslationKey && f
                            ? translationPrefix
                                ? (translationPrefix + f | transloco)
                                : (f | transloco)
                            : f
                    }}
                </mat-radio-button>
            }
        </mat-radio-group>
    </div>
}

<button (click)="apply()" class="a-button a-button--pwo full" type="button" mat-button>
    <mat-icon>check</mat-icon>
    {{ 'shared.buttons.apply' | transloco }}
</button>
@if (filter.isApplied) {
    <button (click)="clear()" class="a-button a-button--pwo full" type="button" mat-button>
        <mat-icon>delete</mat-icon>
        Clear
    </button>
}
