@if (isAppInitialProcessLoaded) {
    <div>
        <ceres-header id="header" class="lay-wrapper lay-wrapper--header"></ceres-header>
        <main>
            <!--ceres-breadcrumb></ceres-breadcrumb-->
            <router-outlet></router-outlet>
        </main>
        @if (!isIE && (appUserService.loggedInUser$ | async)) {
            <ceres-control-center></ceres-control-center>
        }
    </div>
} @else {
    <ceres-header-placeholder
        id="header-placeholder"
        class="lay-wrapper lay-wrapper--header"
    ></ceres-header-placeholder>
    <ceres-loading></ceres-loading>
}

<ceres-footer></ceres-footer>
