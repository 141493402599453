import { Pipe, PipeTransform } from '@angular/core';
import { Employee } from '@ceres/domain';

@Pipe({
    name: 'employeeIsActive'
})
export class EmployeeIsActivePipe implements PipeTransform {
    transform(employees: Employee[]): any {
        if (!employees || employees.length === 0) {
            return [];
        }
        return employees.filter((employee) => employee.isActive);
    }
}
