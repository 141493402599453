import {
    SalesActivity as CeresSalesActivity,
    BusinessArea,
    Bop,
    BusinessPartner,
    Employee,
    TranslationOption
} from '@ceres/domain';

export class SalesActivity implements CeresSalesActivity {
    id: number;
    employee: Employee;
    businessPartner: BusinessPartner;
    date: Date;
    contactOptions: TranslationOption;
    resonance: TranslationOption;
    bopNumber: Bop[];
    notes: string;
    recurringDate: Date;
    // userId: number;
    businessPartnerId: number;
    title: string;
    businessAreas: BusinessArea[];

    constructor(object?: Partial<SalesActivity>) {
        Object.assign(this, object);
    }
}
