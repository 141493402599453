<div
    class="control-center-container"
    [ngClass]="{
        'first-message': showMessageInstantly && toast && !showMessageList,
        collapsed: showMessageList,
        green: showMessageInstantly && toast && !showMessageList && toast?.type === 1,
        yellow: showMessageInstantly && toast && !showMessageList && toast?.type === 2
    }"
>
    @if (showMessageList) {
        <div class="messages-container" data-testid="message-list-container">
            @for (message of messageService.messages$ | async; track message; let i = $index) {
                <div>
                    @if (message.source) {
                        <h4>{{ message.source }}</h4>
                    }
                    <div class="message-container">
                        @if (noMessageArray(message)) {
                            <p>
                                <b>{{ message.title }}:</b>
                                <br />
                                {{ message.message | transloco: message.obj }}
                            </p>
                        } @else {
                            <p>
                                <b>{{ message.title + ': ' }}</b>
                                <br />
                                @for (msg of message.message; track msg) {
                                    <span>
                                        {{ msg | transloco: message.obj }}
                                    </span>
                                }
                            </p>
                        }
                        <i
                            (click)="messageService.deleteMessage(message)"
                            class="material-icons close"
                            data-testid="deleteMessage"
                        >
                            close
                        </i>
                    </div>
                </div>
            }
            @if (!(messageService.hasMessages$ | async)) {
                <div class="message-container">
                    <p>{{ 'control-center.general.no-messages' | transloco }}</p>
                </div>
            }
        </div>
        <div class="settings-container">
            <mat-checkbox [(ngModel)]="showMessageInstantly">{{
                'control-center.general.automatically' | transloco
            }}</mat-checkbox>
        </div>
    }

    @if (!showMessageList && toast) {
        @if (noMessageArray(toast)) {
            <div data-testid="toast">
                <p>
                    <b>
                        {{ toast.title + ': ' + (toast.message | transloco: toast.obj) }}
                    </b>
                </p>
            </div>
        } @else {
            <p>
                <b>
                    {{ toast.title + ': ' }}
                    @for (msg of toast.message; track msg) {
                        <span>
                            {{ msg | transloco: toast.obj }}
                        </span>
                    }
                </b>
            </p>
        }
    }

    @if (!showMessageList) {
        <i
            (click)="toggleMessageList()"
            data-testid="toggle-message-list-on"
            [ngClass]="{
                alert: !showMessageInstantly && toast && toast?.type === 0,
                good: !showMessageInstantly && toast && (toast?.type === 1 || toast?.type === 2),
                'pulse-inform':
                    !showMessageList &&
                    (messageService.messages$ | async).length > 0 &&
                    messagesType(messageService.messages$ | async) === 2,
                'pulse-info':
                    !showMessageList &&
                    (messageService.messages$ | async).length > 0 &&
                    messagesType(messageService.messages$ | async) === 1,
                'pulse-error':
                    !showMessageList &&
                    (messageService.messages$ | async).length > 0 &&
                    messagesType(messageService.messages$ | async) === 0,
                'first-message': showMessageInstantly && toast
            }"
            class="material-icons"
            >info
        </i>
    }
    @if (!showMessageList) {
        <div class="info--counter" data-testid="msg-counter">
            {{ (messageService.messages$ | async).length }}
        </div>
    }
    @if (showMessageList) {
        <i (click)="toggleMessageList()" data-testid="toggle-message-list-off" class="material-icons">cancel </i>
    }
</div>
