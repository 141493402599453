import {
    Attribute,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnDestroy,
    Optional,
    Self
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { ComponentType } from '@angular/cdk/portal';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

@Component({
    selector: 'ceres-value-picker',
    templateUrl: './value-picker.component.html',
    styleUrls: ['./value-picker.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ValuePickerComponent<T, U, V> implements OnDestroy, ControlValueAccessor {
    public onChange!: (value: T | null) => void;
    public onTouched!: () => void;

    @Input() value: T | null = null;
    @Input() placeholder: string | null = null;

    @Input() required: boolean | undefined;
    @Input() valueTransformer!: (value: T | null) => string | null;

    @Input() dialogComponent!: ComponentType<U>;
    @Input() dialogData!: V;

    public isDisabled = false;

    private readonly subscription = new Subscription();

    public get invalid(): boolean | null {
        const { invalid, dirty, touched } = this.control;
        return invalid && (dirty || touched);
    }

    constructor(
        private readonly cdr: ChangeDetectorRef,
        @Self() @Optional() public control: NgControl,
        @Attribute('required') required: boolean | undefined,
        private readonly dialog: MatDialog
    ) {
        this.control.valueAccessor = this;
        this.required = required;
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    public changeValue(newValue: T | null) {
        this.value = newValue;
        this.onChange(this.value);
        this.onTouched();
    }

    public writeValue(value: T) {
        this.value = value;

        this.cdr.markForCheck();
    }

    setDisabledState(isDisabled: boolean) {
        this.isDisabled = isDisabled;
        this.cdr.markForCheck();
    }

    public registerOnChange(fn: (value: T | null) => void) {
        this.onChange = fn;
    }

    public registerOnTouched(fn: () => void) {
        this.onTouched = fn;
    }

    public openSelection(event: MouseEvent) {
        event.stopPropagation();

        this.subscription.add(
            this.dialog
                .open<U, V, T>(this.dialogComponent, {
                    disableClose: true,
                    width: '1000px',
                    data: this.dialogData
                })
                .afterClosed()
                .subscribe((value: T | undefined) => {
                    if (value) {
                        this.changeValue(value);
                    }
                    this.onTouched();
                    this.cdr.markForCheck();
                })
        );
    }

    public removeSelected(event: MouseEvent) {
        event.stopPropagation();

        this.changeValue(null);
    }
}
