/* eslint-disable max-classes-per-file */
import { BehaviorSubject, Observable } from 'rxjs';
import { GenericConstructor } from '../types';

export function CommonLoading<T extends GenericConstructor>(Base: T = class {} as any) {
    class CommonLoadingService extends Base {
        private _loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
        public loading$: Observable<boolean> = this._loading.asObservable();

        constructor(...args: any[]) {
            super(...args);
        }

        protected set loading(loading: boolean) {
            this._loading.next(loading);
        }

        protected get loading() {
            return this._loading.getValue();
        }
    }

    return CommonLoadingService;
}
