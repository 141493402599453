<div class="row dialog-header mb-2" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <div class="col-11 left">
        <h4>{{ 'admin.edit-role.permissions.program.select' | transloco }}</h4>
    </div>
    <div class="col-1 right">
        <button (click)="dialogRef.close()">
            <i class="a-icon a-icon--close"></i>
        </button>
    </div>
</div>
<div class="row list-container">
    <div class="col-8">
        <ceres-search-field [dataSource]="dataSource"></ceres-search-field>
    </div>
    <div class="row mb-2 main-content">
        <div class="col-12">
            <div class="table-container">
                <mat-table [dataSource]="dataSource" matSort>
                    <ng-container matColumnDef="selected">
                        <mat-header-cell *matHeaderCellDef>
                            @if (multiple) {
                                @if (!fewSelected) {
                                    <mat-checkbox (change)="selectAll()" [(ngModel)]="allChecked"></mat-checkbox>
                                }
                                @if (fewSelected) {
                                    <mat-checkbox (change)="selectAll()" class="mat-checkbox-semichecked">
                                    </mat-checkbox>
                                }
                            }
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            <mat-checkbox
                                [(ngModel)]="element.selected"
                                (change)="changeSelected(element, $event)"
                            ></mat-checkbox>
                        </mat-cell>
                        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                    </ng-container>
                    <ng-container matColumnDef="title">
                        <mat-header-cell *matHeaderCellDef
                            ><span>{{ 'shared.miscellaneous.title' | transloco }}</span></mat-header-cell
                        >
                        <mat-cell *matCellDef="let element">
                            <input matInput [(ngModel)]="element.title" />
                        </mat-cell>
                        <mat-footer-cell *matFooterCellDef>
                            <div class="pwo-input">
                                <input matInput [(ngModel)]="draftItem.title" />
                            </div>
                        </mat-footer-cell>
                    </ng-container>
                    <ng-container matColumnDef="portfolio">
                        <mat-header-cell
                            filter
                            *matHeaderCellDef
                            [sort]="true"
                            [filter]="filterService.getColumnFilters('portfolio.title')"
                            name="{{ 'shared.properties.portfolio' | transloco }}"
                            [filterService]="filterService"
                            column="portfolio.title"
                        ></mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            {{ element.portfolio?.title }}
                        </mat-cell>
                        <mat-footer-cell *matFooterCellDef>
                            <mat-select
                                [(ngModel)]="draftItem.portfolio"
                                [compareWith]="compareLookups"
                                class="pwo-select pwo-select--auto margins"
                                placeholder="{{ 'shared.properties.portfolio' | transloco }}"
                            >
                                @for (item of portfolios; track item) {
                                    <mat-option [value]="item">{{ item.title }}</mat-option>
                                }
                            </mat-select>
                        </mat-footer-cell>
                    </ng-container>
                    <ng-container matColumnDef="save">
                        <mat-header-cell *matHeaderCellDef>
                            <!-- <mat-icon>save</mat-icon> -->
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            <!-- <button (click)="updateItem(element)">
                <mat-icon>save</mat-icon>
              </button> -->
                        </mat-cell>
                        <mat-footer-cell *matFooterCellDef>
                            <button [disabled]="!draftItem.title" (click)="createItem()">
                                <i class="a-icon a-icon--plus"></i>
                            </button>
                        </mat-footer-cell>
                    </ng-container>
                    <!-- <ng-container matColumnDef="delete">
          <mat-header-cell *matHeaderCellDef>
            <i class="a-icon a-icon--delete"></i>
          </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <button (click)="deleteItem(element)">
              <i class="a-icon a-icon--delete"></i>
            </button>
          </mat-cell>
          <mat-footer-cell *matFooterCellDef> </mat-footer-cell>
        </ng-container> -->
                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
                    <mat-footer-row *matFooterRowDef="displayedColumns; sticky: true"></mat-footer-row>
                </mat-table>
                @if (busy) {
                    <ceres-loading [insideTable]="true"></ceres-loading>
                }
            </div>
            <mat-paginator #paginator [pageSizeOptions]="[15, 30, 50]" [pageSize]="15"></mat-paginator>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="d-flex gap-10">
                <ceres-button (click)="closeDialog(selectedPrograms)" icon="check-mark" type="button">
                    {{ 'shared.buttons.apply' | transloco }}
                </ceres-button>
                <ceres-button (click)="closeDialog(allChecked)" icon="close" type="button">
                    {{ 'shared.buttons.cancel' | transloco }}
                </ceres-button>
            </div>
        </div>
    </div>
</div>
