import { Component, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';

const round = (value: number) => Math.round((Number.EPSILON + value) * 100) / 100;

@Component({
    selector: 'ceres-ex-rate-based-input',
    templateUrl: './ex-rate-based-input.component.html',
    encapsulation: ViewEncapsulation.None
})
export class ExRateBasedInputComponent {
    @Input()
    value: number;

    @Output()
    valueChange = new EventEmitter<number>();

    @Input()
    rate: number;

    @Input()
    placeholder?: string;

    @Input()
    disabled? = false;

    get localValue() {
        return this.toLocalCurrency(this.value);
    }

    handleChange({ target: { value } }) {
        value = this.toDefaultCurrency(value);
        this.valueChange.emit(value);
    }

    private toDefaultCurrency(value: number) {
        if (value == null) {
            return value;
        }
        return +value / this.rate;
    }

    private toLocalCurrency(value: number): number {
        if (value == null) {
            return value;
        }
        return round(+value * this.rate);
    }
}
