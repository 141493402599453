import { Directive, Input, OnChanges, OnDestroy, TemplateRef, ViewContainerRef, SimpleChanges } from '@angular/core';
import { distinctUntilChanged, switchMap } from 'rxjs/operators';
import { of, Subject, Subscription } from 'rxjs';
import { FeatureTogglesService } from '../services/feature-toggles/feature-toggles.service';
import { FeatureToggleConfig, FeatureOf } from '../interfaces/feature-toggle-config.interface';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[ceresRequiredFeatures]'
})
export class RequiredFeaturesDirective<T extends FeatureToggleConfig> implements OnDestroy, OnChanges {
    private readonly currentFeature$$ = new Subject<FeatureOf<T, boolean>[]>();

    private subscription = new Subscription();

    private readonly true$ = of(true);

    @Input() ceresRequiredFeatures: FeatureOf<T, boolean>[] = [];

    private rendered = false;

    constructor(
        private readonly featureToggleService: FeatureTogglesService<T>,
        private readonly templateRef: TemplateRef<unknown>,
        private readonly vcRef: ViewContainerRef
    ) {
        this.subscription.add(
            this.currentFeature$$
                .pipe(
                    distinctUntilChanged(
                        (a, b) =>
                            JSON.stringify(a.map((x) => x.getKeys())) === JSON.stringify(b.map((x) => x.getKeys()))
                    ),
                    switchMap((features) => {
                        if (features.length) {
                            const [firstFeature, ...otherFeatures] = features;
                            return this.featureToggleService.hasFeatures(firstFeature, ...otherFeatures);
                        }

                        return this.true$;
                    })
                )
                .subscribe({
                    next: (hasFeatures) => {
                        if (this.rendered) {
                            this.vcRef.clear();
                            this.rendered = false;
                        }

                        if (hasFeatures && !this.rendered) {
                            this.vcRef.createEmbeddedView(this.templateRef);
                            this.rendered = true;
                        }
                    }
                })
        );
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.ceresRequiredFeatures?.currentValue) {
            this.currentFeature$$.next(changes.ceresRequiredFeatures.currentValue);
        }
    }

    public ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
