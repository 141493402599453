@switch (navigationModule) {
    @case ('projects') {
        <div class="d-flex flex-wrap inline-navbar">
            <div
                class="navbar-nav-item"
                [routerLinkActiveOptions]="{ exact: true }"
                routerLink="/projects"
                [routerLinkActive]="'active'"
            >
                {{ 'projects.general.title-projects' | transloco }}
            </div>
            @if ('projects' == currentRoute) {
                <div>
                    <ng-container [ngTemplateOutlet]="content"></ng-container>
                </div>
            }
            <ng-container *ceresRequiredFeatures="[features.projects.groups]">
                <div
                    class="navbar-nav-item"
                    [routerLinkActiveOptions]="{ exact: true }"
                    routerLink="/projectgroup"
                    [routerLinkActive]="'active'"
                >
                    {{ 'projects.project-properties.project-group' | transloco }}
                </div>
                @if ('projectgroup' == currentRoute) {
                    <div>
                        <ng-container [ngTemplateOutlet]="content"></ng-container>
                    </div>
                }
            </ng-container>
            <ng-container *ceresRequiredFeatures="[features.projects.charging]">
                <div
                    class="navbar-nav-item"
                    [routerLink]="!appUserService.isMerchant() ? '/accounting/overview' : '/accounting/merchant'"
                    [routerLinkActive]="'active'"
                >
                    {{
                        (!appUserService.isMerchant()
                            ? 'projects.general.title-accounting'
                            : 'projects.general.title-merchant'
                        ) | transloco
                    }}
                </div>
                @if ('accounting' == currentRoute) {
                    <div>
                        <ng-container [ngTemplateOutlet]="content"></ng-container>
                    </div>
                }
            </ng-container>
            <ng-container *ceresRequiredFeatures="[features.projects.nrsView]">
                @if (appUserService.hasPermission(permission.project.nrs)) {
                    <div
                        class="navbar-nav-item"
                        [routerLinkActiveOptions]="{ exact: true }"
                        routerLink="/projects/nrs"
                        [routerLinkActive]="'active'"
                    >
                        {{ 'projects.project-nrs-view.title' | transloco }}
                    </div>
                }
                @if ('nrs' == currentRoute) {
                    <div>
                        <ng-container [ngTemplateOutlet]="content"></ng-container>
                    </div>
                }
            </ng-container>
            <ng-container *ceresRequiredFeatures="[features.projects.preStaffing]">
                @if (appUserService.hasPermission(permission.project.preStaffing.readAll)) {
                    <div
                        class="navbar-nav-item"
                        [routerLinkActiveOptions]="{ exact: true }"
                        routerLink="/prestaffing"
                        [routerLinkActive]="'active'"
                    >
                        {{ 'projects.pre-staffing.title' | transloco }}
                    </div>
                    @if ('prestaffing' == currentRoute) {
                        <div>
                            <ng-container [ngTemplateOutlet]="content"></ng-container>
                        </div>
                    }
                }
            </ng-container>
        </div>
    }
    @case ('business-partner') {
        <div class="d-flex flex-wrap inline-navbar">
            @if (appUserService.hasPermission(permission.businesspartner.readAll)) {
                <div
                    class="navbar-nav-item"
                    [routerLinkActiveOptions]="{ exact: true }"
                    routerLink="/businesspartner"
                    [routerLinkActive]="'active'"
                >
                    {{ 'businesspartner.general.title' | transloco }}
                </div>
                @if ('business-partner' == currentRoute) {
                    <div>
                        <ng-container [ngTemplateOutlet]="content"></ng-container>
                    </div>
                }
            }
        </div>
    }
    @case ('employees') {
        <div class="d-flex flex-wrap inline-navbar">
            @if (appUserService.hasPermission(permission.employee.readAll)) {
                <div
                    class="navbar-nav-item"
                    [routerLinkActiveOptions]="{ exact: true }"
                    routerLink="/employees"
                    [routerLinkActive]="'active'"
                >
                    {{ 'employee.general.employees-title' | transloco }}
                </div>
                @if ('employees' == currentRoute) {
                    <div>
                        <ng-container [ngTemplateOutlet]="content"></ng-container>
                    </div>
                }
            }
            <ng-container *ceresRequiredFeatures="[features.employees.skills]">
                @if (appUserService.hasPermission(permission.employee.skill)) {
                    <div class="navbar-nav-item" routerLink="/employees/skills" [routerLinkActive]="'active'">
                        {{ 'employee.general.skills-title' | transloco }}
                    </div>
                }
                @if ('skills' == currentRoute) {
                    <div>
                        <ng-container [ngTemplateOutlet]="content"></ng-container>
                    </div>
                }
            </ng-container>
        </div>
    }
    @case ('services') {
        <div class="d-flex flex-wrap inline-navbar">
            <ng-container *ceresRequiredFeatures="[features.services.enabled]">
                @if (appUserService.hasPermission(permission.services.readAll)) {
                    <div
                        class="navbar-nav-item"
                        [routerLinkActiveOptions]="{ exact: true }"
                        routerLink="/services"
                        [routerLinkActive]="'active'"
                    >
                        {{ 'services.general.title' | transloco }}
                    </div>
                }
                @if ('services' == currentRoute) {
                    <div>
                        <ng-container [ngTemplateOutlet]="content"></ng-container>
                    </div>
                }
            </ng-container>
        </div>
    }
    @case ('sales') {
        <div class="d-flex flex-wrap inline-navbar">
            <ng-container *ceresRequiredFeatures="[features.sales.businessOpportunities]">
                @if (appUserService.hasPermission(permission.bop.readAll)) {
                    <div
                        class="navbar-nav-item"
                        [routerLinkActiveOptions]="{ exact: true }"
                        routerLink="/bop"
                        [routerLinkActive]="'active'"
                    >
                        {{ 'bop.general.bop-title' | transloco }}
                    </div>
                }
                @if ('bop' == currentRoute) {
                    <div>
                        <ng-container [ngTemplateOutlet]="content"></ng-container>
                    </div>
                }
            </ng-container>
            <ng-container *ceresRequiredFeatures="[features.sales.salesActivities]">
                @if (appUserService.hasPermission(permission.timesheet)) {
                    <div
                        class="navbar-nav-item"
                        [routerLinkActiveOptions]="{ exact: true }"
                        routerLink="/sales-activity"
                        [routerLinkActive]="'active'"
                    >
                        {{ 'sales-activity.general.title' | transloco }}
                    </div>
                }
                @if ('sales-activity' == currentRoute) {
                    <div>
                        <ng-container [ngTemplateOutlet]="content"></ng-container>
                    </div>
                }
            </ng-container>
            <ng-container *ceresRequiredFeatures="[features.sales.salesFunnel]">
                @if (appUserService.hasPermission(permission.controlling.salesFunnel)) {
                    <div
                        class="navbar-nav-item"
                        [routerLinkActiveOptions]="{ exact: true }"
                        routerLink="/controlling/sales-funnel"
                        [routerLinkActive]="'active'"
                    >
                        {{ 'salesfunnel.general.title' | transloco }}
                    </div>
                }
                @if ('sales-funnel' == currentRoute) {
                    <div>
                        <ng-container [ngTemplateOutlet]="content"></ng-container>
                    </div>
                }
            </ng-container>
            <ng-container *ceresRequiredFeatures="[features.sales.offers]">
                @if (appUserService.hasPermission(permission.contract.readAll)) {
                    <div
                        class="navbar-nav-item"
                        [routerLinkActiveOptions]="{ exact: true }"
                        routerLink="/contract"
                        [routerLinkActive]="'active'"
                    >
                        {{ 'offers.general.title' | transloco }}
                    </div>
                }
                @if ('contract' == currentRoute) {
                    <div>
                        <ng-container [ngTemplateOutlet]="content"></ng-container>
                    </div>
                }
            </ng-container>
        </div>
    }
    @case ('timesheets') {
        <div class="d-flex flex-wrap inline-navbar">
            <ng-container *ceresRequiredFeatures="[features.timeManagement.timeSheets]">
                @if (appUserService.hasPermission(permission.timesheet)) {
                    <div
                        class="navbar-nav-item"
                        [routerLinkActiveOptions]="{ exact: true }"
                        routerLink="/timesheet"
                        [routerLinkActive]="'active'"
                    >
                        {{ 'time-management.general.title' | transloco }}
                    </div>
                }
                @if ('working-hours' == currentRoute) {
                    <div>
                        <ng-container [ngTemplateOutlet]="content"></ng-container>
                    </div>
                }
            </ng-container>
            <ng-container *ceresRequiredFeatures="[features.timeManagement.capacity]">
                @if (appUserService.hasPermission(permission.capacities)) {
                    <div
                        class="navbar-nav-item"
                        [routerLinkActiveOptions]="{ exact: true }"
                        routerLink="/capacities"
                        [routerLinkActive]="'active'"
                    >
                        {{ 'time-management.general.capacity-planning-title' | transloco }}
                    </div>
                }
                @if ('capacity' == currentRoute) {
                    <div>
                        <ng-container [ngTemplateOutlet]="content"></ng-container>
                    </div>
                }
            </ng-container>
            <ng-container *ceresRequiredFeatures="[features.timeManagement.absences]">
                @if (appUserService.hasPermission(permission.absences)) {
                    <div
                        class="navbar-nav-item"
                        [routerLinkActiveOptions]="{ exact: true }"
                        routerLink="/absences"
                        [routerLinkActive]="'active'"
                    >
                        {{ 'time-management.general.absences-title' | transloco }}
                    </div>
                }
                @if ('absences' == currentRoute) {
                    <div>
                        <ng-container [ngTemplateOutlet]="content"></ng-container>
                    </div>
                }
            </ng-container>
            <ng-container *ceresRequiredFeatures="[features.timeManagement.workload]">
                @if (appUserService.hasPermission(permission.workload.readAll)) {
                    <div
                        class="navbar-nav-item"
                        [routerLinkActiveOptions]="{ exact: true }"
                        routerLink="/workload"
                        [routerLinkActive]="'active'"
                    >
                        {{ 'time-management.general.workload-title' | transloco }}
                    </div>
                }
                @if ('workload' == currentRoute) {
                    <div>
                        <ng-container [ngTemplateOutlet]="content"></ng-container>
                    </div>
                }
            </ng-container>
            <ng-container *ceresRequiredFeatures="[features.timeManagement.transactionalTimeTracking]">
                @if (appUserService.hasPermission(permission.timesheet)) {
                    <div
                        class="navbar-nav-item"
                        [routerLinkActiveOptions]="{ exact: true }"
                        routerLink="/transactional-hours"
                        [routerLinkActive]="'active'"
                    >
                        {{ 'time-management.general.transactional-hours-title' | transloco }}
                    </div>
                }
                @if ('transactional-hours' == currentRoute) {
                    <div>
                        <ng-container [ngTemplateOutlet]="content"></ng-container>
                    </div>
                }
            </ng-container>
        </div>
    }
    @case ('controlling') {
        <div class="d-flex flex-wrap inline-navbar">
            <ng-container *ceresRequiredFeatures="[features.reporting.dataQuality]">
                @if (appUserService.hasPermission(permission.controlling.quality)) {
                    <div
                        class="navbar-nav-item"
                        [routerLinkActiveOptions]="{ exact: true }"
                        routerLink="/controlling/quality-projects"
                        [routerLinkActive]="'active'"
                    >
                        {{ 'reporting.general.data-quality-title' | transloco }}
                    </div>
                }
                @if ('quality-projects' == currentRoute) {
                    <div>
                        <ng-container [ngTemplateOutlet]="content"></ng-container>
                    </div>
                }
            </ng-container>
            <ng-container *ceresRequiredFeatures="[features.reporting.pulseDashboard]">
                <div class="navbar-nav-item">
                    <a
                        href="https://pulse.siemens.cloud/project/gmsreporting/dash/2506"
                        target="_blank"
                        style="color: inherit; text-decoration: inherit"
                        >{{ 'reporting.general.pulse-title' | transloco }}</a
                    >
                </div>
            </ng-container>
            <ng-container *ceresRequiredFeatures="[features.reporting.costReport]">
                @if (appUserService.hasPermission(permission.costReport)) {
                    <div
                        class="navbar-nav-item"
                        [routerLinkActiveOptions]="{ exact: true }"
                        routerLink="/cost-report"
                        [routerLinkActive]="'active'"
                    >
                        {{ 'reporting.general.cost-report-title' | transloco }}
                    </div>
                }
                @if ('cost-report' == currentRoute) {
                    <div>
                        <ng-container [ngTemplateOutlet]="content"></ng-container>
                    </div>
                }
            </ng-container>
            <ng-container *ceresRequiredFeatures="[features.reporting.workingHoursReport]">
                @if (appUserService.hasPermission(permission.workingHoursReport)) {
                    <div
                        class="navbar-nav-item"
                        [routerLinkActiveOptions]="{ exact: true }"
                        routerLink="/working-hours-report"
                        [routerLinkActive]="'active'"
                    >
                        {{ 'time-management.general.working-hours-report' | transloco }}
                    </div>
                }
                @if ('working-hours-report' == currentRoute) {
                    <div>
                        <ng-container [ngTemplateOutlet]="content"></ng-container>
                    </div>
                }
            </ng-container>
            <ng-container *ceresRequiredFeatures="[features.reporting.gmsReportingDemo]">
                @if (appUserService.hasPermission(permission.reports)) {
                    <div
                        class="navbar-nav-item"
                        [routerLinkActiveOptions]="{ exact: true }"
                        routerLink="/gmsreporting"
                        [routerLinkActive]="'active'"
                    >
                        {{ 'reporting.general.gms-reporting-title' | transloco }}
                    </div>
                }
                @if ('gms' == currentRoute) {
                    <div>
                        <ng-container [ngTemplateOutlet]="content"></ng-container>
                    </div>
                }
            </ng-container>
            <ng-container *ceresRequiredFeatures="[features.reporting.productivityReport]">
                @if (appUserService.hasPermission(permission.productivityReport)) {
                    <div
                        class="navbar-nav-item"
                        [routerLinkActiveOptions]="{ exact: true }"
                        routerLink="/reports/productivity-report"
                        [routerLinkActive]="'active'"
                    >
                        {{ 'reporting.general.productivity-title' | transloco }}
                    </div>
                }
                @if ('productivity-report' == currentRoute) {
                    <div>
                        <ng-container [ngTemplateOutlet]="content"></ng-container>
                    </div>
                }
            </ng-container>
        </div>
    }
    @case ('controlling-todo2') {
        <div class="d-flex flex-wrap inline-navbar">
            <ng-container *ceresRequiredFeatures="[features.reporting.dataQuality]">
                @if (appUserService.hasPermission(permission.controlling.quality)) {
                    <div
                        class="navbar-nav-item"
                        [routerLinkActiveOptions]="{ exact: true }"
                        routerLink="/controlling/quality-bops"
                        [routerLinkActive]="'active'"
                    >
                        {{ 'reporting.general.data-quality-title' | transloco }}
                    </div>
                }
                @if ('quality-projects' == currentRoute) {
                    <div>
                        <ng-container [ngTemplateOutlet]="content"></ng-container>
                    </div>
                }
            </ng-container>
            <ng-container *ceresRequiredFeatures="[features.reporting.pulseDashboard]">
                <div class="navbar-nav-item">
                    <a
                        href="https://pulse.siemens.cloud/project/gmsreporting/dash/2506"
                        target="_blank"
                        style="color: inherit; text-decoration: inherit"
                        >{{ 'reporting.general.pulse-title' | transloco }}</a
                    >
                </div>
            </ng-container>
            <ng-container *ceresRequiredFeatures="[features.reporting.costReport]">
                @if (appUserService.hasPermission(permission.costReport)) {
                    <div
                        class="navbar-nav-item"
                        [routerLinkActiveOptions]="{ exact: true }"
                        routerLink="/cost-report"
                        [routerLinkActive]="'active'"
                    >
                        {{ 'reporting.general.cost-report-title' | transloco }}
                    </div>
                }
                @if ('cost-report' == currentRoute) {
                    <div>
                        <ng-container [ngTemplateOutlet]="content"></ng-container>
                    </div>
                }
            </ng-container>
            <ng-container *ceresRequiredFeatures="[features.reporting.workingHoursReport]">
                @if (appUserService.hasPermission(permission.workingHoursReport)) {
                    <div
                        class="navbar-nav-item"
                        [routerLinkActiveOptions]="{ exact: true }"
                        routerLink="/working-hours-report"
                        [routerLinkActive]="'active'"
                    >
                        {{ 'time-management.general.working-hours-report' | transloco }}
                    </div>
                }
                @if ('working-hours-report' == currentRoute) {
                    <div>
                        <ng-container [ngTemplateOutlet]="content"></ng-container>
                    </div>
                }
            </ng-container>
            <ng-container *ceresRequiredFeatures="[features.reporting.gmsReportingDemo]">
                @if (appUserService.hasPermission(permission.reports)) {
                    <div
                        class="navbar-nav-item"
                        [routerLinkActiveOptions]="{ exact: true }"
                        routerLink="/gmsreporting"
                        [routerLinkActive]="'active'"
                    >
                        {{ 'reporting.general.gms-reporting-title' | transloco }}
                    </div>
                }
                @if ('gms' == currentRoute) {
                    <div>
                        <ng-container [ngTemplateOutlet]="content"></ng-container>
                    </div>
                }
            </ng-container>
            <ng-container *ceresRequiredFeatures="[features.reporting.productivityReport]">
                @if (appUserService.hasPermission(permission.productivityReport)) {
                    <div
                        class="navbar-nav-item"
                        [routerLinkActiveOptions]="{ exact: true }"
                        routerLink="/reports/productivity-report"
                        [routerLinkActive]="'active'"
                    >
                        {{ 'reporting.general.productivity-title' | transloco }}
                    </div>
                }
                @if ('productivity-report' == currentRoute) {
                    <div>
                        <ng-container [ngTemplateOutlet]="content"></ng-container>
                    </div>
                }
            </ng-container>
        </div>
    }
    @default {
        <div>NO CONTENT FOUND</div>
    }
}

<ng-template #content>
    <ng-content></ng-content>
</ng-template>
