import { AllocationType } from '../enums/allocation-type.enum';

export class WorkingHoursReport {
    projectGrouping!: string;

    'employee.name'!: string;

    'businessArea.name'!: string;

    'portfolio.name'!: string;

    'activity.name'!: string;

    'activity.translationKey'!: string;

    mpNumber!: number;

    mpTitle!: string;

    contractNumber!: string;

    'businessPartner.department'!: string;

    'businessPartner.fullName'!: string;
}

export class WorkingHoursDailyReportEntity extends WorkingHoursReport {
    workingHours!: number;

    accountingMonth!: Date | string;

    comment?: string;
}

export class WorkingHoursReportEntity extends WorkingHoursReport {
    october!: number;

    november!: number;

    december!: number;

    january!: number;

    february!: number;

    march!: number;

    april!: number;

    may!: number;

    june!: number;

    july!: number;

    august!: number;

    september!: number;

    overall!: number;

    overallPrice!: number;
}

export class WorkingHoursByProjectReport {
    projectGrouping: string;

    costCenterInternal: string;

    allocationType: AllocationType;

    'program.title': string;

    'businessArea.name': string;

    mpNumber: number;

    mpTitle: string;

    contractNumber: string;

    'businessPartner.department': string;

    'businessPartner.fullName': string;

    'businessPartner.gpType': string;

    'portfolio.name': string;

    overall: number;

    projectPlannedHours?: number;

    [employeeName: string]: string | number;
}
