import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject } from 'rxjs';
import { AppSettings, DateRangeType } from '@ceres/domain';
import { ENVIRONMENT } from '@ceres/frontend-helper';
import { first, pluck } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class AppSettingsService {
    private readonly appSettings$$ = new ReplaySubject<AppSettings>(1);
    public readonly appSettings$: Observable<AppSettings> = this.appSettings$$.asObservable();

    public readonly dateRangeType$: Observable<DateRangeType>;

    public constructor(
        @Inject(ENVIRONMENT) private readonly environment: { edgeService: string },
        private readonly http: HttpClient
    ) {
        this.dateRangeType$ = this.appSettings$.pipe(pluck('dateRangeType'));
    }

    public loadAppSettings(): void {
        this.http
            .get<AppSettings>(`${this.environment.edgeService}/app-settings`)
            .pipe(first())
            .subscribe({
                next: (appSettings) => this.appSettings$$.next(appSettings)
            });
    }

    public updateSettings(changedSettings: Partial<AppSettings>): void {
        console.warn('updateSettings', changedSettings);
        this.http
            .patch<AppSettings>(`${this.environment.edgeService}/app-settings`, changedSettings)
            .pipe(first())
            .subscribe({
                next: (appSettings) => this.appSettings$$.next(appSettings)
            });
    }

    private updateAppSettings(settings: AppSettings): void {
        this.appSettings$$.next(settings);
    }
}
