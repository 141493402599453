import { Component, Input } from '@angular/core';
import { Upload } from '@ceres/domain';

@Component({
    selector: 'ceres-upload-progress',
    templateUrl: './upload-progress.component.html',
    styleUrls: ['./upload-progress.component.scss']
})
export class UploadProgressComponent {
    @Input() fileUploads: Upload[];
}
