import { Component, OnInit, Output, Input, EventEmitter, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FilterService, ValueFilter } from './../../../../lib';

@Component({
    selector: 'lib-single-select-filter',
    templateUrl: './single-select-filter.component.html',
    styleUrls: ['./single-select-filter.component.scss']
})
export class SingleSelectFilterComponent implements OnInit, OnDestroy {
    @Input() filter: ValueFilter;
    @Input() filterService: FilterService;
    @Input() isTranslationKey: boolean;
    @Input() translationPrefix: string;

    @Output() close = new EventEmitter();

    private readonly destroy$ = new Subject<void>();

    allFilterValues: string[] = [];
    filterValues: string[] = [];
    selected;

    searchTerm = '';

    ngOnInit() {
        this.init();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    init() {
        this.filterService
            .getFilterValues(this.filter)
            .pipe(takeUntil(this.destroy$))
            .subscribe((vals) => {
                this.allFilterValues = vals;
                this.search();
                this.selected = vals.find((el) => el === this.filter.value);
            });
    }

    search() {
        const s = this.searchTerm.toLowerCase();
        this.filterValues = this.allFilterValues.filter((el) => str(el).toLowerCase().includes(s));
        if (this.filterValues.length === 1) {
            this.selected = this.filterValues[0];
        }

        const str = (val: string) => {
            if (!val) {
                return '';
            }
            val = val + '';
            return val.length > 0 ? val : '(Empty)';
        };
    }

    apply() {
        this.filter.value = this.selected;
        this.filterService.applyFilter(this.filter);
        this.close.next(true);
    }

    clear() {
        this.selected = undefined;
        this.searchTerm = '';
        this.apply();
    }
}
