import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Inject,
    OnDestroy,
    ViewChild
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

export type Column = { key: string; title: string };

export type SelectFromTableDialogData<T> = {
    title: string;
    filterFunction: (searchTerm: string, dataEntry: T) => boolean;
    columns: Column[];
    data: Observable<T[]>;
};

@Component({
    selector: 'ceres-select-service-dialog',
    templateUrl: './select-from-table-dialog.component.html',
    styleUrls: ['./select-from-table-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectFromTableDialogComponent<T> implements AfterViewInit, OnDestroy {
    @ViewChild('searchInput') searchField!: ElementRef;

    public readonly title: string = '';
    public readonly filterControl = new FormControl(null);
    public dataSource = new MatTableDataSource<T>([]);
    public selectedValue: T | undefined = undefined;

    public readonly columns: Column[];

    public get columnKeys(): string[] {
        return this.columns.map((column) => column.key);
    }

    @ViewChild(MatSort)
    public sort!: MatSort;
    @ViewChild(MatPaginator)
    public paginator!: MatPaginator;

    private readonly subscription = new Subscription();

    constructor(
        @Inject(MAT_DIALOG_DATA) private data: SelectFromTableDialogData<T>,
        public readonly dialogRef: MatDialogRef<SelectFromTableDialogComponent<T>>,
        private readonly cdr: ChangeDetectorRef
    ) {
        this.subscription.add(
            combineLatest(data.data, this.filterControl.valueChanges)
                .pipe(
                    debounceTime(300),
                    map(([dataEntries, searchTerm]: [T[], string | null]) =>
                        searchTerm ? dataEntries.filter((entry) => data.filterFunction(searchTerm, entry)) : dataEntries
                    )
                )
                .subscribe((values: T[]) => {
                    this.dataSource.data = values;
                    this.cdr.markForCheck();
                })
        );
        this.title = data.title;
        this.columns = [{ key: 'selected', title: '' }, ...data.columns];

        this.filterControl.setValue(null); // trigger initial data load
    }

    ngAfterViewInit() {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;

        this.subscription.add(this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0)));

        setTimeout(() => {
            this.searchField.nativeElement.focus();
        }, 500);
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    public handleApply() {
        this.dialogRef.close(this.selectedValue);
    }

    public handleCancel() {
        this.dialogRef.close();
    }

    select(element: T, event: MatCheckboxChange) {
        this.selectedValue = event.checked ? element : undefined;
    }
}
