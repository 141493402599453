import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { FeatureToggleConfig } from '../interfaces/feature-toggle-config.interface';
import { FeatureTogglesService } from '../services/feature-toggles/feature-toggles.service';

@Injectable({
    providedIn: 'root'
})
export class FeatureToggleGuard<T extends FeatureToggleConfig> implements CanActivate {
    constructor(
        private readonly featureToggleService: FeatureTogglesService<T>,
        private readonly router: Router
    ) {}

    public canActivate(next: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
        const features = next.data.features;
        if (features && features.length) {
            const [firstFeature, ...otherFeatures] = features;
            return this.featureToggleService
                .hasFeatures(firstFeature, ...otherFeatures)
                .pipe(map((hasFeatures) => hasFeatures || this.router.parseUrl('/')));
        } else {
            return of(this.router.parseUrl('/'));
        }
    }
}
