import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'ceres-feature-header',
    templateUrl: './feature-header.component.html',
    styleUrls: ['./feature-header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FeatureHeaderComponent {
    @Input()
    public title = '';
}
