import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Program } from '@ceres/domain';
import { ProgramSelectionComponent } from './../program-selection/program-selection.component';

@Component({
    selector: 'ceres-program-picker',
    templateUrl: './program-picker.component.html',
    styleUrls: ['./program-picker.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ProgramPickerComponent implements OnInit {
    @Input() multiple: boolean;
    @Input() placeholder: string;
    @Input() value: any;
    @Input() readonly: boolean;
    @Input() required: boolean;
    // eslint-disable-next-line @typescript-eslint/ban-types
    @Input() condition: Function;

    @Output()
    selected = new EventEmitter<any>();

    @Output()
    unselected = new EventEmitter<any>();

    constructor(private dialog: MatDialog) {}

    ngOnInit() {
        if (this.value && this.multiple) {
            this.value = this.value
                .filter((e) => e)
                .filter((e, idx, arr) => {
                    if (idx === 0) {
                        return true;
                    }
                    return e.id !== arr[idx - 1].id;
                });
        }
    }

    openProgramSelection(multiple?: boolean) {
        const dialogRef: MatDialogRef<any> = this.dialog.open(ProgramSelectionComponent, {
            disableClose: true,
            width: '1000px',

            data: {
                name: 'Confirm',
                programs: this.value,
                multiple
            }
        });

        dialogRef.afterClosed().subscribe((selected) => {
            this.selected.emit(selected);
        });
    }

    removeSelected(program: Program) {
        if (program) {
            this.unselected.emit(program);
            const index = this.value.indexOf(program);
            if (index > -1) {
                this.value.splice(index, 1);
            }
        } else {
            this.unselected.emit(null);
        }
    }
}
