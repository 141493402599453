import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@ngneat/transloco';
import { MatIconModule } from '@angular/material/icon';
import { DateFilterComponent } from './components/filter-types/date-filter/date-filter.component';
import { MultiSelectFilterComponent } from './components/filter-types/multi-select-filter/multi-select-filter.component';
import { SingleSelectFilterComponent } from './components/filter-types/single-select-filter/single-select-filter.component';
import { RangeFilterComponent } from './components/filter-types/range-filter/range-filter.component';
import { SearchFilterComponent } from './components/filter-types/search-filter/search-filter.component';
import { FilterComponent } from './components/filter.component';
import { StandaloneFilterComponent } from './components/standalone-filter/standalone-filter.component';
import { HeaderCellFilterComponent } from './components/header-cell-filter.component';

@NgModule({
    imports: [
        CommonModule,
        MatMenuModule,
        MatCheckboxModule,
        MatRadioModule,
        FormsModule,
        MatButtonToggleModule,
        MatFormFieldModule,
        MatSelectModule,
        MatInputModule,
        ReactiveFormsModule,
        MatTableModule,
        MatSortModule,
        MatChipsModule,
        MatDialogModule,
        MatProgressSpinnerModule,
        MatTabsModule,
        MatDatepickerModule,
        MatTooltipModule,
        ScrollingModule,
        TranslocoModule,
        MatIconModule
    ],
    exports: [HeaderCellFilterComponent, FilterComponent, StandaloneFilterComponent, StandaloneFilterComponent],
    declarations: [
        HeaderCellFilterComponent,
        FilterComponent,
        DateFilterComponent,
        MultiSelectFilterComponent,
        SingleSelectFilterComponent,
        RangeFilterComponent,
        SearchFilterComponent,
        StandaloneFilterComponent
    ]
})
export class FilterModule {}
