import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'ceres-steps-visualizer',
    templateUrl: './steps-visualizer.component.html',
    styleUrls: ['./steps-visualizer.component.scss']
})
export class StepsVisualizerComponent implements OnInit {
    @Input() steps: string[];
    @Input() activeStep: number;

    ngOnInit() {
        console.log(this.activeStep);
    }
}
