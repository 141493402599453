export interface BarCellData {
    projectId: number;
    projectTitle: string;
    activityId: number;
    bgColor?: string;
    textColor?: string;
    data: {
        before: boolean;
        percent: number;
        after: boolean;
    } | null;
}
