import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'ceres-collapse-item',
    templateUrl: './collapse-item.component.html',
    styleUrls: ['./collapse-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CollapseItemComponent {
    @Input() show = false;

    @Output()
    readonly collapseToggle = new EventEmitter<boolean>();

    public toggle() {
        this.collapseToggle.next(this.show);
    }
}
