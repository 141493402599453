@if (data) {
    <div class="row dialog-header" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
        <div class="col-11 left">
            <h4>
                {{ data.header ? (data.header | transloco) : ('shared.dialogs.unsaved-changes-title' | transloco) }}
            </h4>
        </div>
        <div class="col-1 right">
            @if (!data.disableClose) {
                <button (click)="cancel()">
                    <i class="a-icon a-icon--close"></i>
                </button>
            }
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <p>{{ data.text | transloco }}</p>
        </div>
    </div>
    @if (!data.disableClose) {
        <div class="row">
            <div class="col-12">
                <div class="d-flex gap-10">
                    <ceres-button (click)="confirm()" icon="check-mark" type="button">{{
                        'shared.buttons.discard' | transloco
                    }}</ceres-button>
                    <ceres-button (click)="cancel()" icon="close" type="button">{{
                        'shared.buttons.cancel' | transloco
                    }}</ceres-button>
                </div>
            </div>
        </div>
    }
}
