import { AfterViewInit, Component, ContentChildren, ElementRef, OnDestroy, QueryList, ViewChild } from '@angular/core';
import { delay } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { ColumnElementDirective } from './column-element.directive';

@Component({
    selector: 'ceres-column',
    templateUrl: './column.component.html',
    styleUrls: ['./column.component.scss']
})
export class ColumnComponent implements AfterViewInit, OnDestroy {
    @ViewChild('left') leftParent!: ElementRef;
    @ViewChild('right') rightParent!: ElementRef;

    @ContentChildren(ColumnElementDirective)
    contentElements!: QueryList<ColumnElementDirective>;

    private readonly subscription = new Subscription();

    ngAfterViewInit() {
        this.subscription.add(this.contentElements.changes.pipe(delay(0)).subscribe(() => this.updateColumns()));

        this.updateColumns();
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    private updateColumns() {
        this.clear(this.leftParent);
        this.clear(this.rightParent);

        this.contentElements.forEach((item, index) => {
            if (index % 2 === 0) {
                this.leftParent.nativeElement.appendChild(item.self.nativeElement);
            } else {
                this.rightParent.nativeElement.appendChild(item.self.nativeElement);
            }
        });
    }

    private clear(elementRef: ElementRef) {
        const element = elementRef.nativeElement;
        let child = element.lastElementChild;
        while (child) {
            element.removeChild(child);
            child = element.lastElementChild;
        }
    }
}
