import { Injectable } from '@angular/core';
import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { AppUserService } from '../app-user.service';

@Injectable({
    providedIn: 'root'
})
export class ImpersonationInterceptor implements HttpInterceptor {
    constructor(private readonly auth: AppUserService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        const impersonatedUser = this.auth.impersonatedEmployee$.value;
        if (impersonatedUser) {
            req = req.clone({
                setHeaders: {
                    'x-impersonate': impersonatedUser.id + ''
                }
            });
        }
        return next.handle(req);
    }
}
