import { AuditTrailChangedProperty } from '../enums/audit-trail-changed-property.enum';
import { CapacityChangeStatus } from '../enums/capacity-change-status.enum';

export class CapacityAuditTrailDto {
    createdBy: string;
    createdAt: string;
    status: CapacityChangeStatus;
    changedData: CapacityAuditTrailChangedData[];
}

export class CapacityAuditTrailChangedData {
    changedProperty: AuditTrailChangedProperty;
    updatedData: string;
    previousData: string;
}

export class CapacityChangesData {
    id?: string;
    projectId: string;
    employeeId: string;
    from: string;
    to: string;
    hours: string;
}
