import { Inject, Injectable } from '@angular/core';
import {
    BusinessPartner,
    BusinessPartnerMetadata,
    GuestBusinessPartner,
    PaginationResponse,
    SCDResponse
} from '@ceres/domain';
import { Filter } from '@ceres/filter';
import { map } from 'rxjs/operators';
import { ImpersonatedHttpClient, EmployeeService } from '@ceres/shared/services';
import { ENVIRONMENT } from '@ceres/frontend-helper';
import { SCDUser } from '../models';
import { SCDService } from './scd.service';
import { ProjectTypeService } from './project-type.service';

@Injectable({
    providedIn: 'root'
})
export class NewBusinessPartnerService {
    constructor(
        private readonly http: ImpersonatedHttpClient,
        @Inject(ENVIRONMENT) private readonly environment: { edgeService: string },
        private scdService: SCDService,
        private employeeService: EmployeeService,
        private projectTypeService: ProjectTypeService
    ) {}

    public async getAll(): Promise<BusinessPartner[]> {
        const result = [];
        let allFetched = false;
        let page = 0;
        const maxSize = 6000;
        while (!allFetched) {
            const response = await this.http
                .get<PaginationResponse<BusinessPartner>>(`${this.environment.edgeService}/business/partners`, {
                    params: { page: page.toString(), size: maxSize.toString() }
                })
                .pipe(map(({ data }) => data))
                .toPromise();

            result.push(...response);
            page++;
            if (response.length < maxSize) {
                allFetched = true;
            }
        }
        return result;
    }

    public async getBy(query: { filters: Filter[] }): Promise<BusinessPartner[]> {
        const result = [];
        let allFetched = false;

        let page = 0;
        const maxSize = 6000;
        while (!allFetched) {
            const response = await this.http
                .get<PaginationResponse<BusinessPartner>>(`${this.environment.edgeService}/business/partners`, {
                    params: {
                        filters: JSON.stringify(query.filters),
                        page: page.toString(),
                        size: maxSize.toString()
                    }
                })
                .pipe(map(({ data }) => data))
                .toPromise();

            result.push(...response);
            page++;
            if (response.length < maxSize) {
                allFetched = true;
            }
        }
        return result;
    }

    public getPartnerById(id: number): Promise<BusinessPartner> {
        return this.http.get<BusinessPartner>(`${this.environment.edgeService}/business/partners/${id}`).toPromise();
    }

    public getPartnerByGid(gid: string): Promise<BusinessPartner> {
        return this.http
            .get<BusinessPartner>(`${this.environment.edgeService}/business/partners/byGid/${gid}`)
            .toPromise();
    }

    public async checkMail(mail: string): Promise<boolean> {
        try {
            await this.http
                .get<{
                    isCorrect: boolean;
                }>(`${this.environment.edgeService}/business/partners/byEmail/${encodeURIComponent(mail)}`)
                .toPromise();
            return true;
        } catch (error: any) {
            if (error.status === 404) {
                return false;
            }
            return Promise.reject(error);
        }
    }

    public create(partner: BusinessPartner): Promise<BusinessPartner> {
        partner.fullName = `${partner.surName}, ${partner.firstName}`;
        return this.http
            .post<BusinessPartner>(`${this.environment.edgeService}/business/partners`, partner)
            .toPromise();
    }

    async createGuestBusinessPartner(partner: Omit<GuestBusinessPartner, 'id'>): Promise<BusinessPartner> {
        partner.fullName = `${partner.surName}, ${partner.firstName}`;
        return this.http
            .post<BusinessPartner>(`${this.environment.edgeService}/business/partners`, partner)
            .toPromise();
    }

    public getMetadata(): Promise<BusinessPartnerMetadata> {
        return this.http
            .get<BusinessPartnerMetadata>(`${this.environment.edgeService}/business/partners/metadata`)
            .toPromise();
    }

    async createBusinessPartnerFromSCDUser(user: SCDUser): Promise<BusinessPartner> {
        const fullName = `${user.surName}, ${user.firstName}`;
        const locations = await this.scdService.getByLocation(user.location).catch((reason) => console.error(reason));

        const confEmployee = this.projectTypeService.types.find((type) => type.referenceEmployee);
        if (!confEmployee) {
            return Promise.reject();
        }
        const employee = await this.employeeService.getEmployeeById(confEmployee.referenceEmployee);

        const metadata = await this.getMetadata();
        if (!metadata) {
            return Promise.reject();
        }

        const partnerRequest: Omit<GuestBusinessPartner, 'id'> = {
            gpType: metadata.gpTypeOptions?.find(
                (option) => option.translationKey === 'businesspartner.options.gp-type.siemens'
            ),
            gid: user.gid,
            department: user.department,
            salutation: user.personalTitle,
            firstName: user.firstName,
            surName: user.surName,
            fullName,
            subjectArea: metadata.subjectAreas?.find(
                (area) => area.translationKey === 'businesspartner.options.subjects.others'
            ),
            function: metadata.functions?.find((func) => func.translationKey === 'functions.worker'),
            budgetResponsibility: metadata.budgetOptions?.find(
                (option) => option.translationKey === 'budgetOptions.unknown'
            ),
            customerContact: [],
            mainCustomerContact: employee,
            phone: user.phone,
            fax: user.fax,
            mobile: user.mobile,
            eMail: user.mail,
            location: user.location,
            street: locations[user.location][0].street,
            zip: locations[user.location][0].postalCode,
            countryCode: user.country,
            language: metadata.languageOptions?.find((language) => language.translationKey === 'general.de'),
            status: metadata.statusOptions?.find(
                (status) => status.translationKey === 'businesspartner.options.status.customer'
            ),
            newsletterCandidate: false,
            advertisingBarrier: false,
            newsletterBarrier: false,
            christmasCard: false,
            deleteBookmarked: false,
            inFocus: false,
            companyNames: [
                {
                    name: 'Siemens'
                }
            ],
            title: user.graduateTitle,
            city: locations[user.location][0].locality,
            country: user.country,
            lastUpdateBySCD: new Date(),
            costCenter: user.costLocation,
            orgId: user.orgId
        };

        return await this.createGuestBusinessPartner(partnerRequest);
    }

    public update(partner: BusinessPartner) {
        partner.fullName = `${partner.surName}, ${partner.firstName}`;
        return this.http
            .patch<BusinessPartner>(`${this.environment.edgeService}/business/partners/${partner.id}`, partner)
            .toPromise();
    }

    public delete(partner: BusinessPartner) {
        return this.http.delete(`${this.environment.edgeService}/business/partners/${partner.id}`).toPromise();
    }

    async getSCDUserById(gid: string): Promise<SCDResponse> {
        return await this.http
            .get<SCDResponse>(`${this.environment.edgeService}/scd-user-directory/${gid}`)
            .toPromise();
    }
}
