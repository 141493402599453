import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeatureHeaderModule } from '../feature-header/feature-header.module';
import { LayoutComponent } from './layout.component';

@NgModule({
    imports: [CommonModule, FeatureHeaderModule],
    declarations: [LayoutComponent],
    exports: [LayoutComponent]
})
export class LayoutModule {}
