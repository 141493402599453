<div
    ngfDrop
    selectable="1"
    multiple="1"
    [(files)]="dndFiles"
    [(validDrag)]="validDrag"
    (filesChange)="changedFile()"
    [accept]="allowedFileTypes"
    [class.drag]="validDrag"
    class="drop-zone"
>
    {{ 'shared.miscellaneous.drop' | transloco }}
</div>
@if (dndFiles?.length > 0) {
    <div class="col-6">
        <div class="options-form options-form--header">
            <div class="row mb-2">
                <div class="col-12">
                    <p>{{ 'shared.miscellaneous.new-documents' | transloco }}</p>
                </div>
            </div>
        </div>
        <div class="row mb-2">
            <div class="col-12">
                <mat-table [dataSource]="newDocDataSource">
                    <ng-container matColumnDef="select">
                        <mat-header-cell *matHeaderCellDef>
                            <ceres-button [style]="'red'" [disabled]="deleteNames.size < 1" (click)="deleteSelected()">
                                <span
                                    class="icon a-icon a-icon--delete"
                                    matTooltip="{{ 'shared.buttons.delete' | transloco }}"
                                    matTooltipClass="pwo-tooltip"
                                >
                                </span>
                            </ceres-button>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            <mat-checkbox
                                (change)="changeDeleteNameStatus(element.name)"
                                [value]="deleteNames.has(element.name)"
                            ></mat-checkbox>
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="name">
                        <mat-header-cell *matHeaderCellDef> </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            {{ element.name }}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="delete">
                        <mat-header-cell *matHeaderCellDef> </mat-header-cell>
                        <mat-cell *matCellDef="let element; let i = index">
                            <button type="button">
                                <i (click)="cancelNewDocument(i, 1)" class="icon a-icon a-icon--delete"></i>
                            </button>
                        </mat-cell>
                    </ng-container>
                    <mat-header-row *matHeaderRowDef="displayedColumnsDataNew"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumnsDataNew"></mat-row>
                </mat-table>
            </div>
        </div>
    </div>
}
@if (showWarningArchive) {
    <div class="warning col-12 mb-2">
        {{ 'control-center-others.file-exists' | transloco }}
    </div>
}
@if (showWarningDelete) {
    <div class="warning col-12 mb-2">
        {{ 'control-center-others.file-exists-delete' | transloco }}
    </div>
}
