import { Component } from '@angular/core';
import { isIE } from '../../app.component';

@Component({
    selector: 'ceres-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
    currentYear = new Date().getFullYear();
    isIE = isIE;
}
