<form [formGroup]="form" (ngSubmit)="submit()">
    <div class="row dialog-header" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
        <div class="col-11 left">
            <h4>{{ data.title }}</h4>
        </div>
        <div class="col-1 right">
            <button (click)="dialogRef.close()">
                <i class="a-icon a-icon--close"></i>
            </button>
        </div>
    </div>
    <div class="row">
        <div>
            <p>{{ data.subtitle || 'Name' }}</p>
            <mat-form-field>
                <input matInput formControlName="newViewName" />
            </mat-form-field>
            @if (!isFieldValid('newViewName')) {
                <div>{{ 'view.error-already-exists' | transloco }}</div>
            }
            @if (isEmpty) {
                <div>{{ 'view.error-name-empty' | transloco }}</div>
            }
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="d-flex gap-10">
                <ceres-button (click)="submit()" icon="check-mark" [disabled]="!form.valid" type="button">{{
                    'shared.buttons.apply' | transloco
                }}</ceres-button>
                <ceres-button (click)="dialogRef.close()" icon="close" type="button">{{
                    'shared.buttons.cancel' | transloco
                }}</ceres-button>
            </div>
        </div>
    </div>
</form>
