import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { ButtonModule } from '../button/button.module';
import { ConfirmModalComponent } from './confirm-modal.component';

@NgModule({
    declarations: [ConfirmModalComponent],
    imports: [CommonModule, MatDialogModule, ButtonModule],
    exports: [ConfirmModalComponent]
})
export class ConfirmModalModule {}
