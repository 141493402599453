/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { hasValue } from './filters';
import { FilterRecord, ValueRecord, MultiValueRecord, RangeRecord, DateRecord, SearchRecord } from './types';

// export interface SPFilter extends Filter {
//     toFilterString(): string;
// }

// class SPValueFilter extends ValueFilter implements SPFilter {
//     toFilterString() {
//         return `${this.key} eq '${this.value}'`;
//     }
// }

// class SPMultiValueFilter extends MultiValueFilter implements SPFilter {
//     toFilterString() {
//         const filters = this.selected.map(val => `${this.key} eq '${val}'`);
//         return filters.join(' or ');
//         // return [ `substringof(${this.key}, '${this.selected.join()}'` ]; // FIXME ???
//     }
// }

// class SPRangeFilter extends RangeFilter implements SPFilter {
//     toFilterString() {
//         const filters = [];
//         if (hasValue(this.min)) {
//             filters.push(`${this.key} ge '${this.min}'`);
//         }
//         if (hasValue(this.max)) {
//             filters.push(`${this.key} le '${this.max}'`);
//         }
//         return filters.join(' and ');
//     }
// }

// class SPDateFilter<T> extends DateFilter implements SPFilter {
//     toFilterString() {
//         const filters = [];
//         if (hasValue(this.start)) {
//             filters.push(`${this.key} ge datetime'${this.start.toISOString()}'`);
//         }
//         if (hasValue(this.end)) {
//             filters.push(`${this.key} le datetime'${this.end.toISOString()}'`);
//         }
//         return filters.join(' and ');
//     }
// }

// class SPSearchFilter extends SearchFilter implements SPFilter {
//     toFilterString() {
//         return `substringof('${this.term}',${this.key})`;
//     }
// }

// export function deserialize(record: FilterRecord): SPFilter {
//     switch (record.type) {
//         case 'value': return new SPValueFilter(record);
//         case 'multivalue': return new SPMultiValueFilter(record);
//         case 'range': return new SPRangeFilter(record);
//         case 'date': return new SPDateFilter(record);
//         case 'value': return new SPSearchFilter(record);

//         default: throw new Error('Unknown filter record type');
//     }
// }

const getFilterStringFor = {
    value: (record: ValueRecord) => `${record.key} eq '${record.value}'`,
    multivalue: (record: MultiValueRecord) => {
        const filters = record.values.map((val) => `${record.key} eq '${val}'`);
        return filters.join(' or ');
        // return [ `substringof(${record.key}, '${record.selected.join()}'` ]; // FIXME ???
    },
    range: (record: RangeRecord) => {
        const filters = [];
        if (hasValue(record.min)) {
            filters.push(`${record.key} ge '${record.min}'`);
        }
        if (hasValue(record.max)) {
            filters.push(`${record.key} le '${record.max}'`);
        }
        return filters.join(' and ');
    },
    date: (record: DateRecord) => {
        const filters = [];
        if (hasValue(record.from)) {
            filters.push(`${record.key} ge datetime'${record.from}'`);
        }
        if (hasValue(record.to)) {
            filters.push(`${record.key} le datetime'${record.to}'`);
        }
        return filters.join(' and ');
    },
    search: (record: SearchRecord) => `substringof('${record.term}',${record.key})`
};

export function generateFilterString(records: FilterRecord[], exclude?: FilterRecord) {
    if (exclude) {
        records = records.filter((el) => el.key !== exclude.key);
    }

    return records.map((r) => '(' + getFilterStringFor[r.type](r) + ')').join(' and ');
}
