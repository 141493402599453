<header class="h-100 container-fluid">
    <div class="row">
        <div class="col-8 col-sm-6 d-flex align-items-center">
            <a class="mod-logo ms-md-5 me-sm-4" routerLink="/"></a>
            <h1 class="fs-4 d-none d-sm-block app-title" routerLink="/">
                {{ 'main-title.header-title' | transloco }}
            </h1>
        </div>

        <div class="col-4 col-sm-6">
            <div class="w-100 d-flex align-items-center justify-content-end">
                @if (appUserService.loggedInUser$ | async) {
                    <ul class="menu-main">
                        <ceres-session-timer class="me-4 d-none d-md-block"></ceres-session-timer>
                        @if (!appUserService.isUser()) {
                            <li class="search d-flex d-none d-sm-block" [class.opened]="isSearchOpen">
                                <a (click)="openSearch()" [hidden]="isSearchOpen">
                                    <i class="head-nav-icon material-icons">search</i>
                                </a>
                                <ceres-search class="search-component" (closeSearch)="isSearchOpen = false">
                                </ceres-search>
                            </li>
                        }
                        <li class="d-flex">
                            <a (click)="changeLanguage()">
                                <i class="head-nav-icon material-icons">language</i>
                            </a>
                        </li>
                        @if (appUserService.hasPermission(permission.task)) {
                            <li class="d-flex d-none d-sm-block">
                                <a routerLink="/tasks/status/open" style="width: 60px; margin-left: 8px">
                                    <i
                                        style="width: 40px"
                                        class="head-nav-icon material-icons"
                                        matBadge="{{ openTasksCount }}"
                                        matBadgeOverlap="true"
                                        matBadgePosition="below"
                                        matBadgeSize="medium"
                                        >notifications</i
                                    >
                                </a>
                            </li>
                        }
                        <li class="theme-toggle" *ceresRequiredFeatures="[features.global.darkMode]">
                            <mat-slide-toggle (change)="changeTheme()" [(checked)]="checked"> </mat-slide-toggle>
                        </li>
                        <li class="d-flex">
                            <a (click)="logout()">
                                <i class="material-icons head-nav-icon">exit_to_app</i>
                            </a>
                        </li>
                    </ul>
                }
            </div>
        </div>
    </div>

    <!-- SIDEBAR -->
    @if (appUserService.loggedInUser$ | async) {
        <div class="menu-main">
            <ceres-navbar
                (menuOpen)="isNavigationOpen = $event"
                (partlyOpen)="isNavigationPartlyOpen = $event"
                [isMenuOpen]="isNavigationOpen"
                [isPartlyOpen]="isNavigationPartlyOpen"
            >
            </ceres-navbar>
        </div>
    }
</header>
