import { Workload } from './workload';
import { ProjectProfile } from './project-profile';
import { Employee } from './employee';
import { Activity } from './activity';

export interface Favorite {
    id: number;
    project: ProjectProfile;
    employee: Employee;
    sortOrder: number;
    activity: Activity[];
    businessPartner: string;
    isFavorite: boolean;
    workload: Workload[];
}
