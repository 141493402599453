import { timer, throwError, Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { OverrideRetryParams, RetryParams } from './retry-params';

export class RetryStrategy {
    private configuration: RetryParams = {
        maxAttempts: 3,
        scalingDuration: 500,
        shouldRetry: ({ status }) => status === 0 || status >= 502
    };

    configure(configuration?: OverrideRetryParams) {
        if (configuration) {
            this.configuration = {
                ...this.configuration,
                ...configuration
            };
        }
        return this;
    }

    build(attempts: Observable<any>) {
        return attempts.pipe(
            mergeMap((error, i) => {
                const { maxAttempts, scalingDuration, shouldRetry } = this.configuration;
                const retryAttempt = i + 1;
                if (retryAttempt > maxAttempts || !shouldRetry(error)) {
                    return throwError(error);
                }
                return timer(retryAttempt * scalingDuration);
            })
        );
    }
}
