import {
    HttpBackend,
    HttpHandler,
    HTTP_INTERCEPTORS,
    HttpInterceptor,
    HttpRequest,
    HttpEvent
} from '@angular/common/http';
import { Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { ImpersonationInterceptor } from './impersonation.interceptor';
import { HttpInterceptorHandler } from './http-interceptor-handler';

export class ImpersonationHttpHandler implements HttpHandler {
    private chain: HttpHandler | null = null;

    constructor(
        private readonly backend: HttpBackend,
        private readonly injector: Injector
    ) {}

    handle(req: HttpRequest<unknown>): Observable<HttpEvent<unknown>> {
        const chain = this.chain ?? this.buildChain();
        return chain.handle(req);
    }

    private buildChain(): HttpHandler {
        const defaultInterceptors = this.injector.get<HttpInterceptor[]>(HTTP_INTERCEPTORS, []);
        const impersonationInterceptor = this.injector.get<HttpInterceptor>(ImpersonationInterceptor);

        const interceptors = [...defaultInterceptors, impersonationInterceptor];

        this.chain = interceptors.reduceRight(
            (next, interceptor) => new HttpInterceptorHandler(next, interceptor),
            this.backend
        );
        return this.chain;
    }
}
