import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Document, ProjectProfile } from '@ceres/domain';

@Injectable({
    providedIn: 'root'
})
export class ProjectDataService {
    projectData: ProjectProfile;
    private isValid: boolean;
    private hasChanges: boolean;
    public projectSource$$ = new BehaviorSubject<ProjectProfile>(null);
    currentProject$ = this.projectSource$$.asObservable();
    private documentsSource = new BehaviorSubject([]);
    documents = this.documentsSource.asObservable();
    // public documentsToUpload = false;

    storeProject(project: ProjectProfile) {
        this.projectSource$$.next(project);
        this.hasChanges = true;
    }

    setProjectData(data: ProjectProfile) {
        this.projectSource$$.next(data);
    }

    changeValidity(state: boolean) {
        this.isValid = state;
    }

    getValidity(): boolean {
        return this.isValid;
    }

    // not used
    /* storeDocuments(documents: Document[]) {
    this.documentsSource.next(documents);
  }*/

    setDocuments(documents: Document[]) {
        this.documentsSource.next(documents);
    }

    setChanges(changed: boolean): void {
        this.hasChanges = changed;
    }

    getChanges(): boolean {
        return this.hasChanges;
    }
}
