@if (is(filter, filterTypes.DateFilter)) {
    <lib-date-filter [filter]="filter" [filterService]="filterService" (close)="close.next(true)"> </lib-date-filter>
}

@if (is(filter, filterTypes.ValueFilter)) {
    <lib-single-select-filter
        [filter]="filter"
        [filterService]="filterService"
        [isTranslationKey]="isTranslationKey"
        [translationPrefix]="translationPrefix"
        (close)="close.next(true)"
    >
    </lib-single-select-filter>
}

@if (is(filter, filterTypes.MultiValueFilter)) {
    <lib-multi-select-filter
        [filter]="filter"
        [filterService]="filterService"
        [isTranslationKey]="isTranslationKey"
        [translationPrefix]="translationPrefix"
        (close)="close.next(true)"
    >
    </lib-multi-select-filter>
}

@if (is(filter, filterTypes.RangeFilter)) {
    <lib-range-filter [filter]="filter" [filterService]="filterService" (close)="close.next(true)"> </lib-range-filter>
}

@if (is(filter, filterTypes.SearchFilter)) {
    <lib-search-filter [filter]="filter" [filterService]="filterService" (close)="close.next(true)">
    </lib-search-filter>
}
