<div
    class="session-timer"
    [ngClass]="{ expiring: timeLeft <= 5 }"
    matTooltip="{{ 'control-center.session-timer.information' | transloco }}"
    matTooltipClass="pwo-tooltip"
>
    {{ 'header.session-expired' | transloco }} {{ timeLeft | timer }}
    {{ (timeLeft < 60 ? 'header.minutes' : 'time-management.capacity.hours') | transloco }}

    @if (timeLeft < 5) {
        <button class="refreshButton" (click)="renewToken()">
            <mat-icon class="refreshIcon">refresh</mat-icon>
        </button>
    }
</div>
