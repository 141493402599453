import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

/**
 * `HttpHandler` which applies an `HttpInterceptor` to an `HttpRequest`.
 */
export class HttpInterceptorHandler implements HttpHandler {
    constructor(
        private readonly next: HttpHandler,
        private readonly interceptor: HttpInterceptor
    ) {}

    handle(req: HttpRequest<unknown>) {
        return this.interceptor.intercept(req, this.next);
    }
}
