import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'extractTranslationKey',
    pure: true
})
export class TranslationKeyExtractPipe implements PipeTransform {
    transform(element: any, args?: any): any {
        const entityName = args.split('.')[0];
        return element[entityName] ? element[entityName].translationKey : null;
    }
}
