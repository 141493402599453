import {
    Bop as CeresBop,
    BusinessArea,
    BusinessPartner,
    Employee,
    Portfolio,
    PortfolioCategory,
    BopStatus,
    TranslationOption,
    TranslationOptionProbabilityClass
} from '@ceres/domain';

export class Bop implements CeresBop {
    id: number;
    bopNumber: string;
    bopLeader: Employee;
    portfolioCategory: PortfolioCategory;
    portfolio: Portfolio;
    businessArea: BusinessArea;
    plannedProjectStart: Date;
    plannedProjectEnd: Date;
    bopTitle: string;
    content: string;
    offerDate: Date;
    offerTitle: string;
    bopStatus: BopStatus;
    probabilityClass: TranslationOptionProbabilityClass;
    declineReason: TranslationOption;
    declineComment: string;
    businessPartner: BusinessPartner;
    chargeVolumeOwn: number;
    chargeVolumeOthers: number;
    materialCosts: number;

    constructor(object?: Partial<Bop>) {
        if (object) {
            Object.assign(this, object);
        }
    }
}
