import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { TranslocoModule } from '@ngneat/transloco';
import { UploadFileComponent } from './upload-file.component';

@NgModule({
    imports: [CommonModule, DragDropModule, MatProgressBarModule, TranslocoModule],
    declarations: [UploadFileComponent],
    exports: [UploadFileComponent]
})
export class UploadFileModule {}
