import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DateService } from '../../services/date.service';
import { CapacityUnitModel } from '../../interfaces/capacity-unit.model';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'capacity-column-header',
    templateUrl: './capacity-column-header.component.html',
    styleUrls: ['./capacity-column-header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CapacityColumnHeaderComponent {
    heading = '';

    private _unitModel: CapacityUnitModel = null;
    @Input()
    set unitModel(value: CapacityUnitModel) {
        this._unitModel = value;

        this.heading = this.dateService.translateUnit(value.unit, value.unitType);
    }
    get unitModel(): CapacityUnitModel {
        return this._unitModel;
    }

    constructor(private readonly dateService: DateService) {}
}
