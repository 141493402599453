import { Program as CeresProgram, StatusReport, ProjectProfile, Portfolio } from '@ceres/domain';

export class Program implements CeresProgram {
    id: number;
    title = '';
    statusReports: StatusReport[] = [];
    projects: ProjectProfile[] = [];
    interfaceificationGrade = '';
    classificationGrade = '';
    isFavorite: boolean;
    isRemovable: boolean;
    favoriteId: number;
    sortOrder: number;
    originalSortOrder: number;
    portfolio: Portfolio = { id: null, abbreviation: '', title: '' };
    originalPortfolio: Portfolio = { id: null, abbreviation: '', title: '' };
    active: boolean;
    rootFolderId: number;

    constructor(object?: Partial<Program>) {
        if (object) {
            Object.assign(this, object);
        }
    }
}
