<div class="d-flex flex-wrap gap-3 align-items-center">
    <mat-form-field appearance="fill">
        <mat-label>{{ 'my-ceres.settings.custom-filter.select-custom-filter' | transloco }}</mat-label>
        <mat-select [(ngModel)]="selectedCustomFilter">
            @for (customFilterOption of customFilterOptions; track customFilterOption) {
                <mat-option (click)="applySectionFilters(customFilterOption)" [value]="customFilterOption"
                    >{{ customFilterOption.filterTitle }}
                </mat-option>
            }
            @if (!!customFilterOptions && customFilterOptions?.length === 0) {
                <mat-option disabled>{{ 'my-ceres.settings.custom-filter.no-custom-filter' | transloco }} </mat-option>
            }
        </mat-select>
    </mat-form-field>
    <ceres-button (click)="saveCustomFilter()">
        @if (!isLoading) {
            <mat-icon>save</mat-icon>
        }
        @if (isLoading) {
            <ceres-loading [diameterSize]="24" [insideButton]="true" [noMessage]="true"></ceres-loading>
        }
        <span>{{ 'my-ceres.settings.custom-filter.save-custom-filter-button' | transloco }}</span>
    </ceres-button>
    <ceres-button (click)="resetFilters()" [style]="'grey'">
        <mat-icon>refresh</mat-icon>
        <span>{{ 'my-ceres.settings.custom-filter.reset-custom-filter-button' | transloco }}</span>
    </ceres-button>
</div>
