import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Component, Inject, OnInit, ViewChild, AfterViewChecked, ViewEncapsulation } from '@angular/core';
import { Portfolio } from '@ceres/domain';
import { ItemReference } from '@ceres/domain';
import { FilterService } from '@ceres/filter';
import { Program } from '../../models';
import { PortfolioService } from '../../services/portfolio.service';
import { ProgramService } from '../../services/program.service';
import { FilterFactoryService } from '../../services/filter-factory.service';

@Component({
    selector: 'ceres-program-selection',
    templateUrl: './program-selection.component.html',
    styleUrls: ['./program-selection.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ProgramSelectionComponent implements OnInit, AfterViewChecked {
    public filterService = new FilterService<Program>();
    public dataSource = new MatTableDataSource<Program>();
    public filters = {};
    public busy = true;
    public draftItem = new Program();

    displayedColumns = ['selected', 'title', 'portfolio', 'save'];
    filterNames = ['portfolio.title'];
    selectedPrograms = [];
    column: any;
    multiple = false;

    allChecked = false;
    fewSelected = false;

    spinnerMode = 'indeterminate';

    public portfolios: Portfolio[];

    @ViewChild(MatSort)
    sort: MatSort;
    @ViewChild(MatPaginator)
    paginator: MatPaginator;

    constructor(
        public dialogRef: MatDialogRef<ProgramSelectionComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private programService: ProgramService,
        private portfolioService: PortfolioService,
        private filterFactory: FilterFactoryService
    ) {
        if (this.data.multiple) {
            this.multiple = this.data.multiple;
        }
        if (this.data.programs && this.data.programs.length) {
            this.selectedPrograms = this.data.programs.filter((e) => e.id);
        }
        if (data.column) {
            this.column = data.column;
        }
    }

    ngOnInit() {
        this.filters = this.filterFactory.create(this.filterNames);
        this.filterService.init(Object.values(this.filters));
        this.filterService.dataChanged.subscribe((d) => {
            this.dataSource.data = d;
        });
        this.loadData();
    }

    ngAfterViewChecked() {
        if (this.dataSource && !this.dataSource.paginator) {
            this.dataSource.paginator = this.paginator;
        }
        if (this.dataSource && !this.dataSource.sort && this.sort) {
            this.dataSource.sort = this.sort;
            this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
        }
    }

    loadData() {
        this.busy = true;

        void Promise.all([this.portfolioService.getAll(), this.programService.getAll()]).then(
            ([portfolios, programs]) => {
                this.portfolios = portfolios;
                if (this.selectedPrograms) {
                    programs.forEach((program) => {
                        (program as unknown as { selected }).selected = this.selectedPrograms.some(
                            (selected) => program.id === selected.id
                        );
                    });
                }

                this.filterService.setData(programs);
                this.busy = false;
            }
        );
    }

    changeSelected(element, event) {
        if (!this.multiple) {
            if (event.checked) {
                for (const user of this.dataSource.data) {
                    (user as any).selected = false;
                }
                this.selectedPrograms = element;
                element.selected = event.checked;
            } else {
                delete this.selectedPrograms;
            }
        } else {
            element.selected = event.checked;
            if (event.checked) {
                this.selectedPrograms.push(element);
            } else {
                this.selectedPrograms.forEach((item, idx) => {
                    if (item.id === element.id) {
                        this.selectedPrograms.splice(idx, 1);
                    }
                });
            }
            if (this.dataSource.filteredData.length === this.selectedPrograms.length) {
                this.allChecked = true;
                this.fewSelected = false;
            } else {
                this.fewSelected = this.selectedPrograms.length > 0;
                this.allChecked = false;
            }
        }
    }

    selectAll() {
        if (this.allChecked || this.fewSelected) {
            for (const user of this.dataSource.filteredData) {
                (user as any).selected = true;
            }
            // this.selectedEmployees = Object.assign([], this.dataSource.filteredData);
            this.selectedPrograms = [...this.dataSource.filteredData];
            this.fewSelected = false;
            this.allChecked = true;
        } else if (!this.allChecked) {
            this.selectedPrograms = [];
            for (const user of this.dataSource.filteredData) {
                (user as any).selected = false;
            }
        }
    }

    remove(element) {
        this.changeSelected(element, { checked: false });
        for (const item of this.dataSource.data) {
            if (item === element) {
                (item as any).selected = false;
            }
        }
    }

    closeDialog(selectedPrograms) {
        if (selectedPrograms && selectedPrograms.length <= 0) {
            this.dialogRef.close();
        } else {
            if (!this.multiple) {
                this.dialogRef.close({ ...selectedPrograms });
            } else {
                this.dialogRef.close(this.selectedPrograms);
            }
        }
    }

    createItem() {
        void this.programService.create(this.draftItem).then(() => {
            this.loadData();
            this.draftItem = new Program();
        });
    }

    deleteItem(program: Program) {
        void this.programService.delete(program).then(() => {
            this.loadData();
        });
    }

    updateItem(program: Program) {
        void this.programService.update(program).then(() => {
            this.loadData();
        });
    }

    compareLookups(item1: ItemReference, item2: ItemReference): boolean {
        return item1 && item2 && item1.id === item2.id;
    }
}
