import { Injectable } from '@angular/core';
import { Employee, ProjectProfile } from '@ceres/domain';
import { TranslocoService } from '@ngneat/transloco';
import { ImpersonatedHttpClient } from '@ceres/shared/services';
import { environment } from '../../../environments/environment';
import { Favorite } from '../models';
import { ProjectDataService } from '../../project/components/services/project-data.service';

@Injectable({
    providedIn: 'root'
})
export class FavoriteService {
    constructor(
        private translateService: TranslocoService,
        private httpClient: ImpersonatedHttpClient,
        private projectDataService: ProjectDataService
    ) {}

    public getByEmployeeId(id: number): Promise<Favorite[]> {
        return this.httpClient.get<Favorite[]>(`${environment.edgeService}/favorites/employees/${id}`).toPromise();
    }

    // not used - deprecated
    public getByEmployeeIdOnlyFavorited(id: number): Promise<Favorite[]> {
        return this.httpClient
            .get<Favorite[]>(`${environment.edgeService}/favorites/employees/${id}`, {
                params: { favorite: 'true' }
            })
            .toPromise();
    }

    public getAllByProjectWithEmployees(id: number): Promise<Favorite[]> {
        const language = this.translateService.getActiveLang();
        return this.httpClient
            .get<Favorite[]>(`${environment.edgeService}/favorites/projects/${id}`, {
                params: { language }
            })
            .toPromise();
    }

    // not used - deprecated
    public async createFavorite(project: ProjectProfile, employee: Employee): Promise<Favorite> {
        const favorite = this.buildFavoriteObject(project, employee);
        return this.httpClient.post<Favorite>(`${environment.edgeService}/favorites`, favorite).toPromise();
    }

    // not used - deprecated
    public async updateFavorite(project: Favorite): Promise<Favorite> {
        return this.httpClient.patch<Favorite>(`${environment.edgeService}/favorites`, project).toPromise();
    }

    public async saveAll(favorites: Favorite[]): Promise<Favorite[]> {
        return await this.httpClient.put<Favorite[]>(`${environment.edgeService}/favorites`, favorites).toPromise();
    }

    public async saveAllProjectFavorites(favorites: Favorite[]): Promise<Favorite[]> {
        return await this.httpClient
            .put<Favorite[]>(`${environment.edgeService}/favorites/project-favorites`, favorites)
            .toPromise();
    }

    public async saveOrUpdate(project: ProjectProfile, employees: Employee[]): Promise<Favorite[][]> {
        const favoritesToSave: Favorite[] = [];
        for (const employee of employees) {
            const favoriteObject = this.buildFavoriteObject(project, employee);
            favoritesToSave.push(favoriteObject);
        }

        const chunks: Favorite[][] = favoritesToSave.reduce(
            (agg, favorite) => {
                const lastChunk: Favorite[] = agg.pop();
                if (lastChunk.length === 25) {
                    return [...agg, lastChunk, [favorite]];
                } else {
                    return [...agg, [...lastChunk, favorite]];
                }
            },
            [[]] as Favorite[][]
        );

        return Promise.all(chunks.map(async (chunk: Favorite[]) => await this.saveAll(chunk)));
    }

    public async setFavoriteFalse(project: ProjectProfile, employees: Employee[]): Promise<Favorite[]> {
        const favoritsToSave = [];

        for (const employee of employees) {
            const favorite = await this.getFavorite(project.id, employee.id);
            if (favorite) {
                favorite.isFavorite = false;
                favoritsToSave.push(favorite);
            }
        }
        return await this.saveAllProjectFavorites(favoritsToSave);
    }

    private async getFavorite(projectId: number, employeeId: number): Promise<Favorite> {
        try {
            return await this.httpClient
                .get<Favorite>(`${environment.edgeService}/favorites/projects/${projectId}/employees/${employeeId}`)
                .toPromise();
        } catch (e) {
            return undefined;
        }
    }

    private buildFavoriteObject(project: ProjectProfile, employee: Employee): Favorite {
        const employeeWithFavorite = project.employees.find((empl) => empl.id === employee.id);
        const favorite = new Favorite();

        if (employeeWithFavorite && employeeWithFavorite.favorite) {
            favorite.id = employeeWithFavorite.favorite.id;
        }
        favorite.isFavorite = true;
        favorite.employee = { id: employee.id } as Employee;
        favorite.project = { id: project.id } as ProjectProfile;
        favorite.sortOrder = project.sortOrder ? project.sortOrder : 0;
        favorite.activity = employee.assignedActivities ? employee.assignedActivities : [employee.defaultActivity];
        return favorite;
    }
}
