export interface Currency {
    code: string;
    name: string;
}

export interface ExchangeRate {
    referenceDate: string;
    value: number;
}

export interface CurrencyWithExchangeRate extends Currency {
    latestExchangeRate: ExchangeRate;
}
