import { Component, Inject, HostListener } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'ceres-discard-dialog',
    templateUrl: './discard-dialog.component.html',
    styleUrls: ['./discard-dialog.component.scss']
})
export class DiscardDialogComponent {
    constructor(
        @Inject(MAT_DIALOG_DATA)
        public data: {
            text: string;
            header: string;
            disableClose: boolean;
            confirmButtonText: string;
        },
        private matDialogRef: MatDialogRef<DiscardDialogComponent>
    ) {}

    public cancel() {
        this.matDialogRef.close();
    }

    public confirm() {
        this.matDialogRef.close(true);
    }

    @HostListener('keydown.esc')
    public onEsc() {
        this.matDialogRef.close();
    }
}
