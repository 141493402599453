import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { DateRangeType } from '@ceres/domain';

import { FormBuilder, FormGroup } from '@angular/forms';
import { AppSettingsService } from '../../services/app-settings/app-settings.service';

@Component({
    selector: 'ceres-admin-general',
    templateUrl: './admin-general.component.html',
    styleUrls: ['./admin-general.component.scss']
})
export class AdminGeneralComponent implements OnDestroy {
    private readonly subscriptions = new Subscription();

    public readonly settingsForm: FormGroup;

    public readonly DateRangeType = DateRangeType;

    public constructor(
        private readonly fb: FormBuilder,
        private readonly appSettingsService: AppSettingsService
    ) {
        this.settingsForm = this.fb.group({
            dateRangeType: []
        });

        this.subscriptions.add(
            this.appSettingsService.appSettings$.subscribe({
                next: (settings) => this.settingsForm.patchValue(settings)
            })
        );

        this.appSettingsService.loadAppSettings();
    }

    public saveSettings(): void {
        this.appSettingsService.updateSettings(this.settingsForm.value);
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
