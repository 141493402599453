import { ApiHighlight as CeresApiHighlight, ApiPortfolio, ApiWorkload } from '@ceres/domain';

export class ApiHighlight implements CeresApiHighlight {
    Id = 0;
    PortfolioId: number;
    Portfolio: ApiPortfolio;
    BusinessResults = '';
    Productivity = '';
    Strategy = '';
    Acquisition = '';
    EmployeesFixed = 0;
    EmployeesLeasing = 0;
    EmployeesIntern = 0;
    LESalesReal = 0;
    LESalesPeriod = 0;
    LECostReal = 0;
    LECostPeriod = 0;
    AccountingMonth = -1;
    BOPVolume = 0;
    AEActual = 0;
    AEbereinigt = 0;

    Workload: ApiWorkload;
    LESalesConservative = 0;
    LECostConservative = 0;
    LESalesOptimistic = 0;
    LECostOptimistic = 0;
    ChargingQuote = 0;

    constructor(object?: Partial<ApiHighlight>) {
        if (object) {
            Object.assign(this, object);
        }
    }
}
