import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ButtonModule, LayoutModule } from '@ceres/ui';
import { AdminGeneralComponent } from './components/admin-general/admin-general.component';

@NgModule({
    imports: [
        CommonModule,
        TranslocoModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        ReactiveFormsModule,
        RouterModule,
        ButtonModule,
        LayoutModule
    ],
    declarations: [AdminGeneralComponent],
    exports: [AdminGeneralComponent]
})
export class AdminGeneralModule {}
