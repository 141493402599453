import { DocumentStatus, SPFile } from '@ceres/domain';

// TODO Alle referenzen zu dieser Classe entfernen (ceres/domain nutzen mit neuer Logik) und anschließend diese Klasse löschen
export class Document {
    id: number;
    title: string;
    filePath: string;
    url: string;
    fileName: string;
    metaData: SPFile;
    status: DocumentStatus;

    folderName: string;
    file?: File;

    constructor(object?: Partial<Document>) {
        Object.assign(this, object);
    }
}
