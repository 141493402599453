<div class="row list-container mat-card" [hidden]="projectChartService.loading$ | async">
    <div class="col-12 mb-2">
        <h3>{{ 'projects.customer-entry' | transloco }}</h3>
    </div>
    @if (countsByCustomerTypeEntry && !countsByCustomerDepartmentEntry) {
        <div class="col-12">
            <ngx-charts-advanced-pie-chart
                (select)="onSelect($event)"
                [view]="view"
                [scheme]="colorScheme"
                [results]="countsByCustomerTypeEntry"
            >
            </ngx-charts-advanced-pie-chart>
        </div>
    }
    @if (countsByCustomerDepartmentEntry) {
        <div class="col-12">
            <button
                class="widget-button a-button a-button--pwo a-icon a-icon--arrow-left-small"
                type="button"
                (click)="resetSelection()"
            >
                BACK
            </button>
            <ngx-charts-advanced-pie-chart
                [view]="view"
                [scheme]="colorScheme"
                [results]="countsByCustomerDepartmentEntry"
            >
            </ngx-charts-advanced-pie-chart>
        </div>
    }
</div>
@if (projectChartService.loading$ | async) {
    <ceres-loading></ceres-loading>
}
