import { Activity, BusinessAreaDto, BusinessAreaType } from '@ceres/domain';
import { AppUserService } from '@ceres/shared/services';
import { Component, Inject, OnInit, ViewChild, AfterViewChecked, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Employee } from '@ceres/domain';
import { EmployeeService } from '@ceres/shared/services';
import { Month } from '@ceres/domain';
import { ItemReference } from '@ceres/domain';
import { TranslocoService } from '@ngneat/transloco';

import { FilterService } from '@ceres/filter';
import { FilterFactoryService } from '../services/filter-factory.service';

@Component({
    selector: 'ceres-scd-dialog',
    templateUrl: './scd-dialog.component.html',
    styleUrls: ['./scd-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ScdDialogComponent implements OnInit, AfterViewChecked {
    public busy = true;
    public filterService = new FilterService<Employee>();
    displayedColumns = ['selected', 'status-ico', 'title', 'portfolio'];
    filterNames = ['isActive', 'portfolio.title', 'businessArea.title', 'businessAreas.name', 'squads.name'];
    public dataSource = new MatTableDataSource<any>([]);
    filters: any;
    selectedEmployees: Employee[] = [];
    column: any;
    multiple = false;
    dontShow = false;
    fullUser = false;
    kind: string;
    month: Month;
    activity: boolean;

    activities: Activity[] = [];

    currentUser: Employee;

    allChecked = false;
    fewSelected = false;

    spinnerMode = 'indeterminate';

    employees: Employee[];

    @ViewChild(MatSort) sort!: MatSort;
    @ViewChild(MatPaginator) paginator!: MatPaginator;

    constructor(
        public dialogRef: MatDialogRef<ScdDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private employeeService: EmployeeService,
        private appUserService: AppUserService,
        private translateService: TranslocoService,
        private filterFactory: FilterFactoryService
    ) {
        if (this.data.multiple) {
            this.multiple = this.data.multiple;
        }
        if (this.data.fullUser) {
            this.fullUser = this.data.fullUser;
        }

        if (this.data.employees && this.data.employees.length && Array.isArray(this.data.employees)) {
            this.selectedEmployees = this.data.employees.filter((e) => e.id);
        }
        if (data.column) {
            this.column = data.column;
        }
        if (data.month) {
            this.month = data.month;
            this.displayedColumns = ['selected', 'status-ico', 'title', 'portfolio', 'teamName', 'squadName'];
        }
        if (data.kind) {
            this.kind = data.kind;
        }
        if (data.dontShow) {
            this.dontShow = data.dontShow;
        }
        this.activity = data.activity;
        if (this.activity) {
            this.displayedColumns = [
                'selected',
                'status-ico',
                'title',
                'portfolio',
                'teamName',
                'squadName',
                'activity'
            ];
        }
    }

    ngOnInit() {
        this.filters = this.filterFactory.create(this.filterNames, 'employee');
        this.filters.isActive.selected.push(true);
        this.filters['businessAreas.name'].valueAccessor = (employee: Employee) =>
            employee.allBusinessAreas
                ?.filter((area) => area.type === BusinessAreaType.BusinessArea)
                .map((area) => area.name);
        this.filters['squads.name'].valueAccessor = (employee: Employee) =>
            employee.allBusinessAreas?.filter((area) => area.type === BusinessAreaType.Squad).map((area) => area.name);

        this.filterService.init(Object.values(this.filters));
        this.filterService.dataChanged.subscribe((d) => {
            this.dataSource.data = d;
        });
        this.filterService.filtersApplied.subscribe((f) => {
            if (f.scope === 'remote') {
                this.loadData();
            }
        });
        this.loadData();
    }

    ngAfterViewChecked() {
        if (this.dataSource && !this.dataSource.paginator) {
            this.dataSource.paginator = this.paginator;
        }
        if (this.dataSource && !this.dataSource.sort && this.sort) {
            this.dataSource.sort = this.sort;
            this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
        }
    }

    filterBusinessAreas(areas: BusinessAreaDto[]) {
        return areas
            .filter((area) => area.type === 'BusinessArea')
            .map((area) => (area.longName ? area.longName : area.name));
    }

    filterSquads(squads: BusinessAreaDto[]) {
        return squads
            .filter((area) => area.type === 'Squad')
            .map((area) => (area.longName ? area.longName : area.name));
    }

    loadData() {
        this.busy = true;
        this.currentUser = this.appUserService.loggedInUser$.getValue().details;

        void this.getEmployees().then((employees) => {
            employees.forEach((employee) => {
                (employee as unknown as { selected }).selected = this.selectedEmployees.some(
                    (s) => employee.id === s.id
                );
            });

            if (this.dontShow) {
                employees = employees.filter((e) => !this.selectedEmployees.some((selected) => e.id === selected.id));
            }

            if (this.activity && this.selectedEmployees) {
                employees.forEach((e) => {
                    const selected = this.selectedEmployees.find((a) => a.id === e.id);
                    if (selected) {
                        e.assignedActivities = selected.assignedActivities;
                    }

                    if (!e.assignedActivities || !e.assignedActivities.length) {
                        e.assignedActivities = [e.defaultActivity];
                    }
                });
                this.selectedEmployees.forEach((e) => {
                    if (!e.assignedActivities || !e.assignedActivities.length) {
                        e.assignedActivities = [employees.find((a) => a.id === e.id).defaultActivity];
                    }
                });
            }

            employees = employees.sort((a, b) => (a.name ? a.name.localeCompare(b.name) : 0));
            this.employees = employees;
            this.filterService.setData(employees);
            this.busy = false;
        });
    }

    getEmployees() {
        // hier wird scd gebaut
        return this.employeeService.getAllUsersPicker();
    }

    changeSelected(element, event) {
        if (!this.multiple) {
            if (event.checked) {
                for (const user of this.dataSource.data) {
                    user.selected = false;
                }
                this.selectedEmployees = element;
                element.selected = event.checked;
            } else {
                delete this.selectedEmployees;
            }
        } else {
            element.selected = event.checked;
            if (event.checked) {
                this.selectedEmployees = [...this.selectedEmployees, element];
            } else {
                this.selectedEmployees.forEach((item, idx) => {
                    if (item.id === element.id) {
                        this.selectedEmployees.splice(idx, 1);
                        this.selectedEmployees = [...this.selectedEmployees];
                    }
                });
            }
            if (this.dataSource.filteredData.length === this.selectedEmployees.length) {
                this.allChecked = true;
                this.fewSelected = false;
            } else {
                this.fewSelected = this.selectedEmployees.length > 0;
                this.allChecked = false;
            }
        }
    }

    selectAll() {
        if (this.allChecked || this.fewSelected) {
            for (const user of this.dataSource.filteredData) {
                user.selected = true;
            }
            // this.selectedEmployees = Object.assign([], this.dataSource.filteredData);
            this.selectedEmployees = [...this.dataSource.filteredData];
            this.fewSelected = false;
            this.allChecked = true;
        } else if (!this.allChecked) {
            this.selectedEmployees = [];
            for (const user of this.dataSource.filteredData) {
                user.selected = false;
            }
        }
    }

    remove(element) {
        this.changeSelected(element, { checked: false });
        for (const item of this.dataSource.data) {
            if (item === element) {
                item.selected = false;
            }
        }
    }

    closeDialog(selectedEmployees) {
        if (!selectedEmployees) {
            this.dialogRef.close();
        } else {
            if (!this.multiple) {
                if (this.fullUser) {
                    this.dialogRef.close(selectedEmployees);
                } else {
                    this.dialogRef.close({ ...selectedEmployees });
                }
            } else {
                this.selectedEmployees.forEach((e) => {
                    const tempEmp = this.employees.find((a) => a.id === e.id);
                    e.assignedActivities = tempEmp ? tempEmp.assignedActivities : this.employees[0].assignedActivities;
                });
                if (this.fullUser) {
                    this.dialogRef.close(this.selectedEmployees);
                } else {
                    this.dialogRef.close(
                        this.selectedEmployees.map((employee) => {
                            return { ...employee };
                        })
                    );
                }
            }
        }
    }

    compareLookups(item1: ItemReference, item2: ItemReference): boolean {
        return item1 && item2 && item1.id === item2.id;
    }

    public isDE() {
        return this.translateService.getActiveLang() === 'de';
    }

    public getEmployeeActivities(employee: Employee) {
        return !employee.defaultActivity && !employee.activities
            ? []
            : [...[employee.defaultActivity], ...employee.activities];
    }

    // TODO use employees-have-assigned-activities.pipe for this (add reference changes to selectedEmployees)
    public selectedHaveActivities(selectedEmployees: Employee[]) {
        if (selectedEmployees) {
            return !selectedEmployees.some((employee) =>
                employee.assignedActivities ? employee.assignedActivities.length === 0 : true
            );
        }
        return false;
    }
}
