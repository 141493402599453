export interface PaginationQuery {
    page?: number;
    size?: number;
    sort?: string | string[];
}

export interface PaginationResponse<T> {
    total: number;
    data: T[];
}
