<!-- FILTER ELEMENT -->
@if (column !== 'isFavorite' && column !== 'status-ico') {
    @if (sort) {
        <span class="filter-title" mat-sort-header>{{ name }}</span>
    }
    @if (!sort) {
        <span class="filter-title">{{ name }}</span>
    }
}
@if (column === 'isFavorite') {
    <i class="material-icons" mat-sort-header>star</i>
}
@if (column === 'status-ico') {
    <i class="material-icons">lock</i>
}

<span class="pointer" (click)="visible = true" [matMenuTriggerFor]="filterMenu">
    <ng-container *ngTemplateOutlet="filterIcon"></ng-container>
</span>

<!-- FILTER ICON -->
<ng-template #filterIcon>
    @if (!visible && !isApplied) {
        <span #iconClosed>
            <ng-content select="[icon-closed]"></ng-content>
        </span>
        @if (iconClosed.childNodes.length === 0) {
            <i class="material-icons">keyboard_arrow_down</i>
        }
    }

    @if (visible && !isApplied) {
        <span #iconOpened>
            <ng-content select="[icon-opened]"></ng-content>
        </span>
        @if (iconOpened.childNodes.length === 0) {
            <i class="material-icons">keyboard_arrow_up</i>
        }
    }

    @if (isApplied) {
        <span #iconApplied>
            <ng-content select="[icon-applied]"></ng-content>
        </span>
        <!-- <i *ngIf="iconApplied.childNodes.length === 0" class="icon--filter"></i> -->
        @if (iconApplied.childNodes.length === 0) {
            <i class="material-icons">filter_list</i>
        }
    }
</ng-template>

<!-- FILTER COMPONENT -->
<mat-menu #filterMenu="matMenu" [xPosition]="xPosition" (closed)="visible = false">
    @if (visible) {
        <div class="pwo-filter" [class.pwo-styles]="hasStyles" (click)="$event.stopPropagation()">
            <!-- only render component if shown for better performance -->
            @if (!isTabbed) {
                <lib-filter
                    [filter]="filter"
                    [filterService]="filterService"
                    [isTranslationKey]="isTranslationKey"
                    [translationPrefix]="translationPrefix"
                    (close)="close()"
                ></lib-filter>
            }
            @if (isTabbed) {
                <mat-tab-group>
                    @for (f of filter; track f) {
                        <mat-tab [label]="transform(f.key)">
                            <lib-filter
                                [filter]="f"
                                [filterService]="filterService"
                                [isTranslationKey]="isTranslationKey"
                                [translationPrefix]="translationPrefix"
                                (close)="close()"
                            ></lib-filter>
                        </mat-tab>
                    }
                </mat-tab-group>
            }
        </div>
    }
</mat-menu>
