import { Inject, Injectable } from '@angular/core';
import { ENVIRONMENT } from '@ceres/frontend-helper';
import { ImpersonatedHttpClient } from './http';

export interface SearchQuery {
    term: string;
    count: number;
    categories: SearchCategory<any>[];
}
export interface SearchCategory<T extends { [key: string]: any }> {
    key: string;
    name: string;
    list: string;
    titleColumn: keyof T & string;
    subtitleColumn: keyof T & string;
    additionalSearchColumns?: Array<keyof T & string>;
}
export interface SearchResponse {
    [key: string]: { id: number; title: string; subtitle: string }[];
}

@Injectable({
    providedIn: 'root'
})
export class GlobalSearchService {
    constructor(
        private readonly http: ImpersonatedHttpClient,
        @Inject(ENVIRONMENT) private readonly environment: { edgeService: string }
    ) {}

    async search(query: SearchQuery): Promise<SearchResponse[]> {
        return this.http
            .get<SearchResponse[]>(`${this.environment.edgeService}/search`, {
                params: { q: JSON.stringify(query) }
            })
            .toPromise();
    }
}
