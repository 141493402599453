export const rolePermissions = {
    absences: 'absences',
    // admin: 'admin',
    admin: {
        access: 'admin.access',
        general: 'admin.general',
        activities: 'admin.activities',
        businessareas: 'admin.businessareas',
        deletecontract: 'admin.deletecontract',
        currencies: 'admin.currencies',
        exchangerate: 'admin.exchangerate',
        pspelement: 'admin.pspelement',
        holiday: 'admin.holiday',
        juno: 'admin.juno',
        locations: 'admin.locations',
        materialmaster: 'admin.materialmaster',
        news: 'admin.news',
        portfolios: 'admin.portfolios',
        customerstructure: 'admin.customerstructure',
        pricingpackage: 'admin.pricingpackage',
        translations: 'admin.translations',
        commercialimporter: 'admin.commercialimporter',
        bviimporter: 'admin.bviimporter',
        externalimporter: 'admin.externalimporter',
        role: 'admin.role',
        accountinginternal: 'admin.accountinginternal',
        businesspartnerscdupdate: 'admin.businesspartnerscdupdate',
        employeescdupdate: 'admin.employeescdupdate',
        skillmatrix: 'admin.skillmatrix',
        serviceproducts: 'admin.serviceproducts',
        accessServicePackages: 'admin.accessServicePackages',
        servicedepartmentpackage: 'admin.servicedepartmentpackage'
    },
    bop: {
        createOne: 'bop.createOne',
        editOne: 'bop.editOne',
        readAll: 'bop.readAll'
    },
    businesspartner: {
        createOne: 'businesspartner.createOne',
        editOne: 'businesspartner.editOne',
        readAll: 'businesspartner.readAll'
    },
    capacities: 'capacities',
    costReport: 'costReport',
    workingHoursReport: 'workingHoursReport',
    productivityReport: 'productivityReport',
    contract: {
        readAll: 'contract.readAll',
        editOne: 'contract.editOne',
        createOne: 'contract.createOne',
        reviewAll: 'contract.reviewAll',
        offertemplate: {
            readAll: 'contract.offer-template.readAll',
            editOne: 'contract.offer-template.editOne',
            createOne: 'contract.offer-template.createOne'
        }
    },
    controlling: {
        salesFunnel: 'controlling.salesFunnel',
        quality: 'controlling.quality'
    },
    employee: {
        readAll: 'employee.readAll',
        createOne: 'employee.createOne',
        editOne: 'employee.editOne',
        readOne: 'employee.readOne',
        skill: 'employee.skill'
    },
    myceres: {
        settings: 'myceres.settings',
        skills: 'myceres.skills'
    },
    program: {
        readAll: 'program.readAll',
        statusReport: {
            readAll: 'program.statusReport.readAll',
            createOne: 'program.statusReport.createOne',
            editOne: 'program.statusReport.editOne'
        },
        classification: 'program.classification'
    },
    project: {
        readExtern: 'project.readExtern',
        readAll: 'project.readAll',
        readOwn: 'project.readOwn',
        editOne: 'project.editOne',
        createOne: 'project.createOne',
        nrs: 'project.nrs',
        accounting: 'project.accounting',
        preStaffing: {
            readAll: 'project.preStaffing.readAll',
            editOne: 'project.preStaffing.editOne'
        },
        document: {
            editOwn: 'project.document.editOwn'
        }
    },
    reports: 'reports',
    salesActivity: {
        readAll: 'salesActivity.readAll',
        editOne: 'salesActivity.editOne',
        createOne: 'salesActivity.createOne'
    },
    services: {
        readAll: 'services.readAll',
        editAll: 'services.editAll'
    },
    task: 'task',
    guest: 'guest',
    support: 'support',
    timesheet: 'timesheet',
    // workload: 'workload',
    workload: {
        readAll: 'workload.readAll',
        changeValues: 'workload.changeValues'
    }
};
