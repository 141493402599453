import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
    selector: 'ceres-single-input-dialog',
    templateUrl: './single-input-dialog.component.html',
    styleUrls: ['./single-input-dialog.component.scss']
})
export class SingleInputDialogComponent implements OnInit {
    newViewName = '';
    form: FormGroup;
    isEmpty = false;

    constructor(
        private formBuilder: FormBuilder,
        public dialogRef: MatDialogRef<SingleInputDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    ngOnInit() {
        this.form = this.formBuilder.group({
            newViewName: ''
        });
    }

    isFieldValid(field: string) {
        switch (field) {
            case 'newViewName':
                if (this.form.get(field).touched && this.data.existingViews.includes(this.form.value.newViewName)) {
                    return false;
                }
                break;

            default:
                break;
        }
        return true;
    }

    submit() {
        if (this.form.value.newViewName.length === 0) {
            this.isEmpty = true;
            return false;
        }

        if (!this.isFieldValid('newViewName')) {
            return false;
        }

        this.dialogRef.close(`${this.form.value.newViewName}`);
    }
}
