import { Injectable } from '@angular/core';
import {
    Document,
    SignedURLUploadRequest,
    SignedURLUploadResponse,
    SignedURLDownloadResponse,
    Upload
} from '@ceres/domain';
import { HttpParams, HttpEventType } from '@angular/common/http';
import { BehaviorSubject, Observable, of, from, throwError } from 'rxjs';
import { map, mergeMap, filter, toArray, switchMap } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import * as JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { ImpersonatedHttpClient } from '@ceres/shared/services';
import { environment } from '../../../environments/environment';
@Injectable({
    providedIn: 'root'
})
export class ContractDocumentService {
    private _fileUpload: BehaviorSubject<Map<string, Upload>> = new BehaviorSubject<Map<string, Upload>>(
        new Map<string, Upload>()
    );
    public fileUpload$: Observable<Map<string, Upload>> = this._fileUpload.asObservable();

    private documentUrl = `${environment.edgeService}/documents`;

    constructor(private httpClient: ImpersonatedHttpClient) {}

    // USED IN CONTRACT COMPONENTS
    public getDocuments(contractId: string) {
        return this.httpClient
            .get<Document[]>(`${environment.edgeService}/documents/contracts/${contractId}`)
            .toPromise();
    }

    // USED IN CONTRACT COMPONENTS AND PROJECT DETAILS
    public getOnlyActiveDocuments(contractId: string): Promise<Document[]> {
        return this.httpClient
            .get<Document[]>(`${environment.edgeService}/documents/contracts/${contractId}`, {
                params: { active: 'true' }
            })
            .toPromise();
    }

    // USED IN CONTRACT LIST COMPONENT
    public downloadFile(id: number, name: string) {
        this.httpClient
            .get<SignedURLDownloadResponse>(`${environment.edgeService}/documents/document/${id}`)
            .pipe(switchMap((res) => this.httpClient.get(res.url, { responseType: 'blob' as const })))
            .subscribe(
                (res) => {
                    saveAs(res, name);
                },
                (err) => console.error('An Error occured:', err)
            );
    }

    public downloadFolder(folderId: number, contractName: string) {
        this.httpClient
            .get<Document[]>(`${environment.edgeService}/documents/folder/${folderId}`)
            .pipe(
                map((documents) => (documents.length > 0 ? documents : throwError('No files found'))),
                mergeMap((documents) => from(documents)),
                switchMap((document) =>
                    this.httpClient.get<SignedURLDownloadResponse>(
                        `${environment.edgeService}/documents/document/${document.id}`
                    )
                ),
                switchMap((res) =>
                    this.httpClient.get(res.url, { responseType: 'blob' as const }).pipe(
                        map((data) => {
                            return { data, name: res.name };
                        })
                    )
                ),

                toArray(),
                map((res) => {
                    const zip: JSZip = new JSZip();
                    for (const document of res) {
                        zip.file(document.name, document.data);
                        return zip;
                    }
                }),
                switchMap((zip) => from(zip.generateAsync({ type: 'blob' })))
            )
            .subscribe(
                (file) => {
                    const zipFilename = 'GMS Offer_' + contractName + '.zip';
                    saveAs(file, zipFilename);
                },
                (err) => console.error('An Error occured:', err)
            );
    }

    // USED IN CONTRACT EDIT COMPONENT
    public delete(ids: number[]) {
        let params = new HttpParams();
        ids.forEach((id) => (params = params.append('id', `${id}`)));
        return this.httpClient.delete(`${environment.edgeService}/documents/document`, {
            params
        });
    }

    // USED IN CONTRACT COMPONENTS
    public async uploadMultiple(files: File[], folderId: number) {
        return of(...files)
            .pipe(
                map((file) => {
                    const id = uuidv4();
                    const fileUpload = new Map(this._fileUpload.getValue());
                    fileUpload.set(id, { id, name: file.name });
                    this._fileUpload.next(fileUpload);
                    return { id, file };
                }),
                mergeMap(({ id, file }) => {
                    const payload: SignedURLUploadRequest = {
                        name: file.name,
                        contentType: file.type,
                        folderId
                    };
                    return this.httpClient.post<SignedURLUploadResponse>(`${this.documentUrl}/document`, payload).pipe(
                        map((resp) => {
                            return { resp, file, id };
                        })
                    );
                }),
                mergeMap(({ resp, file, id }) =>
                    this.httpClient
                        .put(resp.url, file, {
                            reportProgress: true,
                            observe: 'events',
                            responseType: 'json'
                        })
                        .pipe(
                            map((event) => {
                                switch (event.type) {
                                    case HttpEventType.Sent:
                                        this.updateUpload(id, HttpEventType.Sent);
                                        return false;
                                    case HttpEventType.ResponseHeader:
                                        this.updateUpload(id, HttpEventType.ResponseHeader);
                                        return false;
                                    case HttpEventType.UploadProgress:
                                        this.updateUpload(
                                            id,
                                            HttpEventType.ResponseHeader,
                                            Math.round((event.loaded / event.total) * 100)
                                        );
                                        return false;
                                    case HttpEventType.DownloadProgress:
                                        this.updateUpload(id, HttpEventType.DownloadProgress, 100);
                                        return false;
                                    case HttpEventType.Response:
                                        if (event.status === 200) {
                                            this.updateUpload(id, HttpEventType.Response, 100);
                                            return true;
                                        } else {
                                            this.updateUpload(id, HttpEventType.Response, 0);
                                            return false;
                                        }
                                }
                            }),
                            filter((finished) => finished),
                            map(() => {
                                return { resp, file, id };
                            })
                        )
                ),
                mergeMap(({ resp, id }) =>
                    this.httpClient
                        .post<Document>(`${this.documentUrl}/confirmUpload`, {
                            key: resp.key
                        })
                        .pipe(
                            map((document) => {
                                return { id, document };
                            })
                        )
                ),
                map(({ id, document }) => {
                    const fileUpload = new Map(this._fileUpload.getValue());
                    fileUpload.delete(id);
                    this._fileUpload.next(fileUpload);
                    return document;
                }),
                toArray()
            )
            .toPromise();
    }

    // USED IN CONTRACT EDIT COMPONENT
    public setInactive(id: number) {
        return this.httpClient.patch<Document>(`${environment.edgeService}/documents/document/${id}`, {
            archived: true
        });
    }

    public getDocumentsByProjectNumber(projectNumber: string) {
        return this.httpClient
            .get<any>(`${environment.edgeService}/documents/contracts/projects/${projectNumber}`, {})
            .toPromise();
    }

    public async convertDocumentToPdf(documentId: number, archived?: boolean) {
        return this.httpClient
            .post<Document>(`${environment.edgeService}/documents/document/${documentId}/convertToPdf`, { archived })
            .toPromise();
    }

    private updateUpload(id: string, status: HttpEventType, progress = 0) {
        const fileUpload = new Map(this._fileUpload.getValue());
        const upload = fileUpload.get(id);
        fileUpload.set(id, { ...upload, status, progress });
        this._fileUpload.next(fileUpload);
    }
}
