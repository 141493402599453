import { Inject, Injectable } from '@angular/core';
import { Holiday, HolidayByCountryCode, HolidayByCountryCodeDto, Location } from '@ceres/domain';
import { HttpParams } from '@angular/common/http';
import { ImpersonatedHttpClient } from '@ceres/shared/services';
import { ENVIRONMENT } from '@ceres/frontend-helper';
import { BehaviorSubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class HolidayService {
    private readonly holidays$$ = new BehaviorSubject<HolidayByCountryCode>({});
    public readonly holidays$ = this.holidays$$.asObservable();

    constructor(
        private httpClient: ImpersonatedHttpClient,
        @Inject(ENVIRONMENT) private readonly environment: { edgeService: string }
    ) {}

    getAllInYear(year?: number): Promise<HolidayByCountryCode> {
        return this.httpClient
            .get<HolidayByCountryCodeDto>(
                `${this.environment.edgeService}/holidays/year${year != null ? `/${year}` : ''}`
            )
            .pipe(
                map((data) =>
                    Object.entries(data).reduce((accumulator, [countryCode, holidays]) => {
                        return {
                            ...accumulator,
                            [countryCode]: new Set(holidays)
                        };
                    }, {} as HolidayByCountryCode)
                ),
                tap((data) => this.holidays$$.next(data))
            )
            .toPromise();
    }

    getByCountryCode(countryCode: string, year: number): Promise<string[]> {
        return this.httpClient
            .get<string[]>(`${this.environment.edgeService}/holidays/year/${year}/country-code`, {
                params: { countryCode }
            })
            .toPromise();
    }

    getAllManualHolidays(): Promise<Holiday[]> {
        return this.httpClient.get<Holiday[]>(`${this.environment.edgeService}/holidays/manual`).toPromise();
    }

    getManualHolidaysByLocation(location: Location): Promise<Holiday[]> {
        let httpParams = new HttpParams();
        Object.keys(location).forEach((key) => {
            httpParams = httpParams.append(key, location[key]);
        });

        return this.httpClient
            .get<Holiday[]>(`${this.environment.edgeService}/holidays/manualByLocation`, {
                params: httpParams
            })
            .toPromise();
    }

    getAllManualAndGeneratedByLocationHolidays(location: Location): Promise<Holiday[]> {
        let httpParams = new HttpParams();
        Object.keys(location).forEach((key) => {
            httpParams = httpParams.append(key, location[key]);
        });

        return this.httpClient
            .get<Holiday[]>(`${this.environment.edgeService}/holidays/admin`, {
                params: httpParams
            })
            .toPromise();
    }

    getAllByLocation(location: Location): Promise<Holiday[]> {
        let httpParams = new HttpParams();
        Object.keys(location).forEach((key) => {
            httpParams = httpParams.append(key, location[key]);
        });

        return this.httpClient
            .get<Holiday[]>(`${this.environment.edgeService}/holidays`, {
                params: httpParams
            })
            .toPromise();
    }

    create(holiday: Holiday) {
        return this.httpClient.post<Holiday[]>(`${this.environment.edgeService}/holidays`, holiday).toPromise();
    }

    update(holiday: Holiday) {
        return this.httpClient
            .patch<Holiday[]>(`${this.environment.edgeService}/holidays/${holiday.id}`, holiday)
            .toPromise();
    }

    delete(holiday: Holiday) {
        return this.httpClient.delete<Holiday[]>(`${this.environment.edgeService}/holidays/${holiday.id}`).toPromise();
    }
}
