import { Pipe, PipeTransform } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

@Pipe({
    name: 'formatNumberValue'
})
export class FormatNumberValuesPipe implements PipeTransform {
    constructor(private translate: TranslocoService) {}

    transform(value: number): number {
        const roundedUpValue = Math.round(value * 100) / 100;

        return roundedUpValue;
    }
}
