<div class="row list-container mat-card" [hidden]="projectChartService.loading$ | async">
    <div class="col-12 mb-2">
        <h3>{{ 'projects.order-vs-billing' | transloco }}</h3>
    </div>
    @if (orderEntryVsBillingEntry) {
        <div class="col-12">
            <ngx-charts-bar-vertical
                [view]="view"
                [roundEdges]="false"
                [scheme]="colorScheme"
                [results]="orderEntryVsBillingEntry"
                [gradient]="gradient"
                [xAxis]="showXAxis"
                [yAxis]="showYAxis"
                [legend]="showLegend"
                [showXAxisLabel]="showXAxisLabel"
                [showYAxisLabel]="showYAxisLabel"
                [xAxisLabel]="xAxisLabel"
                [yAxisLabel]="yAxisLabel"
            >
            </ngx-charts-bar-vertical>
        </div>
    }
</div>
@if (projectChartService.loading$ | async) {
    <ceres-loading></ceres-loading>
}
