import { Component, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Document } from '@ceres/domain';

@Component({
    selector: 'ceres-file-upload',
    templateUrl: './file-upload.component.html',
    styleUrls: ['./file-upload.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class FileUploadComponent {
    @Input() activeDocuments: Document[] = [];
    @Input() isNewItem = false;
    @Input() archiving = true;
    @Input() validFileTypes: string[] | undefined;

    @Output() changed = new EventEmitter<any>();

    newDocDataSource: MatTableDataSource<any>;
    dndFiles: File[] = [];
    deleteNames: Set<string> = new Set();
    showWarningArchive = false;
    showWarningDelete = false;
    validDrag: any;

    displayedColumnsDataNew = ['select', 'name', 'delete'];

    deleteSelected() {
        this.dndFiles = this.dndFiles.filter((dndFile) => !this.deleteNames.has(dndFile.name));
        this.changedFile();
    }

    changeDeleteNameStatus(name: string) {
        if (this.deleteNames.has(name)) {
            this.deleteNames.delete(name);
        } else {
            this.deleteNames.add(name);
        }
    }

    changedFile() {
        if (!this.isNewItem) {
            this.showWarningArchive = false;
            this.showWarningDelete = false;

            this.dndFiles.forEach((newDoc: File) => {
                const i = this.activeDocuments.findIndex((doc: Document) => doc.name === newDoc.name);
                if (i > -1) {
                    !this.archiving ? (this.showWarningDelete = true) : (this.showWarningArchive = true);
                }
            });
        }
        this.newDocDataSource = new MatTableDataSource(this.dndFiles);
        this.deleteNames = this.dndFiles.reduce((acc, curr) => {
            if (this.deleteNames.has(curr.name)) {
                acc.add(curr.name);
            }
            return acc;
        }, new Set<string>());
        this.changed.emit(this.dndFiles);
    }

    cancelNewDocument(index: number) {
        this.dndFiles.splice(index, 1);
        this.changedFile();
    }

    protected get allowedFileTypes(): string {
        if (this.validFileTypes) {
            return this.validFileTypes.join(', ');
        }
        return '';
    }
}
