import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatStepperModule } from '@angular/material/stepper';
import { TranslocoModule } from '@ngneat/transloco';
import { ButtonModule } from '../button/button.module';
import { WizardComponent } from './wizard.component';
import { WizardFormDirective } from './wizard-form.directive';

@NgModule({
    declarations: [WizardComponent, WizardFormDirective],
    imports: [CommonModule, ButtonModule, MatStepperModule, TranslocoModule],
    exports: [WizardComponent, WizardFormDirective]
})
export class WizardModule {}
