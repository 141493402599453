import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AppUserService } from '@ceres/shared/services';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class RolePermissionGuard implements CanActivate {
    constructor(
        private appUserService: AppUserService,
        private router: Router
    ) {}
    canActivate(next: ActivatedRouteSnapshot): Observable<boolean> {
        return this.appUserService.permissions$.pipe(
            map((permissions) => {
                if (!next.data.permission || !permissions) {
                    return false;
                }

                const isArray = Array.isArray(next.data.permission);
                if (isArray) {
                    let hasPermission = false;
                    for (const permission of next.data.permission) {
                        if (permissions.has(permission)) {
                            hasPermission = true;
                            break;
                        }
                    }

                    return hasPermission;
                } else {
                    return permissions.has(next.data.permission);
                }
            })
        );
    }
}
