import { BVIMapperEnum } from '../enums/bvi-mapper.enum';

export const markedForDeletionDeadline = 14;

export const DIFA_DEV = 'DEV - DI FA S SUP';
export const DIFA = 'DI FA S SUP';
export const DI_CS = 'DI CS FA DIL';
export const PO = 'P&O ECO';

// prettier-ignore
export const ExcelFileServiceNameMapper = [
  { fileName: 'CONLOG_TRX', serviceNamePart: 'CONLOG transactional', mapper: BVIMapperEnum.CON_LOG, ignore: true },
  { fileName: 'CONLOG', serviceNamePart: 'CONLOG', mapper: BVIMapperEnum.CON_LOG },
  { fileName: 'CONTROLPAY_DI', serviceNamePart: 'Control Pay DI', mapper: BVIMapperEnum.CONTROL_PAY_DI },
  { fileName: 'CONTROLPAY_FL', serviceNamePart: 'Control Pay Flender', mapper: BVIMapperEnum.CONTROL_PAY_FLENDER },
  { fileName: 'EBYDOS_FC', serviceNamePart: 'Ebydos Factual Check', mapper: BVIMapperEnum.EBYDOS_SE , ignore: true },
  { fileName: 'EBYDOS_WEBCYCLE', serviceNamePart: 'Ebydos Webcycle (Ebydos DI)', mapper: BVIMapperEnum.EBYDOS_DI },
  { fileName: 'FREIA_SIFIT', serviceNamePart: 'FREIA & SIFIT', mapper: BVIMapperEnum.FREIA },
  { fileName: 'LP2_AIDA', serviceNamePart: 'LP2 & AIDA', mapper: BVIMapperEnum.LP2 },
  { fileName: 'PEPS', serviceNamePart: 'PEPS (DIY, ELAN, PAPAN)', mapper: BVIMapperEnum.PEPS },
  { fileName: 'SITRIS', serviceNamePart: 'SITRIS', mapper: BVIMapperEnum.SITRIS },
  { fileName: 'LEXA_CHARGING', serviceNamePart: 'LEXA-charging', mapper: BVIMapperEnum.LEXA, ignore: true },
  { fileName: 'LEXA', serviceNamePart: 'Lexa', mapper: BVIMapperEnum.LEXA, ignore: true }
];

export const AllowedFileTypes = {
    EXCEL: [
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
        'application/msexcel',
        'application/vnd.ms-excel.sheet.macroenabled.12',
        'application/vnd.ms-excel.template.macroenabled.12',
        'application/vnd.ms-excel.addin.macroenabled.12',
        'application/vnd.ms-excel.sheet.binary.macroenabled.12'
    ],
    EXCEL_PURE: ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'],
    WORD: [
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
        'application/vnd.ms-word.document.macroEnabled.12',
        'application/vnd.ms-word.template.macroEnabled.12'
    ],
    POWER_POINT: [
        'application/vnd.ms-powerpoint',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        'application/vnd.openxmlformats-officedocument.presentationml.template',
        'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
        'application/vnd.ms-powerpoint.addin.macroEnabled.12',
        'application/vnd.ms-powerpoint.presentation.macroEnabled.12',
        'application/vnd.ms-powerpoint.template.macroEnabled.12',
        'application/vnd.ms-powerpoint.slideshow.macroEnabled.12'
    ],
    CSV: ['text/csv'],
    PDF: ['application/pdf']
};
