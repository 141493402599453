import { Portfolio } from './portfolio';
import { BusinessArea } from './business-area';
import { TranslationOption, TranslationOptionContractCurrency } from './translation-option';
import { ContractStatus } from './contract';

export interface ContractMetadata {
    lookupValues: ContractLookupValues;
}

export interface ContractLookupValues {
    units: Unit[];
    status: ContractStatus[];
    contractTypes: TranslationOption[];
    currencies: TranslationOptionContractCurrency[];
    cissData: CissData[];
    businessAreas: BusinessArea[];
    portfolios: Portfolio[];
}

export class CissData {
    id!: number;
    title!: string;
    are!: string;
    country!: string;
    longName!: string;
    managementResponsibility!: string;
}

export class Unit {
    id!: number;
    title!: string;
}
