import { Injectable } from '@angular/core';
import { Technology } from '@ceres/domain';
import { ImpersonatedHttpClient } from '@ceres/shared/services';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class TechnologiesService {
    constructor(private httpClient: ImpersonatedHttpClient) {}

    async getAll(): Promise<Technology[]> {
        return await this.httpClient.get<Technology[]>(`${environment.edgeService}/technologies`).toPromise();
    }

    public async getFirstInfo(results: Technology[], item: Technology) {
        const parentItem = results.find((e) => e.id === item.parent);
        if (parentItem && parentItem.parent) {
            return await this.getFirstInfo(results, parentItem);
        }
        return parentItem.title;
    }
}
