<div cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane" class="row dialog-header">
    <div class="col-11 left">
        <h4>{{ 'projects.general.select' | transloco }}</h4>
    </div>
    <div class="col-1 right">
        <button (click)="dialogRef.close()">
            <i class="a-icon a-icon--close"></i>
        </button>
    </div>
</div>
<div class="row mb-3">
    <div class="col-6">
        <div class="pwo-input">
            <input placeholder="{{ 'shared.miscellaneous.search-dots' | transloco }}" [formControl]="searchControl" />
            @if (searchControl.value && searchControl.value !== '') {
                <button aria-label="Clear" mat-button mat-icon-button matSuffix (click)="searchControl.reset()">
                    <i class="material-icons">close</i>
                </button>
            }
        </div>
    </div>
</div>
<div class="row main-content" [hidden]="projectSelectionService.loading$ | async">
    <div class="col-12">
        <div class="table-container">
            <mat-table
                [dataSource]="projectSelectionService.projectProfiles$"
                matSort
                (matSortChange)="sortChanged($event)"
            >
                <ng-container matColumnDef="selected">
                    <mat-header-cell *matHeaderCellDef>
                        @if (multiple) {
                            @if (!fewSelected) {
                                <mat-checkbox (change)="selectAll()" [(ngModel)]="allChecked"></mat-checkbox>
                            }
                            @if (fewSelected) {
                                <mat-checkbox (change)="selectAll()" class="mat-checkbox-semichecked"></mat-checkbox>
                            }
                        }
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <mat-checkbox
                            (change)="changeSelected(element, $event)"
                            [checked]="element | projectSelected: selectedProjects"
                        ></mat-checkbox>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="mpNumber">
                    <mat-header-cell
                        filter
                        *matHeaderCellDef
                        [sort]="true"
                        name="{{ 'projects.project-properties.number' | transloco }}"
                        [filter]="filterService.getColumnFilters('mpNumber')"
                        [filterService]="filterService"
                        column="mpNumber"
                    ></mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        {{ element.mpNumber }}
                    </mat-cell>
                </ng-container>

                <!-- MPTitle Column -->
                <ng-container matColumnDef="mpTitle">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ 'projects.project-properties.title' | transloco }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <a class="a-text-link-content" routerLink="/projects/details/{{ element.id }}">{{
                            element.mpTitle
                        }}</a>
                    </mat-cell>
                </ng-container>

                <!-- ProjektGruppierung Column -->
                <ng-container matColumnDef="projektGruppierung">
                    <mat-header-cell
                        filter
                        *matHeaderCellDef
                        [sort]="true"
                        name="{{ 'projects.project-properties.project-group' | transloco }}"
                        [filter]="filterService.getColumnFilters('projektGruppierung')"
                        [filterService]="filterService"
                        column="projektGruppierung"
                    ></mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        {{ element.projektGruppierung }}
                    </mat-cell>
                </ng-container>

                <!-- Project Lead Column -->
                <ng-container matColumnDef="projectLead.name">
                    <mat-header-cell
                        filter
                        *matHeaderCellDef
                        [sort]="true"
                        name="{{ 'projects.project-properties.project-lead' | transloco }}"
                        [filter]="filterService.getColumnFilters('projectLead.name')"
                        [filterService]="filterService"
                        column="projectLead.name"
                    ></mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        {{ element.projectLead.name }}
                    </mat-cell>
                </ng-container>

                <!-- Business Partner Column-->
                <ng-container matColumnDef="businessPartner.fullName">
                    <mat-header-cell
                        filter
                        *matHeaderCellDef
                        [sort]="true"
                        name="{{ 'shared.properties.customer' | transloco }}"
                        [filter]="filterService.getColumnFilters('businessPartner.fullName')"
                        [filterService]="filterService"
                        column="businessPartner.fullName"
                    ></mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        {{ element.businessPartner.fullName }}
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
            </mat-table>
        </div>
        @if (projectSelectionService.total$ | async; as total) {
            <mat-paginator
                [length]="total"
                [pageSizeOptions]="[15, 30, 50, total]"
                [pageSize]="projectSelectionService.pageSize"
                [pageIndex]="projectSelectionService.pageIndex"
                (page)="pageChanged($event)"
            ></mat-paginator>
        }
    </div>
</div>
@if (projectSelectionService.loading$ | async) {
    <ceres-loading></ceres-loading>
}
<div class="row mb-3">
    <div class="col-12">
        <div class="d-flex gap-10">
            <ceres-button (click)="closeDialog()" icon="check-mark" type="button">
                {{ 'shared.buttons.apply' | transloco }}
            </ceres-button>
            <ceres-button (click)="dialogRef.close()" icon="close" type="button">
                {{ 'shared.buttons.cancel' | transloco }}
            </ceres-button>
        </div>
    </div>
</div>
