import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'brakets'
})
export class BraketsPipe implements PipeTransform {
    transform(value: any): any {
        return value.replace(/\[.*\]/g, '');
    }
}
