import {
    AccountingData as CeresAccountingData,
    BusinessPartner,
    MaterialMaster,
    AccountingElement,
    Contract,
    Employee
} from '@ceres/domain';

export class AccountingData implements CeresAccountingData {
    Id: number;
    AbgerechneterWert: number;
    Abzurechnen: number;
    Anmerkung: string;
    AnmerkungKFM: string;
    Auftragsbestand: number;
    Auftragswert: number;
    Bestellnummer: string;
    bestellnummerText: string;
    bestellNummerExistent: boolean;
    Bestellposition: string;
    FreigabeKfm: boolean;
    FreigabePL: boolean;
    FSGGesamtprojekt: number;
    FSGGesamtprojektC: number;
    Istkosten: number;
    Istpreis: number;
    KostenstelleGMS: string;
    Kundenart: string;
    LeistungszeitraumBis: Date;
    LeistungszeitraumVon: Date;
    mpNumber: string;
    mpTitle: string;
    Plankosten: number;
    Planpreis: number;
    PoCCV: number;
    projectLead: Employee;
    ProjektGruppierung: string;
    Vorschlpreis: number;
    Verrechnungsart: string;
    WertVerrechnung: number;
    AccountingRelevance: string | number;
    forecastFY: number;
    forecastExternalCostsFY: number;
    plannedExternalCosts: number;
    OrgID: string;
    Verrechnungsland: string;
    IstkostenStunden: number;
    IstpreisStunden: number;
    IstkostenExtern: number;
    IstpreisExtern: number;
    verrechnungszyklus: string;
    businessPartner: BusinessPartner;
    contract: Contract;
    MaterialMaster: MaterialMaster;
    Abgerechnet: boolean;
    OrderPosNr: number;
    accountingElement: AccountingElement;
    OrderQuantity: number;
    pspElement: string;
    partnerDepthStructure: string;

    constructor(object?: Partial<AccountingData>) {
        if (object) {
            Object.assign(this, object);
        }
    }
}
