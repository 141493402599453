import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
    selector: 'ceres-search-field',
    templateUrl: './search-field.component.html',
    styleUrls: ['./search-field.component.scss']
})
export class SearchFieldComponent implements OnInit {
    @Input() dataSource: MatTableDataSource<object> = new MatTableDataSource();
    @Input() isStyleModern = false;
    @Input() fixMargin = false;

    inputQueryChanged: Subject<MatTableDataSource<object>> = new Subject<MatTableDataSource<object>>();
    filter = '';

    ngOnInit() {
        this.inputQueryChanged.pipe(debounceTime(750), distinctUntilChanged()).subscribe(() => {
            this.dataSource.filter = String(this.filter);
        });
        this.dataSource.filterPredicate = (data, filter) => {
            const dataToFilter = JSON.stringify(data);
            if (dataToFilter && filter) {
                return dataToFilter.toLowerCase().includes(filter.toLowerCase());
            }
            return false;
        };
    }

    public resetFilter() {
        this.dataSource.filter = '';
        this.filter = '';
    }

    changed(event: MatTableDataSource<object>) {
        this.inputQueryChanged.next(event);
    }
}
