<div [matMenuTriggerFor]="menu" [ngClass]="{ triggered: triggered }" class="trigger" type="button">
    <i class="material-icons">tune</i>
</div>
<mat-menu class="ms-menu" #menu="matMenu">
    <div class="top-button-container">
        <button (click)="markAllColumns(true, $event)" class="a-button a-button--pwo" type="button">
            {{ 'shared.buttons.select-all' | transloco }}
        </button>
        <button (click)="markAllColumns(false, $event)" class="a-button a-button--cancel" type="button">
            {{ 'shared.buttons.delete' | transloco }}
        </button>
    </div>
    <div class="multi-select-content" (click)="preventClose($event)">
        @for (col of itemsList; track col?.title) {
            <div>
                <mat-checkbox [(ngModel)]="col.selected">{{ translationPrefix + col.title | transloco }}</mat-checkbox>
            </div>
        }
    </div>
    <div class="bottom-button-container">
        <button (click)="applyColumns()" class="a-button a-button--pwo full a-icon a-icon--check-mark" type="button">
            {{ 'shared.buttons.apply' | transloco }}
        </button>
    </div>
</mat-menu>
