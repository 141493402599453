<div class="row dialog-header" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <div class="col-11 left">
        <h4>{{ 'menu.add-news' | transloco }}</h4>
    </div>
    <div class="col-1 right">
        <button (click)="dialogRef.close()">
            <i class="a-icon a-icon--close"></i>
        </button>
    </div>
</div>
<div class="row">
    <mat-form-field appearance="fill" class="col-4">
        <mat-label>{{ 'admin.edit-news.critical' | transloco }}</mat-label>
        <mat-select [(ngModel)]="draftItem.critical" placeholder="{{ 'admin.edit-news.critical' | transloco }}">
            <mat-option [value]="true">{{ 'admin.edit-news.critical' | transloco }}</mat-option>
            <mat-option [value]="false">{{ 'admin.edit-news.not-critical' | transloco }}</mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill" class="col-4">
        <mat-label>{{ 'admin.edit-news.target-group' | transloco }}</mat-label>
        <mat-select
            (selectionChange)="draftItem.targetGroup = null; draftItem.targetTeam = null"
            [(ngModel)]="draftItem.target"
            [disabled]="!appUserService.isAdmin()"
            placeholder="{{ 'admin.edit-news.target-group' | transloco }}"
        >
            <mat-option value="All">{{ 'shared.miscellaneous.all' | transloco }}</mat-option>
            <mat-option value="Group">{{ 'shared.miscellaneous.group' | transloco }}</mat-option>
            <mat-option value="Team">{{ 'shared.miscellaneous.team' | transloco }}</mat-option>
        </mat-select>
    </mat-form-field>
    @if (draftItem.target === 'Group') {
        <mat-form-field appearance="fill" class="col-4">
            <mat-label>{{ 'shared.miscellaneous.group' | transloco }}</mat-label>
            <mat-select
                [(ngModel)]="draftItem.targetGroup"
                [compareWith]="compareLookups"
                [disabled]="!appUserService.isAdmin()"
                placeholder="{{ 'shared.miscellaneous.group' | transloco }}"
            >
                @for (item of portfolios; track item) {
                    <mat-option [value]="item">{{ item.title }}</mat-option>
                }
            </mat-select>
        </mat-form-field>
    }
    @if (draftItem.target === 'Team') {
        <mat-form-field appearance="fill" class="col-4">
            <mat-label>{{ 'shared.miscellaneous.team' | transloco }}</mat-label>
            <mat-select
                [(ngModel)]="draftItem.targetTeam"
                [compareWith]="compareLookups"
                [disabled]="!appUserService.isAdmin()"
                placeholder="{{ 'shared.miscellaneous.team' | transloco }}"
            >
                @for (item of businessAreas; track item) {
                    <mat-option [value]="item">{{ item.longName ? item.longName : item.name }}</mat-option>
                }
            </mat-select>
        </mat-form-field>
    }
</div>
<div class="row">
    <mat-form-field appearance="fill" class="col-4">
        <mat-label>{{ 'admin.edit-news.priority' | transloco }}</mat-label>
        <mat-select [(ngModel)]="draftItem.priority" placeholder="{{ 'admin.edit-news.priority' | transloco }}">
            <mat-option [value]="0">{{ 'admin.edit-news.priority' | transloco }} 0</mat-option>
            <mat-option [value]="1">{{ 'admin.edit-news.priority' | transloco }} 1</mat-option>
            <mat-option [value]="2">{{ 'admin.edit-news.priority' | transloco }} 2</mat-option>
            <mat-option [value]="3">{{ 'admin.edit-news.priority' | transloco }} 3</mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill" class="col-4">
        <mat-label>{{ 'shared.properties.valid-to' | transloco }}</mat-label>
        <input
            [(ngModel)]="draftItem.validTo"
            [matDatepicker]="date"
            matInput
            placeholder="{{ 'shared.properties.valid-to' | transloco }}"
        />
        <mat-datepicker-toggle [for]="date" matSuffix></mat-datepicker-toggle>
        <mat-datepicker #date></mat-datepicker>
    </mat-form-field>
</div>
<div class="row">
    <div class="col-12 mb-2">
        <mat-card class="news-tile" [ngClass]="{ critical: draftItem.critical, info: !draftItem.critical }">
            <mat-card-content>
                <table>
                    <tr>
                        <td id="news-img">
                            <div>
                                <mat-icon>{{ draftItem.critical ? 'flash_on' : 'info' }}</mat-icon>
                            </div>
                        </td>

                        <td class="row">
                            <mat-form-field appearance="fill" class="col-6">
                                <mat-label>{{ 'admin.edit-news.news-title' | transloco }}</mat-label>
                                <input matInput [(ngModel)]="draftItem.title" />
                            </mat-form-field>
                            <mat-form-field appearance="fill" class="col-12">
                                <mat-label>{{ 'admin.edit-news.news-content' | transloco }}</mat-label>
                                <textarea
                                    cdkTextareaAutosize
                                    #autosize="cdkTextareaAutosize"
                                    cdkAutosizeMinRows="2"
                                    cdkAutosizeMaxRows="10"
                                    [(ngModel)]="draftItem.content"
                                    matInput
                                ></textarea>
                            </mat-form-field>
                        </td>
                    </tr>
                </table>
            </mat-card-content>
        </mat-card>
    </div>
</div>
<div class="row">
    <div class="col-12 mb-2">
        <div class="d-flex justify-content-end gap-10">
            <ceres-button (click)="save()" [disabled]="checkDisability()" icon="check-mark" type="button">{{
                'shared.buttons.save' | transloco
            }}</ceres-button>
        </div>
    </div>
</div>
