@if (formGroup) {
    <form [formGroup]="formGroup" (ngSubmit)="save()">
        <div class="row top-row">
            <div class="col-md-5 header mb-xl-4 mb-lg-4 mb-md-4">
                <div class="header-title">
                    <h3 class="main-title">{{ 'bop.buttons.create-bop' | transloco }}</h3>
                    <p class="description">{{ 'shared.miscellaneous.user-change-values' | transloco }}</p>
                </div>
            </div>
            <div class="col-md-7 my-2 mb-2 mb-sm-3">
                <div class="d-flex flex-wrap justify-content-start justify-content-sm-end gap-10">
                    <ceres-button icon="check-mark" type="submit">{{ 'shared.buttons.save' | transloco }}</ceres-button>
                    <ceres-button icon="close" [style]="'primary'" (click)="close()" type="button">{{
                        'shared.buttons.cancel' | transloco
                    }}</ceres-button>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6 mb-4 form-container">
                <div class="options-form options-form--header">
                    <div class="row">
                        <div class="col-12 mb-2">
                            <p>{{ 'shared.miscellaneous.general-information' | transloco }}</p>
                        </div>
                    </div>
                </div>
                <div class="options-form">
                    <div class="row">
                        <div class="col-sm-6 mb-2">
                            <mat-form-field class="mat-custom" appearance="fill">
                                <mat-label>{{ 'bop.properties.number' | transloco }}</mat-label>
                                <input matInput formControlName="bopNumber" required />
                            </mat-form-field>
                        </div>
                        <div class="col-sm-6 mb-2">
                            <mat-form-field class="mat-custom" appearance="fill">
                                <mat-label>{{ 'bop.properties.title' | transloco }}</mat-label>
                                <input matInput formControlName="bopTitle" required />
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 mb-2">
                            <ceres-user-picker
                                (selected)="changeBopLeader($event)"
                                [appUser]="true"
                                [condition]="pickerCondition('bopLeader')"
                                [fullUser]="true"
                                [required]="true"
                                [value]="formGroup.value.bopLeader"
                                [noCheck]="true"
                                placeholder="{{ 'bop.properties.responsible' | transloco }}"
                            >
                            </ceres-user-picker>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6 mb-3">
                            <mat-form-field class="mat-custom" appearance="fill">
                                <mat-label>{{ 'shared.properties.business-area' | transloco }}</mat-label>
                                <mat-select [compareWith]="compareLookups" formControlName="businessArea" required>
                                    <mat-option>
                                        <ngx-mat-select-search
                                            [placeholderLabel]="'shared.miscellaneous.search-dots' | transloco"
                                            [formControl]="businessAreasFilterCtrl"
                                        ></ngx-mat-select-search>
                                    </mat-option>
                                    @for (item of businessAreasOptions | async | orderBy: item; track item) {
                                        <mat-option [value]="item"
                                            >{{ item.longName ? item.longName : item.name }}
                                        </mat-option>
                                    }
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6 mb-2">
                            <mat-form-field class="mat-custom" appearance="fill">
                                <mat-label>{{
                                    'projects.project-detail.information.planned-project-start' | transloco
                                }}</mat-label>
                                <input
                                    [matDatepicker]="startDate"
                                    formControlName="plannedProjectStart"
                                    matInput
                                    (ngModelChange)="recalculateDates(false)"
                                    [min]="fiscalYearStart"
                                    required
                                />
                                <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
                                <mat-datepicker #startDate></mat-datepicker>
                            </mat-form-field>
                            @if (startDateWarning) {
                                <span class="date-warning">{{ 'shared.properties.date-warning' | transloco }}</span>
                            }
                        </div>
                        <div class="col-sm-6 mb-2">
                            <mat-form-field class="mat-custom" appearance="fill">
                                <mat-label>{{
                                    'projects.project-detail.information.planned-project-end' | transloco
                                }}</mat-label>
                                <input
                                    [matDatepicker]="endDate"
                                    formControlName="plannedProjectEnd"
                                    matInput
                                    (ngModelChange)="recalculateDates(true)"
                                    [min]="fiscalYearStart"
                                    required
                                />
                                <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
                                <mat-datepicker #endDate></mat-datepicker>
                            </mat-form-field>
                            @if (endDateWarning) {
                                <span class="date-warning">{{ 'shared.properties.date-warning' | transloco }}</span>
                            }
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-6">
                            <mat-form-field class="mat-custom" appearance="fill">
                                <mat-label>{{ 'bop.properties.planned-duration' | transloco }}</mat-label>
                                <input [value]="plannedProjectDuration" matInput readonly type="number" />
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 mb-2">
                            <mat-form-field class="mat-custom" appearance="fill">
                                <mat-label>{{ 'shared.miscellaneous.content' | transloco }}</mat-label>
                                <textarea formControlName="content" matInput type="text"></textarea>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="options-form options-form--header">
                    <div class="row">
                        <div class="col-12 mb-2">
                            <p>{{ 'businesspartner.general.title' | transloco }}</p>
                        </div>
                    </div>
                </div>
                <div class="options-form">
                    <div class="row">
                        <div class="col-sm-6 mb-2">
                            <ceres-user-picker
                                (selected)="changeBusinessPartner($event)"
                                [appUser]="true"
                                [businessPartner]="true"
                                [condition]="pickerCondition('businessPartner')"
                                [required]="true"
                                [value]="formGroup.value.businessPartner"
                                [noCheck]="true"
                                placeholder="{{ 'businesspartner.general.title' | transloco }}"
                            ></ceres-user-picker>
                        </div>
                        <div class="col-sm-6 mb-2">
                            <mat-form-field class="mat-custom" appearance="fill">
                                <mat-label>{{ 'businesspartner.properties.phone' | transloco }}</mat-label>
                                <input [value]="formGroup.value.businessPartner?.phone || ''" disabled matInput />
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6 mb-2">
                            <mat-form-field class="mat-custom" appearance="fill">
                                <mat-label>{{ 'businesspartner.properties.company-name' | transloco }}</mat-label>
                                <input
                                    [value]="
                                        formGroup.value.businessPartner?.companyNames?.length
                                            ? formGroup.value.businessPartner.companyNames[0].name
                                            : ''
                                    "
                                    disabled
                                    matInput
                                />
                            </mat-form-field>
                        </div>
                        <div class="col-sm-6 mb-2">
                            <mat-form-field class="mat-custom" appearance="fill">
                                <mat-label>{{ 'shared.properties.department' | transloco }}</mat-label>
                                <input [value]="formGroup.value.businessPartner?.department || ''" disabled matInput />
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 form-container">
                <div class="options-form options-form--header">
                    <div class="row">
                        <div class="col-12 mb-2">
                            <p>{{ 'offers.properties.information' | transloco }}</p>
                        </div>
                    </div>
                </div>
                <div class="options-form">
                    <div class="row">
                        <div class="col-sm-6 mb-2">
                            <mat-form-field class="mat-custom" appearance="fill">
                                <mat-label>{{ 'offers.properties.title' | transloco }}</mat-label>
                                <input matInput formControlName="offerTitle" />
                            </mat-form-field>
                        </div>
                        <div class="col-sm-6 mb-2">
                            <mat-form-field class="mat-custom" appearance="fill">
                                <mat-label>{{ 'offers.properties.date' | transloco }}</mat-label>
                                <input [matDatepicker]="offerDate" formControlName="offerDate" matInput />
                                <mat-datepicker-toggle matSuffix [for]="offerDate"></mat-datepicker-toggle>
                                <mat-datepicker #offerDate></mat-datepicker>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6">
                            <mat-form-field class="mat-custom" appearance="fill">
                                <mat-label>{{ 'bop.properties.status' | transloco }}</mat-label>
                                <mat-select formControlName="bopStatus" required>
                                    @for (item of bopStatus | translateOrder; track item) {
                                        <mat-option [value]="item">{{ item.translationKey | transloco }}</mat-option>
                                    }
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-sm-6">
                            <mat-form-field class="mat-custom" appearance="fill">
                                <mat-label>{{ 'bop.properties.probability-class' | transloco }}</mat-label>
                                <mat-select formControlName="probabilityClass" required>
                                    @for (item of probabilityClasses | translateOrder; track item) {
                                        <mat-option [value]="item">{{ item.translationKey | transloco }} </mat-option>
                                    }
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6 mb-3">
                            <mat-form-field class="mat-custom" appearance="fill">
                                <mat-label>{{ 'bop.properties.declined-reason.title' | transloco }}</mat-label>
                                <mat-select
                                    [required]="formGroup.value.bopStatus === 3"
                                    formControlName="declineReason"
                                >
                                    @for (item of declineReasons | translateOrder; track item) {
                                        <mat-option [value]="item">{{ item.translationKey | transloco }} </mat-option>
                                    }
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 mb-2">
                            <mat-form-field class="mat-custom" appearance="fill">
                                <mat-label>{{ 'bop.properties.decline-comment' | transloco }}</mat-label>
                                <textarea formControlName="declineComment" matInput type="text"></textarea>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="options-form options-form--header">
                    <div class="row">
                        <div class="col-12 mb-2">
                            <p>{{ 'bop.properties.volume' | transloco }}</p>
                        </div>
                    </div>
                </div>
                <div class="options-form">
                    <div class="row">
                        <div class="col-sm-6 mb-2">
                            <mat-form-field class="mat-custom" appearance="fill">
                                <mat-label>{{ 'bop.properties.charging-volume-own' | transloco }}</mat-label>
                                <input
                                    formControlName="chargeVolumeOwn"
                                    matInput
                                    min="0"
                                    oninput="validity.valid||(value=value.replace(/[^\d.,]/g,''));"
                                    type="number"
                                />
                            </mat-form-field>
                        </div>
                        <div class="col-sm-6 mb-2">
                            <mat-form-field class="mat-custom" appearance="fill">
                                <mat-label>{{ 'bop.properties.charging-volume-others' | transloco }}</mat-label>
                                <input
                                    formControlName="chargeVolumeOthers"
                                    matInput
                                    min="0"
                                    oninput="validity.valid||(value=value.replace(/[^\d.,]/g,''));"
                                    type="number"
                                />
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6 mb-2">
                            <mat-form-field class="mat-custom" appearance="fill">
                                <mat-label>{{ 'bop.properties.material-costs' | transloco }}</mat-label>
                                <input
                                    formControlName="materialCosts"
                                    matInput
                                    type="number"
                                    matTooltip="{{ 'bop.tooltips.material-cost' | transloco }}"
                                    matTooltipClass="pwo-tooltip"
                                />
                            </mat-form-field>
                        </div>
                        <div class="col-sm-6 mb-2">
                            <mat-form-field class="mat-custom" appearance="fill">
                                <mat-label>{{ 'bop.properties.total-volume-in-euro' | transloco }}</mat-label>
                                <input [value]="totalVolume | number: '1.2-2' : 'de'" matInput readonly />
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6 mb-2">
                            <mat-form-field class="mat-custom" appearance="fill">
                                <mat-label>{{ 'bop.properties.total-probability' | transloco }}</mat-label>
                                <input [value]="totalProbability" matInput readonly type="number" />
                            </mat-form-field>
                        </div>
                        <div class="col-sm-6 mb-2">
                            <mat-form-field class="mat-custom" appearance="fill">
                                <mat-label>{{ 'bop.properties.expected-value' | transloco }}</mat-label>
                                <input [value]="expectedValue | number: '1.2-2' : 'de'" matInput readonly />
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
}
@if (!formGroup) {
    <ceres-loading></ceres-loading>
}
