export interface RoleAdminResponse {
    roles: RolePermission[];
    allPermissions: string[];
}

export interface RolePermission {
    id: number;
    name: string;
    permissions: PermissionMap;
    isEditable: boolean;
}

interface PermissionMap {
    [index: string]: boolean;
}
