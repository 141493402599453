import { Injectable, Injector } from '@angular/core';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { ImpersonationHttpHandler } from './impersonation-http-handler';

@Injectable({
    providedIn: 'root'
})
export class ImpersonatedHttpClient extends HttpClient {
    constructor(backend: HttpBackend, injector: Injector) {
        super(new ImpersonationHttpHandler(backend, injector));
    }
}
