@if (filterValues) {
    <div class="pwo-filter filter-values">
        <span>{{ filterValues.min }}</span>
        <span class="line"></span>
        <span>{{ filterValues.max }}</span>
    </div>
    <div #slider class="pwo-filter slider-container" (mousedown)="start($event)">
        <div class="slider" [style.background]="getSliderBackground()">
            <div class="thumb min" [style.left.%]="selection.min" [matTooltip]="min">
                <!-- <span>{{ min }}</span> -->
            </div>
            <div class="thumb max" [style.left.%]="selection.max" [matTooltip]="max">
                <!-- <span>{{ max }}</span> -->
            </div>
        </div>
    </div>
    <button (click)="apply()" class="a-button a-button--pwo full" type="button" mat-button>
        <mat-icon>check</mat-icon>
        {{ 'shared.buttons.apply' | transloco }}
    </button>
}

@if (!filterValues) {
    <div class="loader">
        <mat-spinner diameter="60"></mat-spinner>
    </div>
}
