import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface ConfirmDialogData {
    title: string;
    infoText?: string;
    buttons: {
        confirm: string;
        cancel: string;
    };
}

@Component({
    selector: 'ceres-select-modal',
    templateUrl: './confirm-modal.component.html',
    styleUrls: ['./confirm-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmModalComponent {
    constructor(
        private readonly dialogRef: MatDialogRef<ConfirmModalComponent, boolean>,
        @Inject(MAT_DIALOG_DATA) public readonly data: ConfirmDialogData
    ) {}

    confirm() {
        this.dialogRef.close(true);
    }

    cancel() {
        this.dialogRef.close(false);
    }
}
