import { RolePermission } from './role-admin-response';

// TODO check which properties are needed when
export interface Role {
    id: number;
    name: RoleType;
    translationKey?: string;
    isMerchant?: boolean;
    isLead?: boolean;
    isAdmin?: boolean;
    isGroupLead?: boolean;
    isTeamMember?: boolean;
    isGuest?: boolean;
    isHoursTracker?: boolean;
    permissions?: RolePermission[];
}

export enum RoleType {
    Admin = 'Admin',
    TeamMember = 'TeamMember',
    GroupLeader = 'GroupLeader',
    TeamLeader = 'TeamLeader',
    TeamLeaderCharging = 'TeamLeaderCharging',
    LearningArchitect = 'LearningArchitect',
    Merchant = 'Merchant',
    HoursTracker = 'HoursTracker',
    Inactive = 'Inactive',
    User = 'User',
    Guest = 'Guest',
    Support = 'Support'
}
