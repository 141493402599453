export interface FeatureToggleConfig {
    [key: string]: boolean | FeatureToggleConfig;
}

export type FeatureOf<T extends FeatureToggleConfig, C = T> = C extends FeatureToggleConfig
    ? { [P in keyof C]: FeatureOf<T, C[P]> }
    : C & { getKeys(): string[] };

export function property<T extends FeatureToggleConfig, C = T>(): FeatureOf<T, C> {
    return new Proxy<FeatureOf<T, C>>({} as FeatureOf<T, C>, {
        get: (_, name: string) => _property([name])
    });
}

function _property<T extends FeatureToggleConfig, C = T>(keys: string[]): FeatureOf<T, C> {
    return new Proxy<FeatureOf<T, C>>(
        {
            getKeys: () => keys
        } as FeatureOf<T, C>,
        {
            get: (target, name: string) => {
                if (name === 'getKeys') {
                    return target[name];
                }
                return _property([...keys, name]);
            }
        }
    );
}
