import { DeactivationPermission } from '@ceres/shared/services';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FeatureToggleGuard, property } from '@ceres/feature-toggles';
import { rolePermissions as Permissions } from '@ceres/domain';
import { AutoLoginPartialRoutesGuard } from 'angular-auth-oidc-client';
import { CeresFeaturesConfig } from '../features/interfaces/ceres-features-config.interface';
import { UserGuard } from './auth/services/user-guard.service';
import { HomeComponent } from './home/home.component';
import { AuthCallbackComponent } from './auth/components/auth-callback.component';
import { RolePermissionGuard } from './shared/services/guards/role-permission.guard';

const routes: Routes = [
    {
        path: 'services',
        loadChildren: () => import('./services/features-services.module').then((m) => m.FeaturesServicesModule),
        data: {
            features: [property<CeresFeaturesConfig>().services.enabled],
            permission: Permissions.services.readAll
        },
        canActivate: [AutoLoginPartialRoutesGuard, UserGuard, RolePermissionGuard, FeatureToggleGuard]
    },
    {
        path: 'projects',
        loadChildren: () => import('./project/project.module').then((m) => m.ProjectModule),
        data: {
            features: [property<CeresFeaturesConfig>().projects.enabled]
        },
        canActivate: [AutoLoginPartialRoutesGuard, UserGuard, FeatureToggleGuard]
    },
    {
        path: 'timesheet',
        loadChildren: () => import('./working-hours/working-hours.module').then((m) => m.WorkingHoursModule),
        data: {
            features: [property<CeresFeaturesConfig>().timeManagement.timeSheets]
        },
        canActivate: [AutoLoginPartialRoutesGuard, UserGuard, FeatureToggleGuard]
    },
    {
        path: 'tasks',
        loadChildren: () => import('./tasks/tasks.module').then((m) => m.TasksModule),
        canActivate: [AutoLoginPartialRoutesGuard, UserGuard]
    },
    {
        path: 'test',
        loadChildren: () => import('./test/test.module').then((m) => m.TestModule),
        canActivate: [AutoLoginPartialRoutesGuard, UserGuard]
    },
    {
        path: 'projectgroup',
        loadChildren: () => import('./program/program.module').then((m) => m.ProgramModule),
        data: {
            features: [property<CeresFeaturesConfig>().projects.groups]
        },
        canActivate: [AutoLoginPartialRoutesGuard, UserGuard, FeatureToggleGuard]
    },
    {
        path: 'myceres',
        loadChildren: () => import('./myceres/myceres.module').then((m) => m.MyceresModule),
        canActivate: [AutoLoginPartialRoutesGuard, UserGuard]
    },
    {
        path: 'employees',
        loadChildren: () => import('./employees/employees.module').then((m) => m.EmployeeModule),
        canActivate: [AutoLoginPartialRoutesGuard, UserGuard]
    },
    {
        path: 'contract',
        loadChildren: () => import('./contract/contract.module').then((m) => m.ContractModule),
        data: {
            features: [property<CeresFeaturesConfig>().sales.offers]
        },
        canActivate: [AutoLoginPartialRoutesGuard, UserGuard, FeatureToggleGuard]
    },
    {
        path: 'capacities',
        loadChildren: () => import('./capacity/capacity.module').then((m) => m.CapacityModule),
        data: {
            features: [property<CeresFeaturesConfig>().timeManagement.capacity]
        },
        canActivate: [AutoLoginPartialRoutesGuard, UserGuard, FeatureToggleGuard]
    },
    {
        path: 'absences',
        loadChildren: () => import('./absences/absences.module').then((m) => m.AbsencesModule),
        data: {
            features: [property<CeresFeaturesConfig>().timeManagement.absences]
        },
        canActivate: [AutoLoginPartialRoutesGuard, UserGuard, FeatureToggleGuard]
    },
    {
        path: 'workload',
        loadChildren: () => import('./workload/workload.module').then((m) => m.WorkloadModule),
        data: {
            features: [property<CeresFeaturesConfig>().timeManagement.workload]
        },
        canActivate: [AutoLoginPartialRoutesGuard, UserGuard, FeatureToggleGuard]
    },
    {
        path: 'transactional-hours',
        loadChildren: () =>
            import('./transactional-hours/transactional-hours.module').then((m) => m.TransactionalHoursModule),
        data: {
            features: [property<CeresFeaturesConfig>().timeManagement.transactionalTimeTracking]
        },
        canActivate: [AutoLoginPartialRoutesGuard, UserGuard, FeatureToggleGuard]
    },
    {
        path: 'businesspartner',
        loadChildren: () => import('./business-partner/business-partner.module').then((m) => m.BusinessPartnerModule),
        data: {
            features: [property<CeresFeaturesConfig>().businessPartner.enabled]
        },
        canActivate: [AutoLoginPartialRoutesGuard, UserGuard, FeatureToggleGuard]
    },
    {
        path: 'bop',
        loadChildren: () => import('./bop/bop.module').then((m) => m.BopModule),
        data: {
            features: [property<CeresFeaturesConfig>().sales.businessOpportunities]
        },
        canActivate: [AutoLoginPartialRoutesGuard, UserGuard, FeatureToggleGuard]
    },
    {
        path: 'admin',
        loadChildren: () => import('./admin/admin.module').then((m) => m.AdminModule),
        data: {
            features: [property<CeresFeaturesConfig>().administration.enabled]
        },
        canActivate: [AutoLoginPartialRoutesGuard, UserGuard, FeatureToggleGuard]
    },
    {
        path: 'reports',
        loadChildren: () => import('./reports/reports.module').then((m) => m.ReportsModule),
        data: {
            features: [property<CeresFeaturesConfig>().reporting.enabled]
        },
        canActivate: [AutoLoginPartialRoutesGuard, UserGuard, FeatureToggleGuard]
    },
    {
        path: 'gmsreporting',
        loadChildren: () => import('./gmsreporting/gmsreporting.module').then((m) => m.GmsreportingModule),
        data: {
            features: [property<CeresFeaturesConfig>().reporting.gmsReportingDemo]
        },
        canActivate: [AutoLoginPartialRoutesGuard, UserGuard, FeatureToggleGuard]
    },
    {
        path: 'sales-activity',
        loadChildren: () => import('./sales-activity/sales-activity.module').then((m) => m.SalesActivityModule),
        data: {
            features: [property<CeresFeaturesConfig>().sales.salesActivities]
        },
        canActivate: [AutoLoginPartialRoutesGuard, UserGuard, FeatureToggleGuard]
    },
    {
        path: 'controlling',
        loadChildren: () => import('./controlling/controlling.module').then((m) => m.ControllingModule),
        data: {
            features: [property<CeresFeaturesConfig>().reporting.enabled]
        },
        canActivate: [AutoLoginPartialRoutesGuard, UserGuard, FeatureToggleGuard]
    },
    {
        path: 'sales-funnel',
        loadChildren: () => import('./controlling/controlling.module').then((m) => m.ControllingModule),
        data: {
            features: [property<CeresFeaturesConfig>().sales.salesFunnel]
        },
        canActivate: [AutoLoginPartialRoutesGuard, UserGuard, FeatureToggleGuard]
    },
    {
        path: 'quality',
        loadChildren: () => import('./controlling/controlling.module').then((m) => m.ControllingModule),
        data: {
            features: [property<CeresFeaturesConfig>().reporting.enabled]
        },
        canActivate: [AutoLoginPartialRoutesGuard, UserGuard, FeatureToggleGuard]
    },
    {
        path: 'cost-report',
        loadChildren: () => import('./cost-report/cost-report.module').then((m) => m.CostReportModule),
        data: {
            features: [property<CeresFeaturesConfig>().reporting.costReport]
        },
        canActivate: [AutoLoginPartialRoutesGuard, UserGuard, FeatureToggleGuard]
    },
    {
        path: 'working-hours-report',
        loadChildren: () =>
            import('./working-hours-report/working-hours-report.module').then((m) => m.WorkingHoursReportModule),
        data: {
            features: [property<CeresFeaturesConfig>().reporting.workingHoursReport]
        },
        canActivate: [AutoLoginPartialRoutesGuard, UserGuard, FeatureToggleGuard]
    },
    {
        path: 'accounting',
        loadChildren: () => import('./conaktiv/conaktiv.module').then((m) => m.ConAktivModule),
        data: { features: [property<CeresFeaturesConfig>().projects.charging] },
        canActivate: [AutoLoginPartialRoutesGuard, UserGuard, FeatureToggleGuard]
    },
    /*  {
    path: 'prestaffing',
    loadChildren: () =>
      import('./pre-staffing/pre-staffing.module').then(
        (m) => m.PreStaffingModule,
      ),
    data: {
      features: [property<CeresFeaturesConfig>().projects.preStaffing],
      permission: Permissions.project.preStaffing.readAll
    },
    canActivate: [AutoLoginPartialRoutesGuard, UserGuard, FeatureToggleGuard, RolePermissionGuard],
  },*/
    {
        path: 'prestaffing',
        loadChildren: () => import('./pre-staffing-v2/pre-staffing-v2.module').then((m) => m.PreStaffingV2Module),
        canActivate: [AutoLoginPartialRoutesGuard, UserGuard]
    },
    {
        path: 'unauthorized',
        loadChildren: () =>
            import('./unauthorized-logout/unauthorized-logout.module').then((m) => m.UnauthorizedLogoutModule)
    },
    {
        path: 'logout',
        loadChildren: () =>
            import('./unauthorized-logout/unauthorized-logout.module').then((m) => m.UnauthorizedLogoutModule)
    },
    { path: 'auth/callback', component: AuthCallbackComponent },
    {
        path: '',
        pathMatch: 'full',
        component: HomeComponent,
        canActivate: [AutoLoginPartialRoutesGuard, UserGuard]
    },
    {
        path: 'error',
        component: HomeComponent,
        canDeactivate: [DeactivationPermission]
    }
    // { path: '**', redirectTo: '/error' }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            useHash: false,
            enableTracing: false
        })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {}
