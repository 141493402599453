import { Inject, Injectable } from '@angular/core';
import { Currency, CurrencyWithExchangeRate } from '@ceres/domain';
import { shareReplay } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ENVIRONMENT } from '@ceres/frontend-helper';
import { ImpersonatedHttpClient } from './http/impersonated-http-client';

@Injectable({
    providedIn: 'root'
})
export class CurrencyService {
    availableCurrencies$: Observable<CurrencyWithExchangeRate[]>;

    constructor(
        private readonly httpClient: ImpersonatedHttpClient,
        @Inject(ENVIRONMENT) private readonly environment: { edgeService: string }
    ) {
        this.availableCurrencies$ = this.httpClient
            .get<CurrencyWithExchangeRate[]>(`${environment.edgeService}/currencies/active`)
            .pipe(shareReplay(1));
    }

    public compareCurrencies(currency1: Currency, currency2: Currency): boolean {
        return currency1 && currency2 ? currency1.code === currency2.code : currency1 === currency2;
    }
}
