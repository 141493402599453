<div class="paginator paginator-wrapper">
    <button class="invisible-button" (click)="selectPrevious()">
        <mat-icon class="custom-text-color">keyboard_arrow_left</mat-icon>
    </button>
    @if (_selectedDate) {
        <mat-select class="month-selector" [value]="_selectedDate" (selectionChange)="setWithSurrounding($event.value)">
            @for (date of dateList; track date) {
                <mat-option [value]="date">{{ getDisplayedTimeUnit(date) }} </mat-option>
            }
        </mat-select>
    }
    <button class="invisible-button arrow" (click)="selectNext()">
        <mat-icon class="custom-text-color">keyboard_arrow_right</mat-icon>
    </button>
</div>
