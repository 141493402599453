import { MatDialog } from '@angular/material/dialog';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConfirmDialogComponent } from '@ceres/ui';
import { Message, MessageType } from './message';

@Injectable({
    providedIn: 'root'
})
export class MessageService {
    private readonly messages$$ = new BehaviorSubject<Message[]>([]);
    public readonly messages$ = this.messages$$.asObservable();

    public readonly hasMessages$ = this.messages$.pipe(map((messages) => messages.length > 0));

    private id = -1;

    constructor(private readonly dialog: MatDialog) {}

    pushMessage(data: {
        message: string | string[];
        title: string;
        type?: MessageType;
        obj?: object;
        source?: string;
    }) {
        this.id += 1;
        const msg = new Message(this.id, data.message, data.title, data.type || 0, data.obj, data.source);
        this.messages$$.next([...this.messages$$.getValue(), msg]);
    }

    deleteMessage(message: Message) {
        const newMessages = this.messages$$.getValue().filter((e) => e.id !== message.id);
        this.messages$$.next(newMessages);
    }

    openConfirmDialog(text: string, header?: string, disableClose?: boolean, warningText?: string) {
        const ref = this.dialog.open(ConfirmDialogComponent, {
            disableClose: true,
            width: '500px',
            data: { text, header, disableClose, warningText }
        });

        return ref.afterClosed().pipe(map((confirm) => !!confirm));
    }
}
