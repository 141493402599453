import { Component, HostListener } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'ceres-settings-custom-filter-save-dialog',
    templateUrl: './custom-filter-save-dialog.component.html',
    styleUrls: ['./custom-filter-save-dialog.component.scss']
})
export class CustomFilterSaveDialogComponent {
    public name: string;

    constructor(private dialogRef: MatDialogRef<CustomFilterSaveDialogComponent>) {}

    public cancel() {
        this.dialogRef.close();
    }

    public confirm() {
        this.dialogRef.close(this.name);
    }

    @HostListener('keydown.esc')
    public onEsc() {
        this.dialogRef.close();
    }
}
