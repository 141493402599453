import { BusinessArea } from './business-area';

export interface ProjectCalculation {
    modules: ProjectCalculationModule[];
    id: number;
}

export interface ProjectCalculationActivity {
    title: string;
    activityId: number | null;
    days: number;
    costsPerDay: number;
    pricePerDay: number;
    timeUnit: string;
    //translationKey: string; //TODO translation refactor module.component.ts to handle activity relations
}

export interface ProjectCalculationModule {
    title: string;
    tasks: ProjectCalculationTask[];
    isMaterial: boolean;
    isExternal: boolean;
    isDirty?: boolean;
}

export interface ProjectCalculationTask {
    title: string;
    price?: number;
    costs?: number;
    activities?: ProjectCalculationActivity[];
    isDirty?: boolean;
}

export interface ProjectCalculationTemplate extends ProjectCalculation {
    templateId: string;
}

export interface ApiProjectCalculation {
    ID?: number;
    Title: string;
    MPNumber: number;
    VersionDate: Date;
    Data: ProjectCalculation;
}

export interface ApiProjectCalculationTemplate {
    ID?: number;
    Title: string;
    BusinessArea: BusinessArea[] | { results: { Id: number; Title: string }[] };
    Template: ProjectCalculation;
}
