import { NgModule } from '@angular/core';
import { MatSliderModule } from '@angular/material/slider';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SliderCardComponent } from './slider-card.component';

@NgModule({
    imports: [CommonModule, MatSliderModule, FormsModule],
    declarations: [SliderCardComponent],
    exports: [SliderCardComponent]
})
export class SliderCardModule {}
