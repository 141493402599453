export class OfferTemplateTableElement {
    name!: string;
    id!: number;
    updatedAt!: Date;
    businessAreas!: string[];
}

export class OfferTemplateElement {
    updatedAt!: Date;
    createdAt!: Date;
    id!: number;
    name!: string;
    authorId!: number;
    key!: string;
    contentType!: string;
    businessAreas:
        | {
              updatedAt: Date;
              updatedBy: number; // employeeid
              createdAt: Date;
              createdBy: number;
              id: number;
              name: string;
          }[]
        | string[];
    pdfExists!: boolean;
}

export class UpdateOfferTemplateElement {
    name!: string;
    businessAreaIds!: number[];
}
