import { Role } from './role';
import { Employee } from './employee';

export interface IUser {
    id: number;
    gid: string;
    email: string;
    name: string;
    nameShort: string;
    department?: string;
    country: string;
    corporateName: string;
    isActive: boolean;
    details?: Employee;
    role: Role;
}
