import { Attribute, ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'ceres-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonComponent implements OnInit {
    @Input()
    public disabled = false;

    @Input()
    public icon: string | undefined = undefined;

    @Input()
    public style: undefined | 'grey' | 'red' | 'white' | 'orange' | 'primary';

    @Input()
    public layout: undefined | 'round' | 'picker';

    @Input()
    public size: undefined | 'small' | 'full';

    public readonly type: string;

    classes: string[] = [];

    constructor(@Attribute('type') type: 'button' | 'submit' | 'reset' | undefined) {
        this.type = type ?? 'button';
    }

    ngOnInit() {
        if (this.icon) {
            this.classes.push('a-icon', 'a-icon--' + this.icon);
        }
        if (this.style) {
            this.classes.push('style-' + this.style);
        }
        if (this.layout) {
            this.classes.push('layout-' + this.layout);
        }
        if (this.size) {
            this.classes.push('size-' + this.size);
        }
    }
}
