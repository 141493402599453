import { ModuleWithProviders, NgModule } from '@angular/core';
import { FeatureTogglesConfigLoaderService, FeatureTogglesModule } from '@ceres/feature-toggles';
import { CeresFeaturesConfig } from '../../features/interfaces/ceres-features-config.interface';
import { StaticFeaturesAdapterService } from './services/static-features-adapter.service';

@NgModule({
    imports: [FeatureTogglesModule.forRoot<CeresFeaturesConfig>()],
    exports: [FeatureTogglesModule]
})
export class FeaturesModule {
    public static forRoot(): ModuleWithProviders<FeaturesModule> {
        return {
            ngModule: FeaturesModule,
            providers: [
                {
                    provide: FeatureTogglesConfigLoaderService,
                    useClass: StaticFeaturesAdapterService
                }
            ]
        };
    }

    public static forChild(): ModuleWithProviders<FeaturesModule> {
        return {
            ngModule: FeaturesModule
        };
    }
}
