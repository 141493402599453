<footer class="footer d-flex justify-content-between lay-wrapper--footer" [ngClass]="{ ie: isIE }">
    <div class="nav">
        <ul>
            <li>
                <a class="footer-text">{{ 'footer.company-name' | transloco }} {{ currentYear }}</a>
            </li>
            <li>
                <a class="footer-text" href="https://new.siemens.com/global/en/general/legal.html" target="_blank">{{
                    'footer.corporate-info' | transloco
                }}</a>
            </li>
            <li>
                <a
                    class="footer-text"
                    href="https://new.siemens.com/global/en/general/privacy-notice.html"
                    target="_blank"
                    >{{ 'footer.privacy-policy' | transloco }}</a
                >
            </li>
            <li>
                <a
                    class="footer-text"
                    href="https://new.siemens.com/global/en/general/cookie-notice.html"
                    target="_blank"
                    >{{ 'footer.cookie-policy' | transloco }}</a
                >
            </li>
            <li>
                <a
                    class="footer-text"
                    href="https://new.siemens.com/global/en/general/terms-of-use.html"
                    target="_blank"
                    >{{ 'footer.terms-of-use' | transloco }}</a
                >
            </li>
            <li>
                <a
                    class="footer-text"
                    href="https://new.siemens.com/global/en/general/digital-id.html"
                    target="_blank"
                    >{{ 'footer.digital-id' | transloco }}</a
                >
            </li>
        </ul>
    </div>
    <div class="d-none d-md-block">
        <a href="https://gms.siemens.cloud/" target="_blank" rel="noopener noreferrer" class="gms-badge">
            <span>powered by <strong>/GMS/</strong></span>
        </a>
    </div>
</footer>
