@if (options) {
    <div class="multiple-chip">
        <mat-form-field class="pwo-no-underline">
            <mat-chip-grid [(ngModel)]="data">
                @for (item of data; track item) {
                    <mat-chip (removed)="remove(item)" [removable]="!readonly">
                        {{ item.parentName + '/' + item.title }}
                        <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip>
                }
                @if (!readonly) {
                    <mat-chip [matMenuTriggerFor]="menu" class="highlighted pwo-btn">
                        <mat-icon>add</mat-icon>
                    </mat-chip>
                }
            </mat-chip-grid>
        </mat-form-field>
    </div>
    <mat-menu #menu="matMenu" [overlapTrigger]="false">
        <div>
            @for (level of levels; track level) {
                @if (isSelected(level - 1) && childHasOptions(level)) {
                    <mat-form-field (click)="preventClose($event)">
                        <mat-select placeholder="{{ 'shared.select.please-select-placeholder' | transloco }}">
                            @for (option of options; track option) {
                                @if (option.level == level && checkParents(option)) {
                                    <mat-option (onSelectionChange)="select($event, option, level)" [value]="option">
                                        {{ option.title }}
                                    </mat-option>
                                }
                            }
                        </mat-select>
                    </mat-form-field>
                }
            }
            <div class="filter-apply">
                <button (click)="add()" [disabled]="disabledApply" color="accent" mat-raised-button type="button">
                    {{ 'shared.buttons.add' | transloco }}
                </button>
            </div>
        </div>
    </mat-menu>
}
