import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ButtonModule } from '../button/button.module';
import { ConfirmDialogComponent } from './confirm-dialog.component';

@NgModule({
    imports: [CommonModule, TranslocoModule, DragDropModule, ButtonModule],
    declarations: [ConfirmDialogComponent],
    exports: [ConfirmDialogComponent]
})
export class ConfirmDialogModule {}
