import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ColorService {
    public checkBackgroundColor(bgColor: string): string {
        return bgColor && bgColor.length === 6 ? '#' + bgColor : 'var(--ceres-primary-color-1)';
    }

    public calculateFontColor(bgColor: string): string {
        if (!bgColor || bgColor.length !== 6) {
            return 'var(--ceres-white)';
        }

        const m = bgColor.match(/.{1,2}/g);
        if (!m) {
            return 'var(--ceres-white)';
        }
        const r = parseInt(m[0], 16);
        const g = parseInt(m[1], 16);
        const b = parseInt(m[2], 16);

        const brightness = (r * 299 + g * 587 + b * 114) / 1000;

        if (brightness >= 125) {
            return 'var(--ceres-black)';
        } else {
            return 'var(--ceres-white)';
        }
    }
}
