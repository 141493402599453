import { Component, ContentChildren, EventEmitter, Output, QueryList } from '@angular/core';
import { WizardFormDirective } from './wizard-form.directive';

@Component({
    selector: 'ceres-wizard',
    templateUrl: './wizard.component.html',
    styleUrls: ['./wizard.component.scss']
})
export class WizardComponent {
    @ContentChildren(WizardFormDirective)
    wizardForms!: QueryList<WizardFormDirective>;

    @Output() save = new EventEmitter<void>();

    @Output() cancel = new EventEmitter<void>();
}
