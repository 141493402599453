export class ReportQuery {
    role?: 'Admin' | 'User' | string;
    title?: string;
    FK?: string;
    conAktivUser?: string;
    mp?: string | number;

    constructor(object?: Partial<ReportQuery>) {
        Object.assign(this, object);
    }
}
