export interface SearchQuery {
    term: string;
    count: number;
    categories: SearchCategory<any>[];
    index: 'business-partner';
}

export interface SearchCategory<T extends { [key: string]: any }> {
    key: string;
    name: string;
    list: string;
    titleColumn: keyof T & string;
    subtitleColumn: keyof T & string;
    additionalSearchColumns?: Array<keyof T & string>;
}

export interface SearchResponse {
    [key: string]: { id: number; title: string; subtitle: string }[];
}
